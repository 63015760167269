import { Row, Col, Form, Input } from 'antd';
import React, { ReactElement } from 'react';
import CcxComponentProps from '../../../../core/CcxComponent';
import styles from './DataStoreSettingsProxySqlAdminPassword.module.less';

interface Props extends CcxComponentProps {
    form: any;
    onPasswordChange: Function;
    onPasswordConfirmChange: Function;
}

function DataStoreSettingsProxySqlAdminPassword({
    form,
    onPasswordChange,
    onPasswordConfirmChange,
    testId = 'DataStoreSettingsProxySqlAdminPassword',
}: Props): ReactElement {
    const handleOnPasswordChange = (e: any) => {
        onPasswordChange && onPasswordChange(e.target.value);
    };

    const handleOnPasswordConfirmChange = (e: any) => {
        onPasswordConfirmChange && onPasswordConfirmChange(e.target.value);
    };

    return (
        <div
            className={styles.DataStoreSettingsProxySqlAdminPassword}
            data-testid={testId}
        >
            <h2 data-testid={`${testId}Title`}>
                Change ProxySQL administrator password
            </h2>
            <div
                className={
                    styles.DataStoreSettingsProxySqlAdminPasswordDescription
                }
            >
                You can set the ProxySQL administrator password below.
            </div>
            <Form form={form} layout="vertical">
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item noStyle>
                            <Form.Item
                                hasFeedback
                                name="password"
                                label="Password"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter a password',
                                    },
                                ]}
                            >
                                <Input.Password
                                    autoComplete="off"
                                    data-testid={`${testId}DataStorePasswordInput`}
                                    placeholder="Enter a password"
                                    size="large"
                                    onChange={handleOnPasswordChange}
                                />
                            </Form.Item>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item noStyle>
                            <Form.Item
                                dependencies={['password']}
                                hasFeedback
                                name="confirmPassword"
                                label="Repeat password"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please confirm your password',
                                    },
                                    ({ getFieldValue }: any) => ({
                                        validator(_: any, value: any) {
                                            if (
                                                !value ||
                                                getFieldValue('password') ===
                                                    value
                                            ) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(
                                                new Error(
                                                    'The two passwords that you entered do not match!'
                                                )
                                            );
                                        },
                                    }),
                                ]}
                            >
                                <Input.Password
                                    autoComplete="off"
                                    data-testid={`${testId}DataStorePasswordInput`}
                                    placeholder="Enter your password again"
                                    size="large"
                                    onChange={handleOnPasswordConfirmChange}
                                />
                            </Form.Item>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </div>
    );
}

export default DataStoreSettingsProxySqlAdminPassword;
