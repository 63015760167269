import React, { ReactElement, useEffect, useState } from 'react';
import FriendlyTimestamp from '../../../types/FriendlyTimestamp';
import AppChart from '../../AppChart';
import CcxComponentProps from '../../../core/CcxComponent';
import ChartCustomTooltip from './ChartCustomTooltip';
import PrometheusRedisBlockedClientsStats from '../../../types/PrometheusRedisBlockedClientsStats';

interface Props extends CcxComponentProps {
    data?: PrometheusRedisBlockedClientsStats[];
    loading: boolean;
    displaySummary?: boolean;
    height?: number;
    width?: number;
}

function PrometheusRedisBlockedClientsChart({
    data,
    loading,
    displaySummary,
    testId = 'PrometheusRedisBlockedClientsChart',
    width,
    height = 250,
}: Props): ReactElement {
    const [chartData, setChartData] = useState<any>([]);
    const [summary, setSummary] = useState<any>([]);

    const setChartSummary = (data: any, series: any) => {
        let max: number[] = [];
        let min: number[] = [];
        let sum: number[] = [];
        let avg: number[] = [];

        series.forEach((s: any) => {
            max[s.value] = 0;
            min[s.value] = 0;
            sum[s.value] = 0;
            avg[s.value] = 0;
        });

        data.forEach((d: any) => {
            series.forEach((s: any) => {
                sum[s.value] += d[s.value];

                min[s.value] =
                    d[s.value] < min[s.value] ? d[s.value] : min[s.value];
                max[s.value] =
                    d[s.value] > max[s.value] ? d[s.value] : max[s.value];
            });
        });

        series.forEach((s: any) => {
            avg[s.value] = sum[s.value] / data.length;
        });

        const dataSummary = series.map((s: any) => {
            return {
                serie: s.value,
                label: s.label,
                avg: avg[s.value].toFixed(2),
                min: min[s.value].toFixed(2),
                max: max[s.value].toFixed(2),
                sum: sum[s.value].toFixed(2),
            };
        });

        setSummary(dataSummary);
    };

    useEffect(() => {
        if (data && data.length > 0) {
            const formattedData = data.map(
                (stat: PrometheusRedisBlockedClientsStats) => {
                    return [
                        {
                            yValue: stat.blockedClients,
                            series: 'blocked_clients',
                            xValue: new FriendlyTimestamp(
                                stat.created
                            ).getDate(),
                        },
                    ];
                }
            );
            setChartData(formattedData.flat());

            const summarySeries = [
                {
                    value: 'blockedClients',
                    label: 'blocked_clients',
                },
            ];
            setChartSummary(data, summarySeries);
        }
    }, [data]);

    const chartConfig = {
        title: {
            visible: true,
            text: 'Blocked clients',
        },
        description: {
            visible: true,
            text: '',
        },
        height: height,
        width: width,
        autoFit: !width && !height,
        xField: 'xValue',
        yField: 'yValue',
        xAxis: {
            type: 'time',
            mask: 'HH:mm',
        },
        animation: false,
        seriesField: 'series',
        tooltip: {
            customContent: (title: any, data: any) => {
                const getTitle = () => {
                    if (!data || data.length === 0) return '';
                    return data[0]?.data?.xValue.toString();
                };

                const getContent = () => {
                    if (!data || data.length === 0) return [];
                    return data.map((d: any) => ({
                        color: d?.color,
                        label: d?.data?.series,
                        value: d?.data?.yValue,
                    }));
                };

                const tooltipData = {
                    title: getTitle(),
                    content: getContent(),
                };

                return (
                    <ChartCustomTooltip
                        title={tooltipData.title}
                        content={tooltipData.content}
                    />
                );
            },
        },
    };

    return (
        <AppChart
            type="line"
            data={chartData}
            config={chartConfig}
            summary={summary}
            loading={loading}
            displaySummary={displaySummary}
            testId={testId}
        />
    );
}

export default PrometheusRedisBlockedClientsChart;
