import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Deployments from '../types/Deployments';
import DataStoreService from '../services/DataStoreService';
import ContentService from '../services/ContentService';

interface DatastoreType {
    datastores: Deployments | undefined;
    isLoading: boolean;
    isSuccess: boolean;
    isError: boolean;
}
const initialState = {
    datastores: undefined,
    isError: false,
    isSuccess: false,
    isLoading: false,
} as DatastoreType;

export const getAllDataStores = createAsyncThunk(
    'datastores/getDatastores',
    async () => {
        const res = await ContentService.getDeploymentWizardOptions();
        const response = await DataStoreService.getDataStores('default', res);
        return response;
    }
);

export const userSlice = createSlice({
    name: 'datastores',
    initialState,
    reducers: {
        getAllDataStores: (state, action) => {
            state.isLoading = false;
            state.datastores = action.payload;
            state.isSuccess = true;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllDataStores.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getAllDataStores.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.datastores = action.payload;
            })
            .addCase(getAllDataStores.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
            });
    },
});

export default userSlice.reducer;
