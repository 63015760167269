import { ReactElement, useContext, useEffect, useMemo, useState } from 'react';
import { Button, Card, Dropdown, Menu, notification, Modal, Spin } from 'antd';
import CcxComponentProps from '../../../core/CcxComponent';
import AppGridTable from '../../ccx/common/AppGridTable';
import useVpcs from '../../../core/hooks/useVpcs';
import useVpcPeers from '../../../core/hooks/useVpcPeers';
import useContentProviders from '../../../core/hooks/useContentProviders';
import { useHistory, useParams } from 'react-router-dom';
import AppEmpty from '../../AppEmpty';
import LazyLoader from '../../LazyLoader';
import VpcListOptions from '../../vpc/VpcListOptions';
import styles from './Vpcs.module.less';
import CloudProvider from '../../../types/CloudProvider';
import CloudProviderRegion from '../../../types/CloudProviderRegion';
import Vpc from '../../../types/Vpc';
import InstanceInformation from './InstanceInformation';
import VpcStatus from './VpcStatus';
import VpcCidr from './VpcCidr';
import VpcPeering from '../../../types/VpcPeering';
import VpcPeerings from './VpcPeerings';
import CcxContextualMenuIcon from '../../ccx/icons/CcxContextualMenuIcon';
import AppConfirmDialog from '../../AppConfirmDialog';
import CreateVpcPeerButton from '../../vpc/CreateVpcPeerButton';
import VpcDataStores from './VpcDataStores';
import CcxIconInfoCircleTwoTone from '../../ccx/icons/CcxIconInfoCircleTwoTone';
import CcxIconCheckCircleTwoTone from '../../ccx/icons/CcxIconCheckCircleTwoTone';
import CcxIconCloseCircleTwoTone from '../../ccx/icons/CcxIconCloseCircleTwoTone';
import VpcService from '../../../services/VpcService';
import useContentDeployWizard from '../../../core/hooks/useContentDeployWizard';
import { VpcsContext } from '../../../core/context/VpcsContext';
import {
    DeleteOutlined,
    FileTextOutlined,
    PlusCircleOutlined,
} from '@ant-design/icons';

interface UrlProps {
    projectUuid: string;
}

interface Props extends CcxComponentProps {}

function Vpcs({ testId = 'Vpcs' }: Props): ReactElement {
    let history = useHistory();
    let { projectUuid } = useParams<UrlProps>();
    const [rows, setRows] = useState<any>([]);
    const { cloudProviders, loading: loadingProviders } = useContentProviders();
    const [clouds, setClouds] = useState<any[] | undefined>(undefined);
    const [regions, setRegions] = useState<any[] | undefined>(undefined);

    const vpcContext = useContext(VpcsContext);

    const {
        vpcs,
        loading: loadingVpcs,
        refresh: refreshVpcs,
    } = useVpcs(vpcContext.cloud, vpcContext.region);

    const vpcIds = useMemo(() => {
        return vpcs?.map((v: Vpc) => v.id);
    }, [vpcs]);

    const {
        vpcPeers,
        loading: loadingPeers,
        refresh: refreshPeers,
    } = useVpcPeers(vpcContext.cloud, vpcContext.region, vpcIds);

    const { deploymentOptions } = useContentDeployWizard();

    const [deleting, setDeleting] = useState<boolean>(false);

    const columns = [
        { title: 'Resource info', span: 5 },
        { title: 'Status', span: 3 },
        { title: 'CIDR', span: 4 },
        { title: 'Peering', span: 4 },
        { title: 'Used by', span: 4 },
    ];

    useEffect(() => {
        if (cloudProviders && cloudProviders.length > 0) {
            setClouds(
                cloudProviders
                    ?.filter((c: CloudProvider) => {
                        // only show AWS for now
                        return c.isAws();
                    })
                    .map((c: CloudProvider) => {
                        return { label: c.name, value: c.code };
                    }) || []
            );

            setRegions(
                cloudProviders
                    ?.filter((c: CloudProvider) => {
                        // only show AWS for now
                        return c.isAws();
                    })[0]
                    ?.regions.map((r: CloudProviderRegion) => {
                        return {
                            label: `${r.code} - ${r.name}`,
                            value: r.code,
                        };
                    }) || []
            );

            if (vpcContext.cloud && vpcContext.region) {
                vpcContext.setCloud(vpcContext.cloud);
                vpcContext.setRegion(vpcContext.region);
            } else {
                vpcContext.setCloud(cloudProviders[0].code);
                vpcContext.setRegion(cloudProviders[0].regions[0].code);
            }
        }
    }, [cloudProviders]);

    useEffect(() => {
        if (vpcs && vpcs.length > 0) {
            const data = vpcs.map((vpc: Vpc) => {
                const peeringData: any =
                    vpcPeers &&
                    vpcPeers.length &&
                    vpcPeers.filter(
                        (vpcPeer: VpcPeering) => vpcPeer.vpcId === vpc.id
                    ).length
                        ? vpcPeers.filter(
                              (vpcPeer: VpcPeering) => vpcPeer.vpcId === vpc.id
                          )
                        : [];

                const onConfirmDelete = () => {
                    return new Promise(async (resolve, reject) => {
                        setDeleting(true);

                        notification.open({
                            message: 'Remove VPC',
                            description: 'The VPC will be removed soon.',
                            icon: <CcxIconInfoCircleTwoTone />,
                        });

                        Modal.destroyAll();

                        try {
                            if (!vpc?.id) {
                                throw new Error('no VPC ID provided');
                            }
                            await VpcService.deleteVpc({
                                vpcUuid: vpc.id,
                            });

                            refreshVpcs && (await refreshVpcs());

                            vpcContext.refresh && (await vpcContext.refresh());

                            notification.open({
                                message: 'Remove VPC',
                                description: 'VPC successfully removed',
                                icon: (
                                    <CcxIconCheckCircleTwoTone twoToneColor="#52c41a" />
                                ),
                            });

                            setDeleting(false);
                            resolve(null);
                        } catch (e) {
                            notification.open({
                                message: 'Remove VPC',
                                description: `There was an error removing the VPC. ${e}`,
                                icon: (
                                    <CcxIconCloseCircleTwoTone twoToneColor="#eb2f96" />
                                ),
                            });

                            console.error(e);

                            setDeleting(false);
                            reject();
                        }
                    }).catch(() => console.log('Oops errors!'));
                };

                const dataStores = vpc.datastores;

                const menu = (
                    <Menu>
                        <Menu.Item
                            key="viewPeerings"
                            icon={<FileTextOutlined />}
                            onClick={() => {
                                history.push({
                                    pathname: `/projects/${projectUuid}/vpcs/${vpc.id}/peerings`,
                                    state: {
                                        cloud: vpc.cloud,
                                        region: vpc.region,
                                    },
                                });
                            }}
                        >
                            View Peerings
                        </Menu.Item>
                        <Menu.Item
                            key="createPeering"
                            icon={<PlusCircleOutlined />}
                        >
                            <CreateVpcPeerButton
                                onSuccess={refreshPeers}
                                cloud={vpcContext.cloud}
                                region={vpcContext.region}
                                buttonType="simple"
                                buttonText="Create peering"
                                hideIcon={true}
                                buttonClassName={styles.VpcListMenuButton}
                                selectedVpcUuid={vpc.id}
                            />
                        </Menu.Item>
                        <Menu.Divider />
                        <Menu.Item key="delete" danger={true}>
                            <AppConfirmDialog
                                onOk={onConfirmDelete}
                                stretchedClick={true}
                                actionIcon={<DeleteOutlined />}
                                label="Delete"
                                content="The selected VPC will be deleted."
                            />
                        </Menu.Item>
                    </Menu>
                );

                const countryCode = deploymentOptions
                    ?.getCloudRegions(vpc?.cloud)
                    ?.filter((r: any) => r.code === vpc.region)[0]?.countryCode;

                return {
                    borderAlert: vpc.isStatusError(),
                    columns: [
                        {
                            content: deploymentOptions && (
                                <InstanceInformation
                                    vpc={vpc}
                                    countryCode={countryCode}
                                    testId={`${testId}${vpc.id}InstanceInformation`}
                                    deploymentOptions={deploymentOptions}
                                />
                            ),
                            span: 5,
                        },
                        {
                            content: (
                                <VpcStatus
                                    vpc={vpc}
                                    testId={`${testId}${vpc.id}Status`}
                                />
                            ),
                            span: 3,
                        },
                        {
                            content: (
                                <VpcCidr
                                    vpc={vpc}
                                    testId={`${testId}${vpc.id}Cidr`}
                                />
                            ),
                            span: 4,
                        },
                        {
                            content: (
                                <Spin spinning={loadingPeers}>
                                    <VpcPeerings
                                        vpc={vpc}
                                        peerings={peeringData}
                                        testId={`${testId}${vpc.id}Peerings`}
                                    />
                                </Spin>
                            ),
                            span: 4,
                        },
                        {
                            content: (
                                <VpcDataStores
                                    dataStores={dataStores || 0}
                                    testId={`${testId}${vpc.id}DataStores`}
                                />
                            ),
                            span: 4,
                        },
                        {
                            align: 'end',
                            content: (
                                <Dropdown
                                    className={styles.VpcDropdownMenu}
                                    data-testid={`${testId}${vpc.id}Menu`}
                                    overlay={menu}
                                    placement="bottomRight"
                                    trigger={['click']}
                                >
                                    <Button
                                        data-testid={`${testId}${vpc.id}MenuButton`}
                                        icon={
                                            <CcxContextualMenuIcon
                                                testId={`${testId}${vpc.id}MenuButtonIcon`}
                                            />
                                        }
                                    ></Button>
                                </Dropdown>
                            ),
                            span: 4,
                        },
                    ],
                };
            });
            setRows(data);
        }
    }, [vpcs, vpcPeers, loadingPeers]);

    useEffect(() => {
        const AUTO_REFRESH_INTERVAL = 10000;
        const interval = setInterval(async () => {
            refreshVpcs && (await refreshVpcs());
        }, AUTO_REFRESH_INTERVAL);

        return () => clearInterval(interval);
    }, [vpcContext.cloud, vpcContext.region]);

    const handleCloudChange = (value: string) => {
        vpcContext.setCloud(value);
    };

    const handleRegionChange = (value: string) => {
        vpcContext.setRegion(value);
    };

    return (
        <section className={styles.VpcList} data-testid={`${testId}List`}>
            <Card
                className={styles.VpcListOptions}
                data-testid={`${testId}ListOptionsContainer`}
            >
                <VpcListOptions
                    cloud={vpcContext.cloud}
                    region={vpcContext.region}
                    clouds={clouds}
                    regions={regions}
                    onCloudChange={handleCloudChange}
                    onRegionChange={handleRegionChange}
                    disabled={loadingVpcs || loadingProviders}
                    testId={`${testId}ListOptions`}
                />
            </Card>

            {loadingProviders || loadingVpcs ? <LazyLoader type="row" /> : null}

            {(!vpcs || vpcs.length === 0) &&
            !loadingProviders &&
            !loadingVpcs ? (
                <AppEmpty
                    message="No VPCs created yet on the selected region."
                    testId={`${testId}ListEmpty`}
                />
            ) : null}

            {!loadingProviders && !loadingVpcs && vpcs && vpcs?.length > 0 ? (
                <div
                    className={styles.VpcListResults}
                    data-testid={`${testId}ListResults`}
                >
                    <AppGridTable
                        columns={columns}
                        rows={rows}
                        testId={testId}
                    />
                </div>
            ) : null}
        </section>
    );
}

export default Vpcs;
