import { Alert, Form, InputNumber, Space } from 'antd';
import InfoIcon from '@severalnines/bar-frontend-components/build/lib/General/InfoIcon';
import { useEffect, useMemo, useState } from 'react';
import DeploymentOptions from '../../../../types/DeploymentOptions';
import CloudProvider from '../../../../types/CloudProvider';
import InstanceVolumeType from '../../../../types/InstanceVolumeType';
import AppRadioGroupGrid, {
    AppRadioGridButton,
} from '../../../ccx/common/Form/AppRadioGroupGrid';
import User from '../../../../types/User';
import InstanceVolumeSize from '../../../../types/InstanceVolumeSize';
import InstanceVolumeIopsValue from '../../../../types/InstanceVolumeIopsValue';
import { FormInstance } from 'antd/lib/form';
import styles from './InstanceVolumeTypeForm.module.less';
import DatabaseVendor from '../../../../types/DatabaseVendor';

export type InstanceVolumeTypeFormProps = {
    form: FormInstance;
    selectedTech?: DatabaseVendor;
    deploymentOptions?: DeploymentOptions;
    cloudProvider: CloudProvider;

    // @todo remove this and handle change on this component, expose onVolumeTypeChange if it's required
    handleVolumeTypeChange: Function;
    // @todo user should be obtained from global state or context when it's implemented
    user?: User;
    // @todo volumeSize, volumeType, volumeIops, enteredVolumeSize can be obtained from sate or context
    volumeSize?: InstanceVolumeSize;
    volumeType?: InstanceVolumeType;
    volumeIops?: InstanceVolumeIopsValue;
    enteredVolumeSize?: number;

    testId: string;
    onVolumeSizeChange?: (value?: number) => void;
    onIOPSChange?: (value?: number) => void;
};
export default function InstanceVolumeTypeForm({
    form,
    selectedTech,
    deploymentOptions,
    cloudProvider,
    handleVolumeTypeChange,
    user,
    volumeSize,
    volumeType,
    volumeIops,
    enteredVolumeSize,

    testId = 'InstanceVolumeTypeForm',
    onVolumeSizeChange,
    onIOPSChange,
}: InstanceVolumeTypeFormProps) {
    const [storageType, setStorageType] = useState<string>(
        form.getFieldValue('storageType')
    );

    const handleIopsValidation = (rule: any, value: any) => {
        if (!value || !enteredVolumeSize || !volumeIops) {
            // resolve to avoid showing validation errors
            return Promise.resolve();
        }

        if (enteredVolumeSize * volumeIops?.maxPerGb >= value) {
            return Promise.resolve();
        }

        return Promise.reject(
            `Max allowed IOPS is ${volumeIops?.maxPerGb} per GB of volume size.`
        );
    };

    type StorageItem = {
        label?: string;
        info?: string;
        code?: string;
        volumeTypes?: InstanceVolumeType[];
    };
    const [storageItems, volumeTypes] = useMemo(() => {
        const volumeTypes =
            deploymentOptions?.getVolumeTypes(cloudProvider) || [];
        const storageItems: StorageItem[] = [];
        const ephemeral = volumeTypes?.find((v) => v.name === 'Ephemeral');
        if (ephemeral) {
            storageItems.push({
                label: 'Volumes',
                code: 'volumes',
                volumeTypes: volumeTypes?.filter((v) => v.name !== 'Ephemeral'),
            });
            storageItems.push({
                label: ephemeral.name,
                info: ephemeral.info,
                code: 'ephemeral',
                volumeTypes: [],
            });

            return [
                storageItems,
                storageItems.find((s) => s.code === storageType)?.volumeTypes ||
                    [],
            ];
        }
        return [[], volumeTypes];
    }, [deploymentOptions, cloudProvider, storageType]);

    useEffect(() => {
        const code = storageItems?.[0]?.code;
        if (code) {
            if (!storageType) {
                setStorageType(code);
            }
            if (!form.getFieldValue('storageType')) {
                form.setFieldsValue({
                    storageType: code,
                });
            }
        }
    }, [storageItems]);

    useEffect(() => {
        if (storageType === 'ephemeral') {
            form.setFieldsValue({
                volumeType: undefined,
                volumeSize: undefined,
                volumeIops: undefined,
            });
        }
    }, [storageType]);

    const handleStorageTypeChange = (e: any) => {
        setStorageType(e.target.value);
    };

    return (
        <div>
            {storageItems?.length > 0 && (
                <div>
                    <p>
                        <strong>Storage</strong>
                    </p>
                    <Form.Item
                        name="storageType"
                        label=""
                        rules={[
                            {
                                required: true,
                                message: 'Select a storage type',
                            },
                        ]}
                    >
                        <AppRadioGroupGrid
                            data-testid={`${testId}StorageTypeRadio`}
                            onChange={handleStorageTypeChange}
                            items={storageItems.map((t: StorageItem) => {
                                return (
                                    <AppRadioGridButton
                                        key={t.code}
                                        data-testid={`${testId}StorageTypeRadio${t.code}`}
                                        value={t.code}
                                    >
                                        <div
                                            className={
                                                styles.InstanceVolumeTypeFormCentered
                                            }
                                        >
                                            <Space>
                                                {t.label}
                                                {(t.info && (
                                                    <InfoIcon info={t.info} />
                                                )) ||
                                                    undefined}
                                            </Space>
                                        </div>
                                    </AppRadioGridButton>
                                );
                            })}
                        />
                    </Form.Item>
                </div>
            )}

            {volumeTypes.length > 0 && (
                <div>
                    <p>
                        <strong>Volume type</strong>
                    </p>

                    <Form.Item
                        name="volumeType"
                        label=""
                        rules={[
                            {
                                required: true,
                                message: 'Select a volume type',
                            },
                        ]}
                    >
                        <AppRadioGroupGrid
                            data-testid={`${testId}VolumeTypeRadio`}
                            onChange={handleVolumeTypeChange as any}
                            items={volumeTypes.map((t: InstanceVolumeType) => {
                                return (
                                    <AppRadioGridButton
                                        key={t.code}
                                        data-testid={`${testId}VolumeTypeRadio${t.code}`}
                                        value={t}
                                    >
                                        <div
                                            className={
                                                styles.InstanceVolumeTypeFormCentered
                                            }
                                        >
                                            <Space>
                                                {t.name}
                                                <InfoIcon info={t.info} />
                                            </Space>
                                        </div>
                                    </AppRadioGridButton>
                                );
                            })}
                        />
                    </Form.Item>

                    {volumeSize && selectedTech?.code !== 'redis' && (
                        <>
                            <p>
                                <strong>Storage size</strong>
                            </p>
                            {deploymentOptions?.getVolumeSizesInfo() && (
                                <Alert
                                    className={
                                        styles.InstanceVolumeTypeFormAlertBox
                                    }
                                    message={deploymentOptions?.getVolumeSizesInfo()}
                                    showIcon
                                    type="info"
                                />
                            )}
                            <Form.Item
                                name="volumeSize"
                                label=""
                                rules={[
                                    {
                                        required: true,
                                        message: 'Select volume size',
                                    },
                                ]}
                                extra={
                                    <small>
                                        The desired value must be in the range
                                        of {volumeSize?.min}
                                        GB and {volumeSize?.max}GB
                                    </small>
                                }
                            >
                                <InputNumber
                                    min={volumeSize?.min}
                                    max={volumeSize?.max}
                                    className={
                                        styles.InstanceVolumeTypeFormInputNumber
                                    }
                                    size="large"
                                    formatter={(value?: number) => `${value}`}
                                    onChange={(value) => {
                                        onVolumeSizeChange?.(
                                            value || undefined
                                        );
                                    }}
                                    parser={
                                        ((value: string) =>
                                            value
                                                .replace(' ', '')
                                                .replace(
                                                    volumeSize.unit,
                                                    ''
                                                )) as any
                                    }
                                    data-testid={`${testId}VolumeSizeInput`}
                                />
                            </Form.Item>
                        </>
                    )}

                    {volumeType && volumeType.hasIops && volumeIops && (
                        <>
                            <p>
                                <strong>IOPS</strong>
                            </p>
                            <Form.Item
                                name="volumeIops"
                                label=""
                                rules={[
                                    {
                                        required: true,
                                        message: 'Select IOPS',
                                    },
                                    {
                                        validator: handleIopsValidation,
                                    },
                                ]}
                            >
                                <InputNumber
                                    className={
                                        styles.InstanceVolumeTypeFormInputNumber
                                    }
                                    min={volumeIops?.min}
                                    max={volumeIops?.max}
                                    onChange={(value) => {
                                        onIOPSChange?.(value || undefined);
                                    }}
                                    size="large"
                                    data-testid={`${testId}VolumeIopsInput`}
                                />
                            </Form.Item>
                        </>
                    )}
                </div>
            )}
        </div>
    );
}
