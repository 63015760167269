import React, { ReactElement, useEffect, useState } from 'react';
import FriendlyTimestamp from '../../../types/FriendlyTimestamp';
import AppChart from '../../AppChart';
import prettyBytes from 'pretty-bytes';
import CcxComponentProps from '../../../core/CcxComponent';
import ChartCustomTooltip from './ChartCustomTooltip';
import numbro from 'numbro';
import PrometheusRamUsageStats from '../../../types/PrometheusRamUsageStats';

interface Props extends CcxComponentProps {
    data?: PrometheusRamUsageStats[];
    loading: boolean;
    displaySummary?: boolean;
    height?: number;
    width?: number;
}

function PrometheusRamUsageChart({
    data,
    loading,
    displaySummary,
    testId = 'PrometheusRamUsageChart',
    width,
    height = 250,
}: Props): ReactElement {
    const [chartData, setChartData] = useState<any>([]);
    const [summary, setSummary] = useState([]);

    useEffect(() => {
        if (data && data.length > 0) {
            const formattedData = data.map((stat: PrometheusRamUsageStats) => {
                return [
                    {
                        // from CC: row.ramused = row.ramtotal - row.ramfree - row.ramcached - row.rambuffers;
                        yValue: stat.used,
                        series: 'Used',
                        xValue: new FriendlyTimestamp(stat.created).getDate(),
                    },
                    {
                        yValue: stat.available,
                        series: 'Available',
                        xValue: new FriendlyTimestamp(stat.created).getDate(),
                    },
                    // {
                    //   yValue: stat.ramBuffers,
                    //   series: 'Buffers',
                    //   xValue: new FriendlyTimestamp(stat.created).getDate(),
                    // },
                    // {
                    //   yValue: stat.ramCached,
                    //   series: 'Cached',
                    //   xValue: new FriendlyTimestamp(stat.created).getDate(),
                    // },
                ];
            });

            setChartData(formattedData.flat());
        }
    }, [data]);

    const chartConfig = {
        title: {
            visible: true,
            text: 'RAM usage',
        },
        description: {
            visible: true,
            text: '',
        },
        height: height,
        width: width,
        autoFit: !width && !height,
        xField: 'xValue',
        yField: 'yValue',
        xAxis: {
            type: 'time',
            mask: 'HH:mm',
        },
        yAxis: {
            label: {
                formatter: (text: string, item: any, index: number) => {
                    return numbro(parseInt(text, 10)).format({
                        output: 'byte',
                        base: 'binary',
                        spaceSeparated: true,
                        mantissa: 0,
                    });
                },
            },
        },
        animation: false,
        seriesField: 'series',
        tooltip: {
            customContent: (title: any, data: any) => {
                const getTitle = () => {
                    if (!data || data.length === 0) return '';
                    return data[0]?.data?.xValue.toString();
                };

                const getContent = () => {
                    if (!data || data.length === 0) return [];
                    return data.map((d: any) => ({
                        color: d?.color,
                        label: d?.data?.series,
                        value: numbro(
                            parseInt(d?.data?.yValue.toFixed(2), 10)
                        ).format({
                            output: 'byte',
                            base: 'binary',
                            spaceSeparated: true,
                            mantissa: 0,
                        }),
                    }));
                };

                const getSummary = () => {
                    if (!data || data.length === 0) return [];
                    const sum = data.reduce(
                        (accumulator: number, d: any) =>
                            accumulator + d.data.yValue,
                        0
                    );
                    const average = sum / data.length;

                    return [
                        {
                            label: 'Total',
                            value: numbro(sum).format({
                                output: 'byte',
                                base: 'binary',
                                spaceSeparated: true,
                                mantissa: 0,
                            }),
                        },
                    ];
                };

                const tooltipData = {
                    title: getTitle(),
                    content: getContent(),
                    summary: getSummary(),
                };

                return (
                    <ChartCustomTooltip
                        title={tooltipData.title}
                        content={tooltipData.content}
                    />
                );
            },
        },
    };

    return (
        <AppChart
            type="area"
            data={chartData}
            config={chartConfig}
            summary={summary}
            loading={loading}
            displaySummary={displaySummary}
            testId={testId}
        />
    );
}

export default PrometheusRamUsageChart;
