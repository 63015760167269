import React, { ReactElement, Suspense } from 'react';
import { Tabs } from 'antd';
import CcxComponentProps from '../../core/CcxComponent';
import styles from './AdminTables.module.less';
import LazyLoader from '../LazyLoader';
import AdminDataStores from './dataStores/AdminDataStores';
import AdminUsers from './users/AdminUsers';

interface Props extends CcxComponentProps {}

function AdminTables({ testId = 'AdminTables' }: Props): ReactElement {
    return (
        <section className={styles.Admin} data-testid={testId}>
            <Tabs
                className={styles.AdminTabs}
                destroyInactiveTabPane={true}
                data-testid={`${testId}Tabs`}
                type="card"
            >
                <Tabs.TabPane tab="Datastores" key="data-stores">
                    <Suspense fallback={<LazyLoader />}>
                        <AdminDataStores />
                    </Suspense>
                </Tabs.TabPane>

                <Tabs.TabPane tab="Users" key="users">
                    <Suspense fallback={<LazyLoader />}>
                        <AdminUsers />
                    </Suspense>
                </Tabs.TabPane>
            </Tabs>
        </section>
    );
}

export default AdminTables;
