import ApiService from './ApiService';
import Deployment from '../types/Deployment';
import Deployments from '../types/Deployments';
import { API_DEPLOYMENT } from '../core/CcxEnv';
import DeploymentOptions from '../types/DeploymentOptions';

// constants
const API_URL = API_DEPLOYMENT;

export default class DataStoreService extends ApiService {
    /**
     * Get list of Data Stores.
     */
    static async getDataStores(
        projectUuid?: string,
        deploymentOptions?: DeploymentOptions
    ): Promise<Deployments> {
        // @TODO: Use the projectUuid when the backend is ready
        // const response = await this.request('GET', `deployments/${projectUuid}`);
        const deploymentOpt = new DeploymentOptions(deploymentOptions as any);
        const response = await this.request('GET', `deployments`);
        return new Deployments(response, deploymentOpt);
    }

    /**
     * Get details from a Data Store
     * @param {string} dataStoreUuid
     */
    static async getDataStore(dataStoreUuid: string): Promise<Deployment> {
        const response = await this.request(
            'GET',
            `deployment/${dataStoreUuid}`
        );
        return new Deployment(response);
    }

    protected static getApiUrl() {
        return API_URL;
    }
}
