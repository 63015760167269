import { Tooltip } from 'antd';
import { Tag, Row, Col } from 'antd';
import React, { ReactElement, useEffect, useState } from 'react';
import CcxComponentProps from '../../../../core/CcxComponent';
import DeploymentsItem from '../../../../types/DeploymentsItem';
import SmallContainer from '../../../ccx/common/SmallContainer';
import CcxIconInfoCircleOutlined from '../../../ccx/icons/CcxIconInfoCircleOutlined';
import LazyLoader from '../../../LazyLoader';
import styles from './DataStoreOverviewNetworkInformation.module.less';

interface Props extends CcxComponentProps {
    dataStore?: DeploymentsItem;
}

function DataStoreOverviewNetworkInformation({
    dataStore,
    testId = 'DataStoreOverviewNetworkInformation',
}: Props): ReactElement {
    const [cards, setCards] = useState<any>([]);

    useEffect(() => {
        if (dataStore) {
            let cards = [
                {
                    label: (
                        <div
                            className={
                                styles.DataStoreOverviewNetworkInformationLabel
                            }
                        >
                            Network
                        </div>
                    ),
                    testId: `${testId}CardNetwork`,
                    content: (
                        <SmallContainer
                            topChildren={
                                <div>
                                    {dataStore.vpc ? 'Private' : 'Public'}
                                </div>
                            }
                        />
                    ),
                    span: dataStore.vpc ? 5 : 10,
                },
                {
                    label: (
                        <div
                            className={
                                styles.DataStoreOverviewNetworkInformationLabel
                            }
                        >
                            Type
                        </div>
                    ),
                    testId: `${testId}CardType`,
                    content: (
                        <SmallContainer
                            topChildren={
                                <div>
                                    {dataStore.isSingleAZ()
                                        ? 'Single AZ'
                                        : 'Multi AZ'}
                                </div>
                            }
                        />
                    ),
                    span: 9,
                },
                {
                    label: (
                        <div
                            className={
                                styles.DataStoreOverviewNetworkInformationLabel
                            }
                        >
                            Availability zones
                        </div>
                    ),
                    testId: `${testId}CardAZs`,
                    content: dataStore.availabilityZones ? (
                        <SmallContainer
                            topChildren={
                                <div>
                                    {dataStore.availabilityZones.join(', ')}
                                </div>
                            }
                        />
                    ) : (
                        <Tooltip
                            placement="bottom"
                            title="Not available for public networks."
                        >
                            Not available <CcxIconInfoCircleOutlined />
                        </Tooltip>
                    ),
                    span: dataStore.vpc ? 10 : 14,
                },
            ];

            if (!dataStore.vpc) {
                cards.splice(1, 1);
            }

            if (!dataStore.availabilityZones) {
                cards.pop();
            }

            setCards(cards);
        }
    }, [dataStore]);

    return (
        <div
            className={styles.DataStoreOverviewNetworkInformation}
            data-testid={testId}
        >
            <div className={styles.DataStoreOverviewNetworkInformationContent}>
                {dataStore ? (
                    <>
                        <header>Network</header>
                        <main>
                            <Row
                                className={
                                    styles.DataStoreOverviewNetworkInformationColumns
                                }
                            >
                                {cards.map((c: any, i: number) => {
                                    return (
                                        <Col span={c.span} key={`cards${i}`}>
                                            <SmallContainer
                                                testId={c.testId}
                                                topChildren={c.label}
                                                middleChildren={c.content}
                                            />
                                        </Col>
                                    );
                                })}
                            </Row>
                        </main>
                    </>
                ) : (
                    <LazyLoader
                        type="row"
                        testId={`${testId}Loader`}
                        rows={2}
                    />
                )}
            </div>
        </div>
    );
}

export default DataStoreOverviewNetworkInformation;
