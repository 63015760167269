import { Alert, Form, InputNumber, notification } from 'antd';
import React from 'react';
import { FormInstance } from 'antd/lib/form';
import useBackupRetention from '../../core/hooks/useBackupRetention';
import DeploymentsItem from '../../types/DeploymentsItem';
import AppLoading from '../AppLoading';
import CcxIconCheckCircleTwoTone from '../ccx/icons/CcxIconCheckCircleTwoTone';
import CcxIconCloseCircleTwoTone from '../ccx/icons/CcxIconCloseCircleTwoTone';
import CronFormat from '@severalnines/bar-frontend-components/build/lib/Format/CronFormat';

type BackupsSettingsFormProps = {
    form: FormInstance;
    deployment: DeploymentsItem;
    onSuccess?: () => void;
};
export default function BackupsSettingsForm({
    form,
    deployment,
    onSuccess,
}: BackupsSettingsFormProps) {
    const { retention, loading, update, error } = useBackupRetention(
        deployment.dataStoreUuid
    );

    const handleSubmit = async (values: any) => {
        await update(values.retention);
        onSuccess?.();
    };
    return loading ? (
        <AppLoading />
    ) : (
        <Form
            form={form}
            layout="vertical"
            initialValues={{
                retention,
            }}
            onFinish={handleSubmit}
        >
            <Form.Item
                name="retention"
                label="Retention period"
                rules={[
                    {
                        required: true,
                        message: 'Please input retention period',
                    },
                ]}
            >
                <InputNumber
                    placeholder={'Enter retention'}
                    addonAfter={'days'}
                />
            </Form.Item>
            {error && (
                <Alert type={'error'} message={`Error: ${error.message}`} />
            )}
        </Form>
    );
}
