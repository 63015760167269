import { CloseCircleOutlined } from '@ant-design/icons';
import { Col } from 'antd';
import { Progress } from 'antd';
import { Row } from 'antd';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import numbro from 'numbro';
import CcxComponentProps from '../../core/CcxComponent';
import Restore from '../../types/Restore';
import BackupColumns from './BackupColumns';
import RestoreStatus from './RestoreStatus';

import styles from './RestoringBackups.module.less';

dayjs.extend(utc);

interface Props extends CcxComponentProps {
    currentRestore?: Restore;
}
function RestoringBackups({
    currentRestore,
    testId = 'RestoringBackups',
}: Props) {
    const formatSize = (size: any) => {
        return numbro(size).format({
            output: 'byte',
            base: 'binary',
            spaceSeparated: true,
            mantissa: 2,
        });
    };

    const format = 'DD/MM/YYYY hh:mm A UTC';

    let progressBar = null;
    if (currentRestore?.isStatusOk()) {
        progressBar = (
            <Progress percent={currentRestore?.progress} status="active" />
        );
    } else if (currentRestore?.isStatusError()) {
        progressBar = (
            <Progress percent={currentRestore?.progress} status="exception" />
        );
    } else if (currentRestore?.isStatusCompleted()) {
        progressBar = <Progress percent={currentRestore?.progress} />;
    }

    const appendTime = () => {
        if (currentRestore?.isStatusCompleted()) {
            return (
                currentRestore?.endedAt && (
                    <>
                        Ended at{' '}
                        {dayjs
                            .unix(parseInt(currentRestore?.endedAt))
                            .utc()
                            .format(format)}
                    </>
                )
            );
        }

        return (
            currentRestore?.startedAt && (
                <>
                    Started at{' '}
                    {dayjs
                        .unix(parseInt(currentRestore?.startedAt))
                        .utc()
                        .format(format)}
                </>
            )
        );
    };

    return (
        <section className={styles.RestoringBackups}>
            {currentRestore && (
                <>
                    <Row gutter={24}>
                        <Col span={23}>
                            <h4 data-testid={`${testId}Title`}>
                                <strong>Restoring Backup </strong>
                                <small>{appendTime()}</small>
                            </h4>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col>
                            <BackupColumns
                                title="Backup ID"
                                subTitle={currentRestore?.backupId}
                            />
                        </Col>
                        <Col>
                            <BackupColumns
                                title="Type"
                                subTitle={currentRestore?.backupType}
                            />
                        </Col>
                        {currentRestore?.backupSize && (
                            <Col>
                                <BackupColumns
                                    title="Size"
                                    subTitle={formatSize(
                                        currentRestore?.backupSize
                                    )}
                                />
                            </Col>
                        )}
                        <Col>
                            <BackupColumns
                                title="Status"
                                subTitle={
                                    <RestoreStatus
                                        restore={currentRestore}
                                        data-testid={`${testId}RestoreStatus`}
                                    />
                                }
                            />
                        </Col>
                        <Col span={14}>
                            <BackupColumns
                                title={`Progress`}
                                last={true}
                                subTitle={progressBar}
                            />
                        </Col>
                    </Row>
                </>
            )}
        </section>
    );
}

export default RestoringBackups;
