import { Tooltip } from 'antd';
import { ReactElement } from 'react';
import Job from '../../types/Job';
import DotLabelStatus from '../ccx/common/DotLabelStatus';
import SmallContainer from '../ccx/common/SmallContainer';
import CcxComponentProps from '../../core/CcxComponent';

interface Props extends CcxComponentProps {
    job?: Job;
    type?: string;
    showTooltips?: boolean;
    testId?: string;
    updating?: boolean;
}

function EventStatus({
    job,
    type = 'normal',
    testId = 'EventStatus',
    updating = false,
    showTooltips = true,
}: Props): ReactElement {
    let StatusComponent = (
        <DotLabelStatus
            type={type}
            glow={updating}
            label={job?.getStatusText()}
            testId={`${testId}StatusComponent`}
        />
    );

    let top = StatusComponent;

    if (showTooltips) {
        const tooltipTitle = job?.isStatusError()
            ? null
            : `${job?.getStatusText()}`;

        top = (
            <Tooltip placement="bottom" title={tooltipTitle}>
                {StatusComponent}{' '}
            </Tooltip>
        );
    }

    return <SmallContainer testId={testId} topChildren={top} />;
}

export default EventStatus;
