import React, { ReactElement, useEffect, useState } from 'react';
import CcxComponentProps from '../../../core/CcxComponent';
import useAdminNodes from '../../../core/hooks/useAdminNodes';
import Datastore from '../../../types/Datastore';
import Service from '../../../types/Service';
import AppLoading from '../../AppLoading';
import AppTable from '../../AppTable';
import DotLabelStatus from '../../ccx/common/DotLabelStatus';
import { Button, Col, Modal, Row } from 'antd';

interface Props extends CcxComponentProps {
    datastore: Datastore;
    visible: boolean;
    handleClose: any;
}

function AdminNodes({
    testId = 'AdminNodes',
    datastore,
    visible,
    handleClose,
}: Props): ReactElement {
    const { nodes, loading, updating } = useAdminNodes(datastore.id);

    const [tableData, setTableData] = useState<Service[]>([]);
    useEffect(() => {
        if (nodes) {
            let tempNodes = [...nodes];
            tempNodes.map((node: Service) => {
                node.setUserLogin(datastore.userLogin);
                node.setClusterUuid(datastore.id);
                return node;
            });

            setTableData(tempNodes);
        }
    }, [datastore, nodes]);

    const tableColumns = [
        {
            title: 'User',
            dataIndex: 'userLogin',
            key: 'userLogin',
        },
        {
            title: 'Cluster Uuid',
            dataIndex: 'clusterUuid',
            key: 'clusterUuid',
        },
        {
            title: 'Instance ID',
            dataIndex: 'instanceId',
            key: 'instanceId',
        },
        {
            title: 'Instance Type',
            dataIndex: 'instanceType',
            key: 'instanceType',
        },
        {
            title: 'Status',
            key: 'status',
            render: (value: string, record: any) => {
                let type = 'normal';

                if (record?.isStatusOk()) {
                    type = 'ok';
                } else if (record?.isStatusError()) {
                    type = 'error';
                } else if (record?.isStatusWarning()) {
                    type = 'warning';
                } else if (record?.isDeleting()) {
                    type = 'normal';
                } else if (record?.isDeploying()) {
                    type = 'info';
                }

                return (
                    <DotLabelStatus
                        type={type}
                        label={record?.getStatusText()}
                        glow={updating || loading}
                        testId={`${testId}StatusComponent`}
                    />
                );
            },
        },
        {
            title: 'Cloud Provider',
            dataIndex: 'cloudProvider',
            key: 'cloudProvider',
            render: (value: string, record: any) => {
                return record.cloudProvider.toUpperCase();
            },
        },
        {
            title: 'AZ',
            dataIndex: 'hostAvailabilityZone',
            key: 'hostAvailabilityZone',
        },
    ];

    return (
        <Modal
            title={<strong>Nodes</strong>}
            open={visible}
            data-testid={`${testId}Modal`}
            width={1250}
            onCancel={handleClose}
            footer={
                <Row justify="end">
                    <Col>
                        <Button
                            data-testid={`${testId}CancelButton`}
                            onClick={handleClose}
                        >
                            Cancel
                        </Button>
                    </Col>
                </Row>
            }
        >
            {loading ? (
                <AppLoading />
            ) : (
                <AppTable
                    columns={tableColumns}
                    testId={`${testId}Table`}
                    data={tableData}
                    rowKey="subID"
                    expandable={false}
                    pagination={{
                        hideOnSinglePage: true,
                        pageSize: 5,
                    }}
                />
            )}
        </Modal>
    );
}

export default AdminNodes;
