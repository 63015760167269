import React, { ReactElement, ReactNode } from 'react';
import { Button, Layout } from 'antd';
import styles from './AppLayoutTemplate.module.less';
import AppHeader from './AppHeader';
import AppFooter from './AppFooter';
import { hideFeedback, hideHeader } from '../../core/CcxEnv';
import FloatingContent from './FloatingContent';
import FeedbackForm from '../FeedbackForm';
import FeedbackService from '../../services/FeedbackService';
import { useParams } from 'react-router-dom';
import CcxIconMessageOutlined from '../ccx/icons/CcxIconMessageOutlined';

/**
 * Main site layout
 */

interface UrlProps {
    projectUuid: string;
    dataStoreUuid?: string;
    vpcUuid?: string;
}

type AppLayoutTemplateProps = {
    showHeader?: boolean;
    children: ReactNode;
    testId?: string;
};

export default function AppLayoutTemplate({
    showHeader = !hideHeader,
    children,
    testId = 'AppLayoutTemplate',
}: AppLayoutTemplateProps): ReactElement {
    let { projectUuid, dataStoreUuid, vpcUuid } = useParams<UrlProps>();
    const handleFeedbackFormSubmit = async ({
        feedbackType,
        feedbackMessage,
    }: any) => {
        await FeedbackService.sendFeedback({
            feedbackType: feedbackType,
            feedbackMessage: feedbackMessage,
            vpcUuid,
            dataStoreUuid,
            feedbackProps: projectUuid ? [projectUuid] : undefined,
        });
    };

    return (
        <Layout className={styles.AppLayout} data-testid="AppLayoutTemplate">
            {showHeader && <AppHeader />}
            <div className={styles.AppLayoutContentWrap}>
                {children}
                <AppFooter />
            </div>
            {!hideFeedback && (
                <FloatingContent>
                    <FeedbackForm
                        onSubmit={handleFeedbackFormSubmit}
                        showError={true}
                        showThanks={true}
                    >
                        <Button
                            type="primary"
                            className={styles.AppLayoutContentFeedback}
                        >
                            <CcxIconMessageOutlined />
                            <span>Give us Feedback</span>
                        </Button>
                    </FeedbackForm>
                </FloatingContent>
            )}
        </Layout>
    );
}
