import { Col } from 'antd';
import { Row } from 'antd';
import { Card } from 'antd';
import { ReactElement, useEffect, useState } from 'react';
import CcxComponentProps from '../../core/CcxComponent';
import CcxLogo from '../ccx/common/CcxLogo';
import styles from './PaymentCard.module.less';
import Cardtype from '../../types/Card';
import { notification } from 'antd';
import CcxIconInfoCircleTwoTone from '../ccx/icons/CcxIconInfoCircleTwoTone';
import { Modal } from 'antd';
import BillingService from '../../services/BillingService';
import CcxIconCheckCircleTwoTone from '../ccx/icons/CcxIconCheckCircleTwoTone';
import CcxIconCloseCircleTwoTone from '../ccx/icons/CcxIconCloseCircleTwoTone';
import { Menu } from 'antd';
import AppConfirmDialog from '../AppConfirmDialog';
import { Dropdown } from 'antd';
import { Button } from 'antd';
import CcxContextualMenuIcon from '../ccx/icons/CcxContextualMenuIcon';
import IconLabelStatusOk from '../ccx/common/IconLabelStatusOk';
import IconLabelStatusError from '../ccx/common/IconLabelStatusError';
import { DeleteOutlined } from '@ant-design/icons';

interface Props extends CcxComponentProps {
    card: Cardtype;
    refresh: Function;
    deleteIcon?: boolean;
}

export function PaymentCard({
    testId = 'PaymentCard',
    card,
    refresh,
    deleteIcon = false,
}: Props) {
    const [cardObject, setCardObject] = useState<any>();
    const [showIcon, setShowIcon] = useState<boolean>(false);

    useEffect(() => {
        if (card) {
            const onConfirmDelete = () => {
                return new Promise(async (resolve, reject) => {
                    notification.open({
                        message: 'Remove Card',
                        description: 'Your Card will be removed soon.',
                        icon: <CcxIconInfoCircleTwoTone />,
                    });

                    Modal.destroyAll();

                    try {
                        await BillingService.deleteCard(card.id);

                        refresh && (await refresh());

                        notification.open({
                            message: 'Remove Card',
                            description: 'Your Card was removed.',
                            icon: (
                                <CcxIconCheckCircleTwoTone twoToneColor="#52c41a" />
                            ),
                        });

                        resolve(null);
                    } catch (e) {
                        notification.open({
                            message: 'Remove Card',
                            description: `There was an error removing your Card. ${e}`,
                            icon: (
                                <CcxIconCloseCircleTwoTone twoToneColor="#eb2f96" />
                            ),
                        });

                        console.error(e);

                        reject();
                    }
                }).catch(() => console.log('Oops errors!'));
            };

            const makeDefault = () => {
                return new Promise(async (resolve, reject) => {
                    Modal.destroyAll();

                    try {
                        await BillingService.setDefaultCard(card.id);

                        refresh && (await refresh());

                        notification.open({
                            message: 'Set as Default',
                            description:
                                'This Card will be used as the default payment method.',
                            icon: <CcxIconInfoCircleTwoTone />,
                        });

                        resolve(null);
                    } catch (e) {
                        notification.open({
                            message: 'Set as Default',
                            description: `There was an error setting your default Card. ${e}`,
                            icon: (
                                <CcxIconCloseCircleTwoTone twoToneColor="#eb2f96" />
                            ),
                        });

                        console.error(e);

                        reject();
                    }
                }).catch(() => console.log('Oops errors!'));
            };

            const expired =
                new Date(`${card.expYear}-${card.expMonth}`) < new Date();

            const menu = (
                <Menu>
                    {!card.default && (
                        <Menu.Item key="makeDefault" onClick={makeDefault}>
                            Make a default card
                        </Menu.Item>
                    )}
                    <Menu.Item key="delete">
                        <AppConfirmDialog
                            critical={true}
                            onOk={onConfirmDelete}
                            label="Delete"
                            content="The selected Payment Method will be deleted."
                        />
                    </Menu.Item>
                </Menu>
            );

            const action = deleteIcon ? (
                <AppConfirmDialog
                    critical={true}
                    onOk={onConfirmDelete}
                    actionIcon={<DeleteOutlined style={{ color: 'black' }} />}
                    content="The selected Payment Method will be deleted."
                />
            ) : (
                <Dropdown
                    overlay={menu}
                    placement="bottomRight"
                    trigger={['click']}
                >
                    <Button icon={<CcxContextualMenuIcon />}></Button>
                </Dropdown>
            );

            const defaultStatus = card.default ? (
                <div className={styles.PaymentMethodDrawerStatus}>
                    <div
                        className={styles.PaymentMethodDrawerStatusDotGreen}
                    ></div>
                    <IconLabelStatusOk label="Default" />
                </div>
            ) : null;

            const expiryStatus = expired ? (
                <div className={styles.PaymentMethodDrawerStatus}>
                    <div
                        className={styles.PaymentMethodDrawerStatusDotRed}
                    ></div>
                    <IconLabelStatusError label="Expired" />
                </div>
            ) : null;

            const month = `0${card.expMonth}`.slice(-2);

            const expiry = `${month}/${card.expYear.toString().substr(-2)}`;

            const brandName = card.getElegantBrandName();

            let expirySpan = 14;

            if (expired && card.default) {
                expirySpan = 8;
            } else if (expired || card.default) {
                expirySpan = 11;
            }

            setCardObject({
                ...card,
                action,
                defaultStatus,
                expiryStatus,
                expiry,
                expired,
                brandName,
                expirySpan,
            });
        }
    }, [card]);

    return cardObject ? (
        <Card
            size="small"
            className={styles.PaymentCard}
            data-testid={`${testId}`}
            onMouseEnter={() => {
                setShowIcon(true);
            }}
            onMouseLeave={() => {
                setShowIcon(false);
            }}
        >
            <Row>
                <Col span={9} className={styles.PaymentCardDetails}>
                    <CcxLogo
                        className={styles.PaymentLogo}
                        logo={`ccx/${cardObject.brand}`}
                        size="medium"
                    />

                    <strong>{`${cardObject.brandName} *${cardObject.last4}`}</strong>
                </Col>
                <Col
                    span={cardObject.expirySpan}
                    className={styles.PaymentCardDetails}
                >
                    Expires: {cardObject.expiry}
                </Col>
                {cardObject.expired && (
                    <Col span={3} className={styles.PaymentCardDetails}>
                        {cardObject.expiryStatus}
                    </Col>
                )}
                {cardObject.default && (
                    <Col span={3} className={styles.PaymentCardDetails}>
                        {cardObject.defaultStatus}
                    </Col>
                )}
                <Col span={1}>{showIcon && cardObject.action}</Col>
            </Row>
        </Card>
    ) : null;
}
