import React, { ReactElement, useEffect, useState } from 'react';
import ButtonModalForm from '../ButtonModalForm';
import DbUserService, {AddUserForm } from '../../services/DbUserService';
import DbDatabase from '../../types/DbDatabase';
import { notification } from 'antd';
import CcxIconCheckCircleTwoTone from '../ccx/icons/CcxIconCheckCircleTwoTone';
import CcxIconCloseCircleTwoTone from '../ccx/icons/CcxIconCloseCircleTwoTone';
import DeploymentsItem from '../../types/DeploymentsItem';
import { getDatabaseUsernameValidators } from '../../core/validation/databaseValidation';
import { DatabaseType } from '../../types/Db';
import DbUser from "../../types/DbUser";
import { EditOutlined } from "@ant-design/icons";


type UpdateDbUserProps = {
    uuid: string;
    user : DbUser;
    onSuccess: Function;
    databases?: DbDatabase[] | undefined;
    currentDeployment?: DeploymentsItem | null | undefined;
};

function UpdateDbUserDrawer({
    onSuccess,
    uuid,
    user,
    databases,
    currentDeployment,
}: UpdateDbUserProps): ReactElement {
    const fieldsSetup = [
        {
            name: ['dbUsername'],
            testId: 'AddDbUserDbUsername',
            required: true,
            label: 'Username',
            validators: getDatabaseUsernameValidators(
                (currentDeployment?.getServiceName() as DatabaseType) ||
                    DatabaseType.UNKNOWN
            ),
        },
        {
            name: ['dbPassword'],
            testId: 'AddDbUserDbPassword',
            required: true,
            label: 'Specify a new user password',
            type: 'password',
        },
        {
            name: ['dbName'],
            testId: 'AddDbUserDbName',
            required: true,
            label: 'Database name',
            placeholder: 'Select a database',
            type: 'select',
            options: [],
        },
        {
            name: ['dbAuthPlugin'],
            testId: 'AddDbAuthPlugin',
            required: true,
            label: 'Authentication plugin',
            placeholder: 'Select authentication plugin',
            type: 'select',
            options: [
                {
                    label: 'caching_sha2_password',
                    value: 'caching_sha2_password',
                },
                {
                    label: 'mysql_native_password',
                    value: 'mysql_native_password',
                },
            ],
            defaultValue: 'caching_sha2_password',
        },
        {
            name: ['allowedHost'],
            testId: 'AddDbUserAllowedHost',
            required: false,
            label: 'Allowed host',
            placeholder: 'Enter the host that is allowed to connect from.',
        },
        {
            name: ['dbUserDbCommandCategories'],
            testId: 'AddDbUserDbCommandCategories',
            required: true,
            label: 'Categories',
            placeholder: '-@dangerous and -@admin always included',
            defaultValue: '+@all',
            validators: [{
                message: 'ensure the category  name as a @ sign and starts with a + or -, e.g +@all',
                pattern: /^\s*(\+@|-@)([^\s^,]+)(?:\s*(?:\+@|-@)([^\s^,]+)\s*)*\s*$/,
            },],
        },
        {
            name: ['dbUserDbCommands'],
            testId: 'AddDbUserDbCommands',
            required: false,
            label: 'Commands',
            placeholder: 'starts with a "+" or "-" (+get or -get)',
            validators: [{
                pattern: /^\s*[+-]([^\s^,]+)(?:\s*[+-]([^\s^,]+)\s*)*\s*$/,
                message: 'ensure commands starts with a + or - (e.g +get -set)',
            },]
        },
        {
            name: ['dbUserDbChannels'],
            testId: 'AddDbUserDbChannels',
            required: false,
            label: 'Channels',
            placeholder: 'starts with "&" (&channel, or &*)',
            validators: [{
                pattern: /^\s*&([^\s^,]+)(?:\s*&([^\s^,]+)\s*)*\s*$/,
                message: 'ensure the channel name starts with a & (e.g &channel,  or &*)',
            },]
        },
        {
            name: ['dbUserDbKeys'],
            testId: 'AddDbUserDbKeys',
            required: false,
            label: 'Keys',
            placeholder: 'starts with a "~" (~key, or ~*)',
            validators: [{
                pattern: /^\s*~([^\s^,]+)(?:\s*~([^\s^,]+)\s*)*\s*$/,
                message: 'ensure the key names starts with a ~ (~key,  or  ~*)',
            },]
        },
    ];
    const [fields, setFields] = useState<any>(fieldsSetup);
    const [title, setTitle] = useState<any>('Update user');

    useEffect(() => {
        if (databases !== undefined && databases?.length > 0) {
            const newFields = fields.map((f: any) => {
                if (f.name && f.name[0] === 'dbName') {
                    f.options = databases
                        ? databases.map((d) => {
                            return {
                                label: d.databaseName,
                                value: d.databaseName,
                            };
                        })
                        : [];
                }
                return f;
            });
            setFields(newFields);
        }
    }, [databases, user]);

    useEffect(() => {
        let { categories,  commands,  channels,  keys } = { categories: '', commands: '', channels: '', keys: '' };
        user.grants.split(',').forEach((grant: string) => {
            if (grant.startsWith('+@') || grant.startsWith('-@')) {
                if (categories !== '') {
                    categories = categories.concat(' ')
                }
                categories = categories.concat(grant)
            } else if (grant.startsWith('+')) {
                if (commands !== '') {
                    commands = commands.concat(' ')
                }
                commands = commands.concat(grant)
            } else if (grant.startsWith('&')) {
                if (channels !== '') {
                    channels = channels.concat(' ')
                }
                channels = channels.concat(grant)
            } else if (grant.startsWith('~')) {
                if (keys !== '') {
                    keys = keys.concat(' ')
                }
                keys = keys.concat(grant)
            }
        });
        const newFields = fields.map((f: any) => {
            switch (f.name[0]) {
                case 'dbUsername':
                    f.defaultValue = user.username;
                    f.disabled = true;
                    break;
                case 'dbAuthPlugin':
                    f.defaultValue = user.authPlugin;
                    break;
                case 'allowedHost':
                    f.defaultValue = user.hostAllow;
                    break
                case 'dbUserDbCommandCategories':
                    f.defaultValue = categories;
                    break;
                case 'dbUserDbCommands':
                    f.defaultValue = commands;
                    break;
                case 'dbUserDbChannels':
                    f.defaultValue = channels;
                    break;
                case 'dbUserDbKeys':
                    f.defaultValue = keys;
                    break;
            }
            return f;
        });
        setFields(newFields);
    }, [user]);

    useEffect(() => {
        setTitle('Update user');
        if (
            (currentDeployment?.isPostgreSql() ||
                currentDeployment?.isMSSQL()) &&
            fields
        ) {
            setFields(fields.slice(0, 2));
        } else if (currentDeployment?.isMariaDb() && fields) {
            setFields(fields.slice(0, 3));
        } else if (currentDeployment?.isPercona() && fields) {
            setFields(fields.slice(0, 4));
        } else if (currentDeployment?.isRedis() && fields) {
            setFields([fields[0],fields[1],fields[fields.length-4], fields[fields.length-3], fields[fields.length-2], fields[fields.length-1]]);
        }
    }, [currentDeployment]);

    const onCancel = () => {
        // do something here
    };

    const doApiRequest = async ({
        dbUsername,
        dbPassword,
        allowedHost,
        dbName,
        dbUserDbCommandCategories,
        dbUserDbCommands,
        dbUserDbKeys,
        dbUserDbChannels,
        isAdmin = true,
        dbAuthPlugin,
    }: AddUserForm) => {
        try {
            await DbUserService.addUser({
                uuid,
                dbUsername,
                dbPassword,
                allowedHost,
                dbName,
                dbPrivileges: [dbUserDbCommandCategories,dbUserDbCommands,dbUserDbKeys,dbUserDbChannels].join(' '),
                isAdmin,
                dbAuthPlugin,
            });

            notification.open({
                message: 'Update user',
                description: `New user successfully updated!`,
                icon: <CcxIconCheckCircleTwoTone twoToneColor="#52c41a" />,
            });

            return true;
        } catch (e) {
            notification.open({
                message: 'Update user',
                description: `There was an error updating your user. ${e}`,
                icon: <CcxIconCloseCircleTwoTone twoToneColor="#eb2f96" />,
            });

            console.error(e);

            throw e;
        }
    };

    return (
        <ButtonModalForm
            disabled={!currentDeployment?.operable}
            title={title}
            buttonText={title}
            buttonIcon={< EditOutlined />}
            onSubmit={doApiRequest}
            onSuccess={onSuccess}
            onCancel={onCancel}
            fields={fields}
            submitText="Update"
            formLayout="vertical"
            useGrid={true}
            stretchedClick={true}
        >
            <span></span>
        </ButtonModalForm>

    );
}

export default UpdateDbUserDrawer;
