import { Form } from 'antd';
import InstanceSize from '../../../types/InstanceSize';
import AppRadioGroupGrid, {
    AppRadioGridButton,
} from '../../ccx/common/Form/AppRadioGroupGrid';
import InstanceCard from './InstanceCard';
import InstanceVolumeTypeForm from './formParts/InstanceVolumeTypeForm';
import { ReactElement } from 'react';
import DeploymentOptions from '../../../types/DeploymentOptions';
import CloudProvider from '../../../types/CloudProvider';
import User from '../../../types/User';
import { FormInstance } from 'antd/lib/form';
import InstanceVolumeSize from '../../../types/InstanceVolumeSize';
import InstanceVolumeType from '../../../types/InstanceVolumeType';
import InstanceVolumeIopsValue from '../../../types/InstanceVolumeIopsValue';
import InfoIcon from '@severalnines/bar-frontend-components/build/lib/General/InfoIcon';
import DatabaseVendor from '../../../types/DatabaseVendor';

export type Props = {
    testId: string;
    selectedTech?: DatabaseVendor;
    handleInstanceSizeChange?: Function;
    deploymentOptions?: DeploymentOptions;
    cloudProvider: CloudProvider;
    user?: User;
    handleVolumeTypeChange: Function;
    form: FormInstance;
    volumeSize: InstanceVolumeSize | undefined;
    volumeType: InstanceVolumeType | undefined;
    volumeIops: InstanceVolumeIopsValue | undefined;
    enteredVolumeSize: number | undefined;
    setEnteredVolumeSize: Function;
    setEnteredIOPS: Function;
};

export default function InstanceSizeForm({
    testId,
    selectedTech,
    handleInstanceSizeChange,
    deploymentOptions,
    cloudProvider,
    user,
    form,
    handleVolumeTypeChange,
    volumeIops,
    volumeSize,
    volumeType,
    enteredVolumeSize,
    setEnteredVolumeSize,
    setEnteredIOPS,
}: Props): ReactElement {
    const onVolumeSizeChange = (value?: number) => {
        setEnteredVolumeSize && setEnteredVolumeSize(value);
    };

    const onIOPSChange = (value?: number) => {
        setEnteredIOPS && setEnteredIOPS(value);
    };

    return (
        <>
            <p>
                <strong>Instances</strong>
                <InfoIcon
                    info={
                        <span>
                            Select the instance matching your needs. Instances
                            types can be changed later.
                        </span>
                    }
                />
            </p>

            <Form.Item
                name="instanceSize"
                label=""
                rules={[
                    {
                        required: true,
                        message: 'Select your preferred instance',
                    },
                ]}
            >
                <AppRadioGroupGrid
                    data-testid={`${testId}InstanceSizeRadio`}
                    onChange={handleInstanceSizeChange as any}
                    items={
                        deploymentOptions
                            ?.getInstanceSizes(cloudProvider)
                            ?.map((s: InstanceSize) => {
                                return (
                                    <AppRadioGridButton
                                        key={s.code}
                                        data-testid={`${testId}InstanceSizeRadio${s.code}`}
                                        value={s}
                                        size={'large'}
                                    >
                                        <InstanceCard instance={s} />
                                    </AppRadioGridButton>
                                );
                            }) || []
                    }
                />
            </Form.Item>

            <InstanceVolumeTypeForm
                form={form}
                selectedTech={selectedTech}
                deploymentOptions={deploymentOptions}
                cloudProvider={cloudProvider}
                handleVolumeTypeChange={handleVolumeTypeChange}
                user={user}
                volumeSize={volumeSize}
                volumeType={volumeType}
                volumeIops={volumeIops}
                enteredVolumeSize={enteredVolumeSize}
                testId={testId}
                onVolumeSizeChange={onVolumeSizeChange}
                onIOPSChange={onIOPSChange}
            />
        </>
    );
}
