import React, { ReactElement, useEffect, useState } from 'react';
import { Typography, Row, Col } from 'antd';
import CcxComponentProps from '../../core/CcxComponent';
import Job from '../../types/Job';
import styles from './AppNotificationItem.module.less';
import CcxIconCloseCircleOutlined from '../ccx/icons/CcxIconCloseCircleOutlined';
import CcxIconCheckCircle from '../ccx/icons/CcxIconCheckCircle';
import CcxIconClockCircle from '../ccx/icons/CcxIconClockCircle';

interface Props extends CcxComponentProps {
    notification: Job;
}

function AppNotificationItem({
    notification,
    testId = 'AppNotificationItem',
}: Props): ReactElement {
    const { Text } = Typography;

    const [message, setMessage] = useState<ReactElement>();
    const [status, setStatus] = useState<ReactElement>();

    useEffect(() => {
        if (notification) {
            let instanceType = notification.data.instanceType;
            if (notification.isStatusOk()) {
                setMessage(
                    <span className={styles.AppNotificationItemMessage}>
                        Your new service is deployed successfully. <br />(
                        {instanceType})
                    </span>
                );
                setStatus(
                    <div className={styles.AppNotificationItemStateIconOk}>
                        <CcxIconCheckCircle />
                    </div>
                );
            } else if (notification.isStatusRunning()) {
                setMessage(
                    <span className={styles.AppNotificationItemMessage}>
                        Your new service is running. <br />({instanceType})
                    </span>
                );
                setStatus(
                    <div className={styles.AppNotificationItemStateIconRunning}>
                        <CcxIconClockCircle />
                    </div>
                );
            } else if (notification.isStatusError()) {
                setMessage(
                    <span className={styles.AppNotificationItemMessage}>
                        Deployment of your new service has failed. <br />(
                        {instanceType})
                    </span>
                );
                setStatus(
                    <div className={styles.AppNotificationItemStateIconError}>
                        <CcxIconCloseCircleOutlined />
                    </div>
                );
            } else {
                setMessage(
                    <span className={styles.AppNotificationItemMessage}>
                        Your service status will soon be updated
                    </span>
                );
                setStatus(
                    <div className={styles.AppNotificationItemStateIconRunning}>
                        <CcxIconClockCircle />
                    </div>
                );
            }
        }
    }, [notification]);

    return (
        <div data-testid={testId} className={styles.AppNotificationItem}>
            <Row gutter={32}>
                <Col span={2}>{status}</Col>
                <Col span={20}>
                    <div>{message}</div>
                    <small>
                        <Text type="secondary">{notification.createdAt}</Text>
                    </small>
                </Col>
            </Row>
        </div>
    );
}

export default AppNotificationItem;
