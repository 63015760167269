import React, { ReactElement, useEffect, useState } from 'react';
import styles from './HostDashboard.module.less';
import { Col, Row } from 'antd';
import usePrometheusLoadAverageStats from '../../../core/hooks/usePrometheusLoadAverageStats';
import usePrometheusCpuUsageStats from '../../../core/hooks/usePrometheusCpuUsageStats';
import usePrometheusRamUsageStats from '../../../core/hooks/usePrometheusRamUsageStats';
import usePrometheusNetworkUsageStats from '../../../core/hooks/usePrometheusNetworkUsageStats';
import usePrometheusDiskSpaceUsageStats from '../../../core/hooks/usePrometheusDiskSpaceUsageStats';
import usePrometheusDiskIOUtilStats from '../../../core/hooks/usePrometheusDiskIOUtilStats';
import usePrometheusDiskIopsStats from '../../../core/hooks/usePrometheusDiskIopsStats';
import usePrometheusDiskThroughputStats from '../../../core/hooks/usePrometheusDiskThroughputStats';
import PrometheusLoadAvgChart from '../../ccx/charts/PrometheusLoadAvgChart';
import PrometheusCpuUsageChart from '../../ccx/charts/PrometheusCpuUsageChart';
import PrometheusDiskIopsChart from '../../ccx/charts/PrometheusDiskIopsChart';
import PrometheusDiskIOUtilizationChart from '../../ccx/charts/PrometheusDiskIOUtilizationChart';
import PrometheusDiskSpaceUsageChart from '../../ccx/charts/PrometheusDiskSpaceUsageChart';
import PrometheusDiskThroughputChart from '../../ccx/charts/PrometheusDiskThroughputChart';
import PrometheusNetworkUsageChart from '../../ccx/charts/PrometheusNetworkUsageChart';
import PrometheusRamUsageChart from '../../ccx/charts/PrometheusRamUsageChart';

type HostDashboardProps = {
    uuid: string;
    host_uuid: string;
    port: number;
    from?: string;
    to?: string;
    interval: number;
    hidden?: boolean;
    displaySummary?: boolean;
    onLoading?: Function;
};

function HostDashboard({
    uuid,
    host_uuid,
    port,
    from,
    to,
    interval,
    hidden,
    displaySummary,
    onLoading,
}: HostDashboardProps): ReactElement {
    const {
        stats: loadAvgStats,
        refresh: loadAvgStatsRefresh,
        loading: loadAvgStatsLoading,
    } = usePrometheusLoadAverageStats(uuid, host_uuid, from, to);
    const {
        stats: cpuUsageStats,
        refresh: cpuUsageStatsRefresh,
        loading: cpuUsageStatsLoading,
    } = usePrometheusCpuUsageStats(uuid, host_uuid, from, to);
    const {
        stats: ramUsageStats,
        refresh: ramUsageStatsRefresh,
        loading: ramUsageStatsLoading,
    } = usePrometheusRamUsageStats(uuid, host_uuid, from, to);
    const {
        stats: networkUsageStats,
        refresh: networkUsageStatsRefresh,
        loading: networkUsageStatsLoading,
    } = usePrometheusNetworkUsageStats(uuid, host_uuid, from, to);
    const {
        stats: diskSpaceUsageStats,
        refresh: diskSpaceUsageStatsRefresh,
        loading: diskSpaceUsageStatsLoading,
    } = usePrometheusDiskSpaceUsageStats(uuid, host_uuid, from, to);
    const {
        stats: diskIOUtilStats,
        refresh: diskIOUtilStatsRefresh,
        loading: diskIOUtilStatsLoading,
    } = usePrometheusDiskIOUtilStats(uuid, host_uuid, from, to);
    const {
        stats: diskIopsStats,
        refresh: diskIopsStatsRefresh,
        loading: diskIopsStatsLoading,
    } = usePrometheusDiskIopsStats(uuid, host_uuid, from, to);
    const {
        stats: diskThroughputStats,
        refresh: diskThroughputStatsRefresh,
        loading: diskThroughputStatsLoading,
    } = usePrometheusDiskThroughputStats(uuid, host_uuid, from, to);

    const [loadAvgInterval, setLoadAvgInterval] = useState<number | undefined>(
        undefined
    );
    const [cpuUsageInterval, setCpuUsageInterval] = useState<
        number | undefined
    >(undefined);
    const [ramUsageInterval, setRamUsageInterval] = useState<
        number | undefined
    >(undefined);
    const [networkUsageInterval, setNetworkUsageInterval] = useState<
        number | undefined
    >(undefined);
    const [diskSpaceUsageInterval, setDiskSpaceUsageInterval] = useState<
        number | undefined
    >(undefined);
    const [diskIOUtilInterval, setDiskIOUtilInterval] = useState<
        number | undefined
    >(undefined);
    const [diskIopsInterval, setDiskIopsInterval] = useState<
        number | undefined
    >(undefined);
    const [diskThroughputInterval, setDiskThroughputInterval] = useState<
        number | undefined
    >(undefined);
    const [refreshInterval, setRefreshInterval] = useState(interval);

    useEffect(() => {
        let interval: any;
        if (loadAvgStats && refreshInterval > 0) {
            interval = setInterval(() => {
                loadAvgStatsRefresh();
            }, refreshInterval);
            setLoadAvgInterval(interval);
        }
        return () => clearInterval(interval);
    }, [loadAvgStats, refreshInterval]);

    useEffect(() => {
        let interval: any;
        if (cpuUsageStats && refreshInterval > 0) {
            interval = setInterval(() => {
                cpuUsageStatsRefresh();
            }, refreshInterval);
            setLoadAvgInterval(interval);
        }
        return () => clearInterval(interval);
    }, [cpuUsageStats, refreshInterval]);

    useEffect(() => {
        let interval: any;
        if (ramUsageStats && refreshInterval > 0) {
            interval = setInterval(() => {
                ramUsageStatsRefresh();
            }, refreshInterval);
            setLoadAvgInterval(interval);
        }
        return () => clearInterval(interval);
    }, [ramUsageStats, refreshInterval]);

    useEffect(() => {
        let interval: any;
        if (networkUsageStats && refreshInterval > 0) {
            interval = setInterval(() => {
                networkUsageStatsRefresh();
            }, refreshInterval);
            setLoadAvgInterval(interval);
        }
        return () => clearInterval(interval);
    }, [networkUsageStats, refreshInterval]);

    useEffect(() => {
        let interval: any;
        if (diskSpaceUsageStats && refreshInterval > 0) {
            interval = setInterval(() => {
                diskSpaceUsageStatsRefresh();
            }, refreshInterval);
            setLoadAvgInterval(interval);
        }
        return () => clearInterval(interval);
    }, [diskSpaceUsageStats, refreshInterval]);

    useEffect(() => {
        let interval: any;
        if (diskIOUtilStats && refreshInterval > 0) {
            interval = setInterval(() => {
                diskIOUtilStatsRefresh();
            }, refreshInterval);
            setLoadAvgInterval(interval);
        }
        return () => clearInterval(interval);
    }, [diskIOUtilStats, refreshInterval]);

    useEffect(() => {
        let interval: any;
        if (diskIopsStats && refreshInterval > 0) {
            interval = setInterval(() => {
                diskIopsStatsRefresh();
            }, refreshInterval);
            setLoadAvgInterval(interval);
        }
        return () => clearInterval(interval);
    }, [diskIopsStats, refreshInterval]);

    useEffect(() => {
        let interval: any;
        if (diskThroughputStats && refreshInterval > 0) {
            interval = setInterval(() => {
                diskThroughputStatsRefresh();
            }, refreshInterval);
            setLoadAvgInterval(interval);
        }
        return () => clearInterval(interval);
    }, [diskThroughputStats, refreshInterval]);

    useEffect(() => {
        if (interval === 0) {
            // when interval is 0, we must pause the refresh (clear existing intervals)
            clearInterval(loadAvgInterval);
            clearInterval(cpuUsageInterval);
            clearInterval(ramUsageInterval);
            clearInterval(networkUsageInterval);
            clearInterval(diskSpaceUsageInterval);
            clearInterval(diskIOUtilInterval);
            clearInterval(diskIopsInterval);
            clearInterval(diskThroughputInterval);
            setLoadAvgInterval(0);
            setCpuUsageInterval(0);
            setRamUsageInterval(0);
            setNetworkUsageInterval(0);
            setDiskSpaceUsageInterval(0);
            setDiskIOUtilInterval(0);
            setDiskIopsInterval(0);
            setDiskThroughputInterval(0);
        }
        setRefreshInterval(interval);
    }, [interval]);

    useEffect(() => {
        if (hidden) {
            // clear interval to stop updating data if the tab is not active (charts are not visible)
            clearInterval(loadAvgInterval);
            clearInterval(cpuUsageInterval);
            clearInterval(ramUsageInterval);
            clearInterval(networkUsageInterval);
            clearInterval(diskSpaceUsageInterval);
            clearInterval(diskIOUtilInterval);
            clearInterval(diskIopsInterval);
            clearInterval(diskThroughputInterval);
            setLoadAvgInterval(0);
            setCpuUsageInterval(0);
            setRamUsageInterval(0);
            setNetworkUsageInterval(0);
            setDiskSpaceUsageInterval(0);
            setDiskIOUtilInterval(0);
            setDiskIopsInterval(0);
            setDiskThroughputInterval(0);
        } else if (
            loadAvgInterval === 0 ||
            cpuUsageInterval === 0 ||
            ramUsageInterval === 0 ||
            networkUsageInterval === 0 ||
            diskSpaceUsageInterval === 0 ||
            diskIOUtilInterval === 0 ||
            diskIopsInterval === 0 ||
            diskThroughputInterval === 0
        ) {
            // force refresh if the interval is 0, otherwise, the interval will refresh data soon
            // interval is undefined on the first load
            // interval will be 0 when paused or tab is hidden
            loadAvgStatsRefresh();
            cpuUsageStatsRefresh();
            ramUsageStatsRefresh();
            networkUsageStatsRefresh();
            diskSpaceUsageStatsRefresh();
            diskIOUtilStatsRefresh();
            diskIopsStatsRefresh();
            diskThroughputStatsRefresh();
        }
    }, [hidden]);

    useEffect(() => {
        if (
            loadAvgStatsLoading ||
            cpuUsageStatsLoading ||
            ramUsageStatsLoading ||
            networkUsageStatsLoading ||
            diskSpaceUsageStatsLoading ||
            diskIOUtilStatsLoading ||
            diskIopsStatsLoading ||
            diskThroughputStatsLoading
        ) {
            onLoading && onLoading(true);
        } else {
            onLoading && onLoading(false);
        }
    }, [
        loadAvgStatsLoading,
        cpuUsageStatsLoading,
        ramUsageStatsLoading,
        networkUsageStatsLoading,
        diskSpaceUsageStatsLoading,
        diskIOUtilStatsLoading,
        diskIopsStatsLoading,
        diskThroughputStatsLoading,
    ]);

    return (
        <section className={styles.HostDashboard}>
            {/* CPU */}
            <Row
                gutter={[32, { xs: 8, sm: 8, md: 16, lg: 32, xl: 40, xxl: 48 }]}
                className={styles.HostDashboardRow}
            >
                <Col
                    className={styles.HostDashboardCol}
                    xs={24}
                    sm={24}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                >
                    <PrometheusLoadAvgChart
                        data={loadAvgStats}
                        loading={loadAvgStatsLoading}
                        displaySummary={displaySummary}
                    />
                </Col>
                <Col
                    className={styles.HostDashboardCol}
                    xs={24}
                    sm={24}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                >
                    <PrometheusCpuUsageChart
                        data={cpuUsageStats}
                        loading={cpuUsageStatsLoading}
                        displaySummary={displaySummary}
                    />
                </Col>

                {/* RAM */}
                <Col
                    className={styles.HostDashboardCol}
                    xs={24}
                    sm={24}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                >
                    <PrometheusRamUsageChart
                        data={ramUsageStats}
                        loading={ramUsageStatsLoading}
                        displaySummary={displaySummary}
                    />
                </Col>

                {/* NETWORK */}
                <Col
                    className={styles.HostDashboardCol}
                    xs={24}
                    sm={24}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                >
                    <PrometheusNetworkUsageChart
                        data={networkUsageStats}
                        loading={networkUsageStatsLoading}
                        displaySummary={displaySummary}
                    />
                </Col>

                {/* DISK */}
                <Col
                    className={styles.HostDashboardCol}
                    xs={24}
                    sm={24}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                >
                    <PrometheusDiskSpaceUsageChart
                        data={diskSpaceUsageStats}
                        loading={diskSpaceUsageStatsLoading}
                        displaySummary={displaySummary}
                    />
                </Col>
                <Col
                    className={styles.HostDashboardCol}
                    xs={24}
                    sm={24}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                >
                    <PrometheusDiskIOUtilizationChart
                        data={diskIOUtilStats}
                        loading={diskIOUtilStatsLoading}
                        displaySummary={displaySummary}
                    />
                </Col>

                <Col
                    className={styles.HostDashboardCol}
                    xs={24}
                    sm={24}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                >
                    <PrometheusDiskIopsChart
                        data={diskIopsStats}
                        loading={diskIopsStatsLoading}
                        displaySummary={displaySummary}
                    />
                </Col>
                <Col
                    className={styles.HostDashboardCol}
                    xs={24}
                    sm={24}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                >
                    <PrometheusDiskThroughputChart
                        data={diskThroughputStats}
                        loading={diskThroughputStatsLoading}
                        displaySummary={displaySummary}
                    />
                </Col>
            </Row>
        </section>
    );
}

export default HostDashboard;
