import './WizardSelectTypeStep.less';
import React, { DOMAttributes, useEffect, useState } from 'react';
import { Button, Col, Empty, Row, Space, Typography } from 'antd';
import BoxSelect from '@severalnines/bar-frontend-components/build/lib/DataEntry/BoxSelect';

import classNames from 'classnames';
import TypographyText from '@severalnines/bar-frontend-components/build/lib/General/TypographyText';
import {
    CheckCircleFilled,
    DeploymentUnitOutlined,
    FileOutlined,
} from '@ant-design/icons';
import TextFormat from '@severalnines/bar-frontend-components/build/lib/Format/TextFormat';
import getAntIconSvgFile from '@severalnines/bar-frontend-components/build/lib/helpers/getAntIconSvgFile';
import {
    getStatusFormatColor,
    StatusFormatStatus,
} from '@severalnines/bar-frontend-components/build/lib/Format/StatusFormat';

import logo from './img-type-zero-state.svg';
import { Tag } from 'antd';
import { Tooltip } from 'antd';

export type WizardSelectTypeStepIcon = {
    src: string;
    name: string;
};

export type WizardSelectTypeStepItem = DOMAttributes<any> & {
    name: string;
    key: string;
    value: string;
    icon?: string | JSX.Element | WizardSelectTypeStepIcon;
    description: string | React.ReactElement;
    documentationLink?: string;
    disabled?: boolean;
    selected?: boolean;
    beta?: boolean;
    extra?: string | React.ReactElement;
    defaultIconComponent?: React.ExoticComponent;
};

export type WizardSelectTypeStepItemDetail = {
    item?: WizardSelectTypeStepItem;
    onContinue: () => void;
    selected?: boolean;
    implicitContinue?: boolean;
    emptyState?: React.ReactElement;
    defaultIconComponent?: React.ExoticComponent;
};
export type WizardSelectTypeStepItemDetailEmptyState = {
    description?: React.ReactNode;
    src?: any;
    imgAlt?: string;
};

const Item = ({
    name,
    icon,
    disabled,
    beta,
    selected = false,
    defaultIconComponent = DeploymentUnitOutlined,
    ...rest
}: WizardSelectTypeStepItem) => {
    return (
        <BoxSelect.Item
            className={classNames('WizardSelectTypeStep-select_item', {
                'WizardSelectTypeStep-select_item--selected': selected,
            })}
            icon={
                (icon && (
                    <img
                        src={getIconSvgFile(icon)}
                        alt={name}
                        height={'35px'}
                    />
                )) || <DefaultIcon size={35} component={defaultIconComponent} />
            }
            {...rest}
        >
            <Space
                style={{
                    justifyContent: 'space-between',
                    marginLeft: '5px',
                    marginRight: '10px',
                }}
            >
                <Typography.Text strong style={{ margin: 'auto' }}>
                    {name}{' '}
                    {beta && (
                        <Tooltip
                            placement="bottom"
                            title={`The CCX team tests new features and products before
releasing them in beta. However, beta features and products
may contain bugs, vulnerabilities, performance issues, or
other problems. To minimize risk, the CCX team recommends
to test these beta features in a sandbox environment before
gradually using them in production scenarios.`}
                        >
                            <Tag color="blue">Beta</Tag>
                        </Tooltip>
                    )}
                </Typography.Text>
                {selected ? (
                    <TypographyText primary>
                        <CheckCircleFilled />
                    </TypographyText>
                ) : null}
                {disabled ? <TextFormat warning>Coming soon</TextFormat> : null}
            </Space>
        </BoxSelect.Item>
    );
};

const DetailEmptyState = ({
    description,
    src,
    imgAlt,
}: WizardSelectTypeStepItemDetailEmptyState) => {
    return (
        <Empty
            description={
                description || (
                    <span>
                        Choose a database on the left <br /> that you want to
                        deploy
                    </span>
                )
            }
            image={<img src={src || logo} alt={imgAlt || 'Select an option'} />}
        />
    );
};

const Detail = ({
    item,
    onContinue,
    selected = false,
    implicitContinue = false,
    emptyState,
    defaultIconComponent = DeploymentUnitOutlined,
}: WizardSelectTypeStepItemDetail) => {
    return (
        <div className="WizardSelectTypeStep-detail_wrapper">
            {item ? (
                <>
                    <div className="WizardSelectTypeStep-detail">
                        <Space direction="vertical">
                            {(item.icon && (
                                <img
                                    style={{ width: '50px' }}
                                    src={getIconSvgFile(item.icon)}
                                    alt={item.name}
                                />
                            )) || (
                                <DefaultIcon
                                    size={50}
                                    component={defaultIconComponent}
                                />
                            )}
                            <Typography.Title level={3}>
                                {item.name}
                            </Typography.Title>
                            <Typography.Paragraph>
                                {item.description}
                            </Typography.Paragraph>
                            <Space
                                size="large"
                                align="center"
                                style={{
                                    width: '100%',
                                    justifyContent: 'space-between',
                                    visibility: selected ? 'visible' : 'hidden',
                                }}
                            >
                                <div>{item.extra}</div>

                                <>
                                    {item?.documentationLink && (
                                        <a
                                            href={item.documentationLink}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <FileOutlined /> Learn more
                                        </a>
                                    )}
                                    {implicitContinue && (
                                        <Button
                                            type="primary"
                                            onClick={onContinue}
                                        >
                                            Continue
                                        </Button>
                                    )}
                                </>
                            </Space>
                        </Space>
                    </div>
                    <style
                        dangerouslySetInnerHTML={
                            (item.icon && {
                                __html: `.WizardSelectTypeStep-detail:before {
                                    background-image: url("${getIconSvgFile(
                                        item.icon
                                    )}")
                                    }`,
                            }) || {
                                __html: `.WizardSelectTypeStep-detail:before {
                                    background-color: ${getStatusFormatColor(
                                        StatusFormatStatus.info
                                    )};
                                    -webkit-mask-image: url("${getDefaultIconSvgFile(
                                        defaultIconComponent
                                    )}");
                                    mask-image: url(${getDefaultIconSvgFile(
                                        defaultIconComponent
                                    )});
                                }`,
                            }
                        }
                    />
                </>
            ) : (
                emptyState || <DetailEmptyState />
            )}
        </div>
    );
};

export type WizardSelectTypeStepProps = {
    onSelect: (item: WizardSelectTypeStepItem) => void;
    onConfirm: (item: WizardSelectTypeStepItem) => void;
    footer: React.ReactElement;
    items: WizardSelectTypeStepItem[];
    defaultValue?: string;
    emptyState?: React.ReactElement;
    defaultIconComponent?: React.ExoticComponent;
};
type WizardSelectType = React.FC<WizardSelectTypeStepProps> & {
    Item: React.FC<WizardSelectTypeStepItem>;
    Detail: React.FC<WizardSelectTypeStepItemDetail>;
    DetailEmptyState: React.FC<WizardSelectTypeStepItemDetailEmptyState>;
};
const WizardSelectTypeStep: WizardSelectType = ({
    onConfirm,
    onSelect,
    footer,
    items,
    defaultValue,
    emptyState,
    defaultIconComponent,
}: WizardSelectTypeStepProps) => {
    const [selectedItem, setSelectedItem] =
        useState<WizardSelectTypeStepItem>();
    const [hoveredItem, setHoveredItem] = useState<WizardSelectTypeStepItem>();

    const handleItemMouseOver = (item: WizardSelectTypeStepItem) => {
        if (!item.disabled) {
            setHoveredItem(item);
        }
    };
    const handleItemMouseOut = () => {
        setHoveredItem(undefined);
    };

    const handleChange = (key: string) => {
        const item = items.find((item) => item.key === key);
        if (item) {
            setSelectedItem(item);
            onSelect(item);
        }
    };
    const handleConfirm = () => {
        if (selectedItem) {
            onConfirm(selectedItem);
        }
    };
    const currentItem: WizardSelectTypeStepItem | undefined =
        hoveredItem || selectedItem;

    useEffect(() => {
        if (defaultValue) {
            handleChange(defaultValue);
        }
    }, [defaultValue]);

    return (
        <Row gutter={[16, 16]} className="WizardSelectTypeStep">
            <Col span={12}>
                <BoxSelect
                    cols={1}
                    onChange={handleChange}
                    className="WizardSelectTypeStep-select"
                    itemBodyStyle={{ padding: '0px' }}
                    defaultValue={defaultValue}
                >
                    {items.map((item) => (
                        <WizardSelectTypeStep.Item
                            key={item.key}
                            value={item.key}
                            name={item.name}
                            beta={item.beta}
                            description={item.description}
                            disabled={item.disabled}
                            icon={item.icon}
                            selected={
                                item.key === (selectedItem && selectedItem.key)
                            }
                            onMouseOver={() => handleItemMouseOver(item)}
                            onMouseOut={handleItemMouseOut}
                            defaultIconComponent={defaultIconComponent}
                        />
                    ))}
                </BoxSelect>
            </Col>
            <Col span={12}>
                <WizardSelectTypeStep.Detail
                    selected={
                        currentItem &&
                        currentItem.key === (selectedItem && selectedItem.key)
                    }
                    item={currentItem}
                    onContinue={handleConfirm}
                    emptyState={emptyState}
                    defaultIconComponent={defaultIconComponent}
                />
            </Col>
            {footer && <Col span={24}>{footer}</Col>}
        </Row>
    );
};

type DefaultIconProps = {
    size: number;
    component: React.ExoticComponent<any>;
};
function DefaultIcon({ size, component: Component }: DefaultIconProps) {
    return (
        <Component
            className="WizardSelectTypeStep--DefaultIcon"
            style={{ fontSize: size }}
        />
    );
}

function getDefaultIconSvgFile(Component: React.ExoticComponent<any>) {
    return getAntIconSvgFile(<Component />);
}

/**
 * require() icon svg file
 * @param icon - string with icon file name or ant design icon JSX
 */
function getIconSvgFile(icon: string | JSX.Element | WizardSelectTypeStepIcon) {
    if ((icon as WizardSelectTypeStepIcon).src) {
        return (icon as WizardSelectTypeStepIcon).src;
    } else if (typeof icon === 'string') {
        return require(`@severalnines/bar-frontend-components/build/lib/General/icons/${icon}`);
    } else {
        return getAntIconSvgFile(icon as JSX.Element);
    }
}

WizardSelectTypeStep.Item = Item;
WizardSelectTypeStep.Detail = Detail;
WizardSelectTypeStep.DetailEmptyState = DetailEmptyState;
export default WizardSelectTypeStep;
