import React, { ReactElement, useEffect, useState } from 'react';
import { Tooltip } from 'antd';
import CcxComponentProps from '../../../../core/CcxComponent';
import DeploymentsItem from '../../../../types/DeploymentsItem';
import LazyLoader from '../../../LazyLoader';
import styles from './DataStoreOverviewServicesState.module.less';
import CcxIconCheckCircle from '../../../ccx/icons/CcxIconCheckCircle';
import CcxIconCloseCircleOutlined from '../../../ccx/icons/CcxIconCloseCircleOutlined';

interface Props extends CcxComponentProps {
    dataStore?: DeploymentsItem;
    services?: any;
}

function DataStoreOverviewServicesState({
    dataStore,
    services,
    testId = 'DataStoreOverviewServicesState',
}: Props): ReactElement {
    const [total, setTotal] = useState(0);
    const [failed, setFailed] = useState(0);

    useEffect(() => {
        if (services) {
            let stopped = services?.dbServices?.reduce(
                (accumulator: number, current: any) => {
                    return current.status === 'error'
                        ? accumulator + 1
                        : accumulator;
                },
                0
            );
            stopped = services?.lbServices?.reduce(
                (accumulator: number, current: any) => {
                    return current.status === 'error'
                        ? accumulator + 1
                        : accumulator;
                },
                stopped
            );
            setFailed(stopped);
            setTotal(
                services?.dbServices?.length + services?.lbServices?.length
            );
        }
    }, [services]);

    return (
        <div
            className={styles.DataStoreOverviewServicesState}
            data-testid={testId}
        >
            <div className={styles.DataStoreOverviewServicesStateContent}>
                {dataStore && services ? (
                    <>
                        <header>State of nodes</header>
                        <main>
                            {failed === 0 && (
                                <div>
                                    <div
                                        className={
                                            styles.DataStoreOverviewServicesStateIconOk
                                        }
                                    >
                                        <CcxIconCheckCircle />
                                    </div>
                                    All nodes are running fine. No issues
                                    reported.
                                </div>
                            )}
                            {failed > 0 && (
                                <>
                                    <Tooltip
                                        placement="bottom"
                                        title="Our Support Team has been notified of an incident in regards to this issue. No further action is required."
                                    >
                                        <div>
                                            <div
                                                className={
                                                    styles.DataStoreOverviewServicesStateIconError
                                                }
                                            >
                                                <CcxIconCloseCircleOutlined />
                                            </div>
                                            {failed} out of {total} nodes are
                                            reporting issues.
                                        </div>
                                    </Tooltip>
                                </>
                            )}
                        </main>
                    </>
                ) : (
                    <LazyLoader
                        type="row"
                        testId={`${testId}Loader`}
                        rows={2}
                    />
                )}
            </div>
        </div>
    );
}

export default DataStoreOverviewServicesState;
