export type FirewallRuleClassProps = {
    description: string;
    from_port: number;
    protocol: string;
    security_group_id?: string;
    security_group_rule_id?: string;
    source: string;
    to_port: number;
};

export interface FirewallRuleInterface {
    fromPort: number;
    description: string;
    protocol: string;
    securityGroupId?: string;
    securityGroupRuleId?: string;
    source: string;
    toPort: number;
    uniqueKey: string; // this is used for the Table component, a unique key for each value
}

export default class FirewallRule implements FirewallRuleInterface {
    readonly fromPort: number;
    readonly description: string;
    readonly protocol: string;
    readonly securityGroupId?: string;
    readonly securityGroupRuleId?: string;
    readonly source: string;
    readonly toPort: number;
    readonly uniqueKey: string;

    constructor(props: FirewallRuleClassProps) {
        this.fromPort = props.from_port;
        this.description = props.description;
        this.protocol = props.protocol;
        this.securityGroupId = props.security_group_id;
        this.securityGroupRuleId = props.security_group_rule_id;
        this.source = props.source;
        this.toPort = props.to_port;
        this.uniqueKey = `${props.from_port}-${props.to_port}-${props.source}`;
    }
}
