import { DeleteOutlined, LockOutlined } from '@ant-design/icons';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import { Typography } from 'antd';
import { Col } from 'antd';
import { Modal } from 'antd';
import { notification } from 'antd';
import { Row } from 'antd';
import { Input } from 'antd';
import { Button } from 'antd';
import { Form } from 'antd';
import { message } from 'antd';
import { Alert } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { ReactElement, useEffect, useState } from 'react';
import CcxComponentProps from '../../../core/CcxComponent';
import { billingDisabled, StripePK } from '../../../core/CcxEnv';
import useCards from '../../../core/hooks/useCards';
import BillingService from '../../../services/BillingService';
import CountryService from '../../../services/CountryService';
import Address from '../../../types/Address';
import BillingUserData from '../../../types/BillingUserData';
import Card from '../../../types/Card';
import Country from '../../../types/Country';
import Coupon from '../../../types/Coupon';
import AddCard from '../../account/AddCard';
import { PaymentCard } from '../../account/PaymentCard';
import AppTable from '../../AppTable';
import AppFlagIcon from '../../ccx/common/AppFlagIcon';
import CcxIconCheckCircleTwoTone from '../../ccx/icons/CcxIconCheckCircleTwoTone';
import CcxIconCloseCircleTwoTone from '../../ccx/icons/CcxIconCloseCircleTwoTone';
import BillingForm from './BillingForm';
import styles from './WizardFormConfigurationStep6.module.less';

interface Props extends CcxComponentProps {
    setCheckedCoupon: Function;
    checkedCoupon?: Coupon;
    setSelectedCard: Function;
    selectedCard?: Card;
    subscription: any;
    billingForm: FormInstance;
    setCardToken: Function;
    handleCreateUser: Function;
    cardToken: any;
    subscriptionUser: any;
}

export function WizardFormConfigurationStep6({
    testId = 'WizardFormConfigurationStep6',
    setCheckedCoupon,
    checkedCoupon,
    setSelectedCard,
    selectedCard,
    subscription,
    billingForm,
    setCardToken,
    handleCreateUser,
    cardToken,
    subscriptionUser,
}: Props): ReactElement {
    const stripePromise = !billingDisabled ? loadStripe(StripePK) : null;
    const { Text } = Typography;
    const [form] = Form.useForm();

    const [promo, setPromo] = useState<boolean>(false);
    const [addNewCard, setAddNewCard] = useState<boolean>(false);
    const [editAddress, setEditAddress] = useState<boolean>(false);
    const [row, setRow] = useState<any>();

    const { cards, billingUser, refresh } = useCards();

    const [validCards, setValidCards] = useState<Card[]>();

    const [errorFields, setErrorFields] = useState<any>([]);

    const [countries, setCountries] = useState<any>([]);

    const [loading, setLoading] = useState(false);

    const onAddPromoCode = async () => {
        const formCoupon = form.getFieldValue('coupon');
        if (formCoupon) {
            try {
                const { coupon } = await BillingService.checkCoupon(formCoupon);

                if (!coupon || !coupon.isValid()) {
                    throw 'Coupon not valid.';
                } else {
                    setCheckedCoupon(coupon);

                    setRow({
                        title: coupon.name,
                        coupon: coupon.coupon,
                    });

                    form.resetFields();
                }
            } catch (e) {
                message.error(e);
                throw e;
            }
        }
    };

    const tableColumns = [
        {
            title: 'Coupon',
            dataIndex: 'coupon',
            key: 'coupon',
        },
        {
            title: 'Title',
            key: 'title',
            render: (text: string, record: any) => (
                <div className={styles.WizardFormConfigurationStep6Row}>
                    {record.title}
                    <DeleteOutlined onClick={() => setRow(undefined)} />
                </div>
            ),
        },
    ];

    const selectCard = (card: Card) => {
        setSelectedCard(card);
    };

    const fieldsSetup = [
        {
            name: ['firstName'],
            required: true,
            label: 'First name',
            placeholder: 'Enter your first name',
            testId: `${testId}FirstName`,
            type: 'input',
            onChange: async (v: any) => {
                setErrorFields([]);
            },
        },
        {
            name: ['lastName'],
            required: true,
            label: 'Last name',
            placeholder: 'Enter your last name',
            testId: `${testId}LastName`,
            type: 'input',
            onChange: async (v: any) => {
                setErrorFields([]);
            },
        },
        {
            name: ['country'],
            required: true,
            label: 'Country',
            testId: `${testId}Country`,
            placeholder: 'Select your country',
            type: 'select',
            options: [],
            onChange: async (v: any, o: any) => {
                setErrorFields([]);
                await form.setFieldsValue({ country: v });
            },
        },
        {
            name: ['state'],
            required: false,
            label: 'State',
            testId: `${testId}State`,
            placeholder: 'Enter your billing address',
            type: 'input',
        },
        {
            name: ['city'],
            required: false,
            label: 'City',
            testId: `${testId}City`,
            placeholder: 'Enter your City',
            type: 'input',
        },
        {
            name: ['postalCode'],
            required: false,
            label: 'Postal code',
            testId: `${testId}PostalCode`,
            placeholder: 'Enter your postal code',
            type: 'input',
        },
        {
            name: ['line1'],
            required: true,
            label: 'Address 1',
            testId: `${testId}AddressLine1`,
            placeholder: 'Enter your billing address',
            type: 'input',
            onChange: async (v: any) => {
                setErrorFields([]);
            },
        },
        {
            name: ['line2'],
            required: false,
            label: 'Address 2',
            testId: `${testId}AddressLine2`,
            placeholder: 'Enter your billing address',
            type: 'input',
        },
        {
            name: ['companyName'],
            required: false,
            label: 'Company name',
            testId: `${testId}CompanyName`,
            placeholder: 'Time travel agency Ltd.',
            type: 'input',
        },
        {
            name: ['vat'],
            required: false,
            label: 'EU VAT number',
            placeholder: 'RO U25775505',
            testId: `${testId}Vat`,
            type: 'input',
            tips: (
                <div>Only for countries in the EU for reverse charge rule</div>
            ),
        },
    ];

    useEffect(() => {
        if (cards && cards.length) {
            setValidCards(cards.filter((card: Card) => card.isValid()));
        }
    }, [cards]);

    const [fields, setFields] = useState<any>(fieldsSetup);

    const handleCancel = () => {
        billingForm.resetFields();
        setErrorFields([]);
        setEditAddress(false);
    };

    const validateForm = async () => {
        try {
            const a = await billingForm.validateFields();
            return true;
        } catch (e: any) {
            setErrorFields(e.errorFields);
            message.error(
                'One or more fields have errors. Please double check and try again.'
            );
            return false;
        }
    };

    const onSubmitCreateUser = async () => {
        const stepValid = await validateForm();

        if (stepValid) {
            setLoading(true);
            try {
                await handleCreateUser(billingForm);
            } catch (e) {
                console.log('error e=', e);
            }
            setLoading(false);
        }
    };

    const onSubmitButtonClick = async () => {
        const stepValid = await validateForm();

        if (stepValid) {
            setLoading(true);
            try {
                await onSubmit();
                handleCancel();
            } catch (e) {
                console.log('error e=', e);
            }
            setLoading(false);
        }
    };

    const onSubmit = async () => {
        try {
            const data = {
                address: new Address({
                    country: billingForm.getFieldValue('country'),
                    state: billingForm.getFieldValue('state'),
                    city: billingForm.getFieldValue('city'),
                    line_1: billingForm.getFieldValue('line1'),
                    line_2: billingForm.getFieldValue('line2'),
                    postal_code: billingForm.getFieldValue('postalCode'),
                }),
                userData: new BillingUserData({
                    company_name: billingForm.getFieldValue('companyName'),
                    eu_vat_id: billingForm.getFieldValue('vat'),
                    first_name: billingForm.getFieldValue('firstName'),
                    last_name: billingForm.getFieldValue('lastName'),
                }),
            };

            let uuid = subscription?.billingUser?.uuid;

            await BillingService.editUserSubscription(data, uuid);

            notification.open({
                message: 'Edit Address',
                description: `Address successfully edited!`,
                icon: <CcxIconCheckCircleTwoTone twoToneColor="#52c41a" />,
            });

            setLoading(false);
        } catch (e) {
            notification.open({
                message: 'Edit Address',
                description: `There was an error editing your Address. ${e}`,
                icon: <CcxIconCloseCircleTwoTone twoToneColor="#eb2f96" />,
            });

            console.error(e);
            setLoading(false);

            throw e;
        }
    };

    useEffect(() => {
        const load = async () => {
            const response = await CountryService.listCountries();
            setCountries(response);
        };

        load();
    }, []);

    useEffect(() => {
        if (fields && countries && countries.length > 0) {
            const newStep1 = fields.map((f: any) => {
                if (f.name && f.name[0] === 'country') {
                    f.options = countries.map((c: Country) => {
                        return {
                            value: c.code,
                            label: c.name,
                            content: (
                                <div data-testid={`${testId}${c.code}`}>
                                    <AppFlagIcon
                                        code={c.code.toLowerCase()}
                                        className={
                                            styles.WizardFormConfigurationStep6Flag
                                        }
                                    />
                                    {c.name}
                                </div>
                            ),
                        };
                    });
                }
                return f;
            });
            const newFields = newStep1;
            setFields(newFields);
        }
    }, [countries]);

    return (
        <section data-testid={testId}>
            <p>
                <strong>Billing info</strong>
            </p>
            <Alert
                message={
                    cards?.length === 0
                        ? "You don't have a valid credit/debit card"
                        : 'There is a problem with your default card'
                }
                description={
                    <span>
                        To be able to create a datastore or VPC, first, you have
                        to add a valid <br /> credit or debit card to your
                        account.
                    </span>
                }
                type="info"
                showIcon
            />

            <p className={styles.WizardFormConfigurationStep6Title}>
                <strong>Billing address</strong>
            </p>

            {subscription?.valid ? (
                <div className={styles.WizardFormConfigurationStep6Row}>
                    {billingUser?.addressData?.line1 &&
                        billingUser?.addressData?.country && (
                            <p>{`${billingUser?.addressData?.line1}, ${billingUser?.addressData?.country}`}</p>
                        )}
                    <Button onClick={() => setEditAddress(true)}>
                        Edit address
                    </Button>

                    <Modal
                        title={'Edit billing address'}
                        open={editAddress}
                        data-testid={`${testId}Modal`}
                        width={626}
                        onCancel={handleCancel}
                        footer={
                            <Row justify="space-between">
                                <Col>
                                    <Text type="danger">*</Text>Required
                                </Col>
                                <Col>
                                    <Button
                                        data-testid={`${testId}CancelButton`}
                                        onClick={handleCancel}
                                    >
                                        Cancel
                                    </Button>

                                    <Button
                                        onClick={onSubmitButtonClick}
                                        data-testid={`${testId}SubmitButton`}
                                        type="primary"
                                    >
                                        Save
                                    </Button>
                                </Col>
                            </Row>
                        }
                    >
                        <BillingForm
                            fields={fields}
                            form={billingForm}
                            values={{
                                firstName:
                                    subscription?.billingUser?.userData
                                        ?.firstName,
                                lastName:
                                    subscription?.billingUser?.userData
                                        ?.lastName,
                                country:
                                    subscription?.billingUser?.addressData
                                        ?.country,
                                state: subscription?.billingUser?.addressData
                                    ?.state,
                                city: subscription?.billingUser?.addressData
                                    ?.city,
                                postalCode:
                                    subscription?.billingUser?.addressData
                                        ?.postalCode,
                                line1: subscription?.billingUser?.addressData
                                    ?.line1,
                                line2: subscription?.billingUser?.addressData
                                    ?.line2,
                            }}
                            errorFields={errorFields}
                        />
                    </Modal>
                </div>
            ) : (
                <>
                    <BillingForm
                        fields={fields}
                        form={billingForm}
                        errorFields={errorFields}
                    >
                        <Button
                            onClick={onSubmitCreateUser}
                            className={
                                styles.WizardFormConfigurationStep6CreateUserButton
                            }
                            disabled={subscriptionUser}
                        >
                            {subscriptionUser
                                ? `User Added`
                                : `Create new user`}
                        </Button>
                    </BillingForm>
                </>
            )}

            <br />
            <p className={styles.WizardFormConfigurationStep6Title}>
                <strong>Card information</strong>
            </p>

            <div className={styles.WizardFormConfigurationStep6NewCard}>
                {stripePromise && (
                    <Elements stripe={stripePromise}>
                        {cardToken ? (
                            <Text type="secondary">Card info recorded</Text>
                        ) : (
                            <AddCard
                                onSuccess={refresh}
                                drawer={false}
                                setCardToken={setCardToken}
                                submitDisabled={!subscriptionUser}
                            />
                        )}
                    </Elements>
                )}

                <p>
                    <small>
                        <Text type="secondary">
                            <LockOutlined />
                            We will store your data securely.
                        </Text>
                    </small>
                </p>
            </div>

            <p className={styles.WizardFormConfigurationStep6Title}>
                <strong>Promo codes</strong>
            </p>

            {promo ? (
                <Form
                    layout={'vertical'}
                    form={form}
                    className={styles.WizardFormConfigurationStep6Coupon}
                >
                    <Form.Item label="Code" name="coupon">
                        <Input placeholder="Enter your promo code and then click “Add”" />
                    </Form.Item>
                    <Button onClick={onAddPromoCode} disabled={row}>
                        Add
                    </Button>
                </Form>
            ) : (
                <small onClick={() => setPromo(true)}>
                    <Text
                        className={
                            styles.WizardFormConfigurationStep6CouponText
                        }
                    >
                        I have a promo code
                    </Text>
                </small>
            )}

            {row && (
                <>
                    <p>Added promo codes</p>
                    <AppTable
                        columns={tableColumns}
                        testId={`${testId}Table`}
                        data={[row]}
                        rowKey="subID"
                        expandable={false}
                        pagination={{
                            hideOnSinglePage: true,
                            pageSize: 1,
                        }}
                    />
                </>
            )}
        </section>
    );
}
