import CloudProviderRegion, {
    CloudProviderRegionClassProps,
} from './CloudProviderRegion';
import FriendlyDateTime from './FriendlyDateTime';

export const DB_VENDOR_MARIADB = 'mariadb';
export const DB_VENDOR_POSTGRESQL = 'pgsql';
export const DB_VENDOR_POSTGRESQL_V2 = 'postgres'; // introduced in provisioning v2
export const DB_VENDOR_PERCONA = 'percona';
export const DB_VENDOR_REDIS = 'redis';
export const DB_VENDOR_UNKNOWN = '';

export const LB_VENDOR_HAPROXY = 'haproxy';
export const LB_VENDOR_PROXYSQL = 'proxysql';

export const DB_VENDOR_MARIADB_PRETTY = 'MariaDB Cluster';
export const DB_VENDOR_POSTGRESQL_PRETTY = 'PostgreSQL';
export const DB_VENDOR_PERCONA_PRETTY = 'MySQL';
export const DB_VENDOR_REDIS_PRETTY = 'Redis';

export const LB_VENDOR_HAPROXY_PRETTY = 'HAProxy';
export const LB_VENDOR_PROXYSQL_PRETTY = 'ProxySQL';

export type ServiceClassProps = {
    admin_port: number;
    cloud_provider: string;
    disk_type: string;
    cluster_uuid: string;
    created_at: string;
    disks: string;
    fqdn?: string;
    host_az: string;
    az: string;
    host_status: string;
    host_type?: string;
    host_uuid: string;
    id: string;
    hostname: string;
    instance_id: string;
    instance_type: string;
    last_seen_at: string;
    last_seen: string;
    listening_port: number;
    listening_port_ro: number;
    node_type: string;
    port: number;
    private_ip: string;
    public_ip: string;
    region: CloudProviderRegionClassProps;
    role: string;
    security_group: string;
    service_started_at: string;
    ssl_enabled: boolean;
    subnet: string;
    unique_id: number;
    updated_at: string;
    user_login: string;
    vpc: string;
    version: string;
    readonly: boolean;
};

export interface ServiceInterface {
    getAvailabilityZone(): string;
    getNodeType(): string;
    getRole(): string;
    getStatus(): string;
    getStatusText(): string;
    isStatusOk(): boolean;
    isStatusError(): boolean;
    isStatusWarning(): boolean;
    isStarting(): boolean;
    getPort(): number;
    getNodeVersion(): string;
}

export default class Service implements ServiceInterface {
    static CMON_STATUS_ONLINE = 'CmonHostOnline';
    static CMON_STATUS_FAILED = 'CmonHostFailed';
    static CMON_STATUS_OFFLINE = 'CmonHostOffLine';
    static CMON_STATUS_SHUTDOWN = 'CmonHostShutDown';
    static CMON_STATUS_RECOVERY = 'CmonHostRecovery';
    static CMON_STATUS_UNKNOWN = 'CmonHostUnknown';

    protected adminPort: number;
    protected cloudProvider: string;
    protected diskType: string;
    protected clusterUuid: string;
    protected createdAt: string;
    protected disks: string;
    protected fqdn?: string;
    protected hostAvailabilityZone: string;
    protected hostStatus: string;
    protected hostType?: string;
    protected hostUuid: string;
    protected hostname: string;
    protected instanceId: string;
    protected instanceType: string;
    protected lastSeenAt: string;
    protected listeningPort: number;
    protected listeningPortRo: number;
    protected nodeType: string;
    protected port: number;
    protected privateIp: string;
    protected publicIp: string;
    protected region?: CloudProviderRegion;
    protected role: string;
    protected securityGroup: string;
    protected serviceStartedAt: string;
    protected status: string;
    protected sslEnabled: boolean;
    protected subnet: string;
    protected uniqueId: number;
    protected updatedAt: string;
    protected userLogin: string;
    protected vpc: string;
    protected version: string;
    protected readonly: boolean;

    constructor(props: ServiceClassProps) {
        this.adminPort = props.admin_port;
        this.cloudProvider = props.cloud_provider;
        this.diskType = props.disk_type;
        this.clusterUuid = props.cluster_uuid;
        this.createdAt = props.created_at;
        this.disks = props.disks;
        this.fqdn = props.fqdn;
        this.hostAvailabilityZone = props.host_az || props.az;
        this.hostStatus = props.host_status;
        this.hostType = props.host_type;
        this.hostUuid = props.host_uuid || props.id;
        this.hostname = props.hostname;
        this.instanceId = props.instance_id;
        this.instanceType = props.instance_type;
        this.lastSeenAt = props.last_seen_at || props.last_seen;
        this.listeningPort = props.listening_port;
        this.listeningPortRo = props.listening_port_ro;
        this.nodeType = props.node_type;
        this.port = props.port;
        this.privateIp = props.private_ip;
        this.publicIp = props.public_ip;
        this.region = props.region
            ? new CloudProviderRegion(props.region)
            : undefined;
        this.role = props.role;
        this.securityGroup = props.security_group;
        this.serviceStartedAt = props.service_started_at;
        this.status =
            this.isStatusError() || this.isStatusWarning() ? 'error' : 'ok';
        this.sslEnabled = props.ssl_enabled;
        this.subnet = props.subnet;
        this.uniqueId = props.unique_id;
        this.updatedAt = props.updated_at;
        this.userLogin = props.user_login;
        this.vpc = props.vpc;
        this.version = props.version.split('.')[0] || '';
        this.readonly = props.readonly;
    }

    getNodeType(): string {
        return this.nodeType;
    }

    getRole(): string {
        return this.role;
    }

    getRoleName(): string {
        if (this.role === 'master' || this.role === 'slave') {
            return this.role
                .replaceAll('master', 'primary')
                .replaceAll('slave', 'replica');
        } else {
            return this.role;
        }
    }

    isRoleReplica(): boolean {
        return ['slave', 'SECONDARY', 'RESOLVING', 'replica'].includes(
            this.role
        );
    }

    isRolePrimary(): boolean {
        return ['master', 'primary'].includes(this.role);
    }

    getPermisiion(): string {
        if (this.readonly === undefined) {
            return '';
        } else if (this.readonly) {
            return 'Read-only';
        } else {
            return 'Read-write';
        }
    }

    getStatus(): string {
        return this.hostStatus;
    }

    getServiceUuid(): string {
        return this.hostUuid;
    }

    getHostPort(isLB?: any) {
        return isLB
            ? `${this.publicIp || this.privateIp}:${this.listeningPort}`
            : `${this.publicIp || this.privateIp}:${this.port}`;
    }

    getFQDN(isLB?: any): string {
        return this.fqdn || this.getHostPort(isLB);
    }

    getAvailabilityZone(): string {
        return this.hostAvailabilityZone;
    }

    isStatusOk(): boolean {
        return this.hostStatus === Service.CMON_STATUS_ONLINE;
    }

    isStatusError(): boolean {
        return (
            this.hostStatus === Service.CMON_STATUS_FAILED ||
            this.hostStatus === Service.CMON_STATUS_SHUTDOWN ||
            this.hostStatus === Service.CMON_STATUS_OFFLINE
        );
    }

    isStatusWarning(): boolean {
        return (
            this.hostStatus === Service.CMON_STATUS_RECOVERY ||
            this.hostStatus === Service.CMON_STATUS_UNKNOWN
        );
    }

    isStarting(): boolean {
        if (!this.hostStatus) {
            return false;
        }
        return (
            this.hostStatus.toLowerCase() === Service.CMON_STATUS_RECOVERY ||
            this.hostStatus.toLowerCase() === Service.CMON_STATUS_UNKNOWN
        );
    }

    getStatusText(): string {
        switch (this.hostStatus) {
            case Service.CMON_STATUS_ONLINE:
                return 'Running';
            case Service.CMON_STATUS_SHUTDOWN:
            case Service.CMON_STATUS_OFFLINE:
            case Service.CMON_STATUS_FAILED:
                return 'Stopped';
            case Service.CMON_STATUS_RECOVERY:
                return 'Recovering';
            case Service.CMON_STATUS_UNKNOWN:
            default:
                return 'Unknown';
        }
    }

    getPort(): number {
        return this.port;
    }

    getElegantNodeType(): string {
        if (this.getNodeType() === LB_VENDOR_HAPROXY) {
            return LB_VENDOR_HAPROXY_PRETTY;
        } else if (this.getNodeType() === LB_VENDOR_PROXYSQL) {
            return LB_VENDOR_PROXYSQL_PRETTY;
        } else if (this.getNodeType() === DB_VENDOR_MARIADB) {
            return DB_VENDOR_MARIADB_PRETTY;
        } else if (this.getNodeType() === DB_VENDOR_POSTGRESQL) {
            return DB_VENDOR_POSTGRESQL_PRETTY;
        } else if (this.getNodeType() === DB_VENDOR_PERCONA) {
            return DB_VENDOR_PERCONA_PRETTY;
        } else if (this.getNodeType() === DB_VENDOR_REDIS) {
            return DB_VENDOR_REDIS_PRETTY;
        } else {
            return '';
        }
    }

    getNodeVersion(): string {
        return this.version;
    }

    getFriendlyCreatedAt(): string {
        const dateTime = new FriendlyDateTime({
            date: this.createdAt,
        });

        return dateTime.getFriendlyDate();
    }

    getFullCreatedAt(): string {
        const dateTime = new FriendlyDateTime({
            date: this.createdAt,
        });

        return dateTime.getFullDate();
    }

    getCloudProvider(): string {
        return this.cloudProvider;
    }

    getRegion(): CloudProviderRegion | undefined {
        return this.region;
    }

    getRegionCode(): string {
        return this.region ? this.region.code : '';
    }

    getInstanceType(): string {
        return this.instanceType;
    }

    getDiskType(): string {
        return this.diskType;
    }

    setUserLogin(userLogin: string) {
        this.userLogin = userLogin;
    }

    setClusterUuid(clusterUuid: string) {
        this.clusterUuid = clusterUuid;
    }

    getClusterUuid() {
        return this.clusterUuid;
    }

    getHostname() {
        return this.hostname;
    }

    public getHostnameOrIP(): string {
        if (this.fqdn) {
            return this.fqdn?.split('.')[0];
        } else {
            return this.hostname;
        }
    }
}
