import ApiService from './ApiService';
import DbUsers from '../types/DbUsers';
import DbDatabases from '../types/DbDatabases';
import { API_USERDB } from '../core/CcxEnv';

// constants
const API_URL = API_USERDB;

export type AddUserProps = {
    uuid: string;
    dbUsername: string;
    dbPassword: string;
    allowedHost?: string;
    dbName: string;
    dbPrivileges: string;
    dbAuthPlugin: string;
    isAdmin?: boolean;
};
export type AddUserForm = {
    uuid: string;
    dbUsername: string;
    dbPassword: string;
    allowedHost?: string;
    dbName: string;
    dbUserDbCommandCategories: string;
    dbUserDbCommands : string;
    dbUserDbChannels: string;
    dbUserDbKeys: string;
    dbAuthPlugin: string;
    isAdmin?: boolean;
};

export type DeleteDbUserProps = {
    uuid: string;
    dbUsername: string;
    dbHost: string;
};

export type AddDatabaseProps = {
    uuid: string;
    dbName: string;
    dbOwner: string;
};

export type DeleteDatabaseProps = {
    uuid: string;
    dbName: string;
};

export default class DbUserService extends ApiService {
    /**
     * Get list of available database privileges.
     * @param {string} uuid
     */
    static async getPrivileges(uuid: string): Promise<string[]> {
        return await this.request('GET', `user/available_privs/${uuid}`);
    }

    /**
     * Get list of DB Users.
     * @param {string} uuid
     */
    static async getAllUsers(uuid: string): Promise<DbUsers> {
        const response = await this.request('GET', `users/${uuid}`);
        return new DbUsers(response);
    }

    /**
     * Add a DB User
     * @param {string} uuid
     * @param dbUsername
     * @param dbPassword
     * @param allowedHost
     * @param dbName
     * @param dbPrivileges
     * @param dbAuthPlugin
     * @param isAdmin
     */
    static async addUser({
        uuid,
        dbUsername,
        dbPassword,
        allowedHost,
        dbName,
        dbPrivileges,
        dbAuthPlugin,
        isAdmin,
    }: AddUserProps): Promise<any> {
        const postData = {
            data: {
                database_username: dbUsername,
                database_password: dbPassword,
                database_name: dbName,
                database_privileges: dbPrivileges,
                create_admin_user: isAdmin,
                database_host: allowedHost,
                auth_plugin: dbAuthPlugin,
            },
        };
        return await this.request('POST', `user/${uuid}`, postData);
    }

    /**
     * Get list of Databases.
     * @param {string} uuid
     */
    static async getAllDatabases(uuid: string): Promise<DbDatabases> {
        const response = await this.request('GET', `databases/${uuid}`);
        return new DbDatabases(response);
    }

    /**
     * Add a Database
     * @param {string} uuid
     */
    static async addDatabase({
        uuid,
        dbName,
        dbOwner,
    }: AddDatabaseProps): Promise<any> {
        const postData = {
            data: {
                database_name: dbName,
                database_owner: dbOwner,
            },
        };
        return await this.request('POST', `database/${uuid}`, postData);
    }

    /**
     * Delete a Database
     * @param {string} uuid
     */
    static async deleteDatabase({
        uuid,
        dbName,
    }: DeleteDatabaseProps): Promise<any> {
        const data = {
            data: {
                database_name: dbName,
            },
        };
        return await this.request('DELETE', `database/${uuid}`, data);
    }

    /**
     * Delete a Db User
     * @param {DeleteDbUserProps} props
     */
    static async deleteUser({
        uuid,
        dbUsername,
        dbHost,
    }: DeleteDbUserProps): Promise<any> {
        const requestData = {
            data: {
                database_username: dbUsername,
                database_host: dbHost,
            },
        };
        return await this.request('DELETE', `user/${uuid}`, requestData);
    }

    protected static getApiUrl() {
        return API_URL;
    }
}
