import styles from './DatastoreScalingStep1.module.less';
import 'rc-slider/assets/index.css';
import { Alert, Col, Form, InputNumber, Row, message } from 'antd';
import DatastoreScalingStepHeader from './DatastoreScalingStepHeader';
import DeploymentsItem from '../../../types/DeploymentsItem';
import InstanceVolumeSize from '../../../types/InstanceVolumeSize';
import DatastoreScalingCard from './DatastoreScalingCard';

interface DataStoreScalingStep1Props {
    dataStore: DeploymentsItem | undefined;
    storage: number | undefined;
    handleStorage: Function;
    volume_size: InstanceVolumeSize;
    testId?: string;
}

export default function DatastoreScalingStep1({
    dataStore,
    storage,
    handleStorage,
    volume_size,
    testId = 'DatastoreScalingStep1',
}: DataStoreScalingStep1Props) {
    return (
        <>
            <DatastoreScalingStepHeader
                title="Storage capacity"
                subtitle="Enter desired extra storage capacity"
            >
                <Row
                    className={styles.DatastoreScalingStep1SliderRow}
                    gutter={[16, 16]}
                    data-testid={testId}
                >
                    {storage! < dataStore?.volumeSize! + 10 ? (
                        <Col span={24}>
                            <Alert
                                className={styles.DatastoreScalingStep2Alert}
                                message={`New storage must be 10 GB greater than ${dataStore?.volumeSize} GB`}
                                showIcon
                                type="error"
                            />
                        </Col>
                    ) : null}
                    <Col span={22}>
                        {volume_size && (
                            <>
                                <Form.Item
                                    name="volumeSize"
                                    label=""
                                    rules={[
                                        {
                                            required: true,
                                            message: `Value must be 10 GB greater than ${dataStore?.volumeSize} GB`,
                                        },
                                    ]}
                                    extra={
                                        <small
                                            data-testid={`${testId}InputMessage`}
                                        >
                                            The desired value must be in the
                                            range of {volume_size?.min}
                                            GB and {volume_size?.max}GB
                                        </small>
                                    }
                                >
                                    <InputNumber
                                        min={dataStore?.volumeSize!}
                                        max={volume_size?.max}
                                        data-testid={`${testId}Input`}
                                        className={
                                            styles.DatastoreScalingStepFormInputNumber
                                        }
                                        size="large"
                                        formatter={(value?: number) =>
                                            `${value}`
                                        }
                                        onChange={(value) => {
                                            handleStorage?.(value || undefined);
                                        }}
                                        value={storage}
                                        defaultValue={
                                            storage
                                                ? storage
                                                : dataStore?.volumeSize
                                        }
                                        parser={
                                            ((value: string) =>
                                                value
                                                    .replace(' ', '')
                                                    .replace(
                                                        volume_size.unit,
                                                        ''
                                                    )) as any
                                        }
                                    />
                                </Form.Item>
                            </>
                        )}
                    </Col>
                </Row>
                <DatastoreScalingCard
                    stepScalingTitle="Extending to: "
                    stepScalingCount={`${
                        storage! > dataStore?.volumeSize!
                            ? storage
                            : dataStore?.volumeSize
                    } GB`}
                />
            </DatastoreScalingStepHeader>
        </>
    );
}
