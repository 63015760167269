import React from 'react';
import { Table } from 'antd';
import CcxComponentProps from '../core/CcxComponent';
import { DownOutlined, RightOutlined, UpOutlined } from '@ant-design/icons';
import styles from './AppTable.module.less';
import { SizeType } from 'antd/lib/config-provider/SizeContext';

interface AppTableProps extends CcxComponentProps {
    columns: any[];
    data: any[] | undefined;
    rowKey: string | Function;
    footer?: any;
    bordered?: boolean;
    expandedRowRender?: Function;
    rowExpandable?: Function;
    className?: string;
    size?: SizeType;
    pagination?: any;
    expandable?: any;
    onChange?: Function;
    customIcon?: boolean;
    onRow?: Function;
    onExpand?: Function;
    expandableIconVariant?: 'default' | 'topQueries';
}

interface ExpandProps {
    expanded: any;
    onExpand: any;
    record: any;
}

function AppTable({
    columns,
    data,
    rowKey,
    footer,
    bordered = false,
    expandedRowRender,
    rowExpandable,
    className,
    size,
    pagination,
    expandable,
    onChange,
    onRow,
    onExpand,
    customIcon,
    expandableIconVariant,
    testId = 'AppTable',
}: AppTableProps) {
    const expandIcon = ({ expanded, onExpand, record }: ExpandProps) => {
        if (rowExpandable && !rowExpandable(record)) {
            return null;
        }

        if (expandableIconVariant === 'topQueries') {
            return expanded ? (
                <UpOutlined onClick={(e: any) => onExpand(record, e)} />
            ) : (
                <DownOutlined onClick={(e: any) => onExpand(record, e)} />
            );
        }

        return expanded ? (
            <DownOutlined onClick={(e: any) => onExpand(record, e)} />
        ) : (
            <RightOutlined onClick={(e: any) => onExpand(record, e)} />
        );
    };

    return (
        <Table
            columns={columns}
            data-testid={testId}
            dataSource={data}
            bordered={bordered}
            rowKey={rowKey as any}
            footer={footer}
            onRow={onRow as any}
            onExpand={onExpand as any}
            expandable={
                expandable ?? {
                    expandedRowRender: expandedRowRender
                        ? (record: any) => expandedRowRender(record)
                        : (record: any) => record?.expandableRowContent,
                    rowExpandable: rowExpandable
                        ? (record: any) => rowExpandable(record)
                        : (record: any) => record?.expandableRowContent,
                    expandIcon: customIcon ? expandIcon : null,
                }
            }
            className={`${styles.AppTableHeader} ${className}`}
            size={size}
            pagination={pagination !== undefined ? pagination : true}
            onChange={onChange as any}
            {...{
                top: 'topRight',
            }}
        />
    );
}

export default AppTable;
