import React, { ReactElement, useState, useEffect } from 'react';
import styles from './ConnectionAssistantForm.module.less';
import { Form, Divider } from 'antd';
import ConnectionAssistantFormStep1 from './ConnectionAssistantFormStep1';
import ConnectionAssistantFormStep2 from './ConnectionAssistantFormStep2';
import DbDatabase from '../../types/DbDatabase';
import CcxComponentProps from '../../core/CcxComponent';
import { getUniqueSortedNodesByRole } from '../../core/helpers';
import useDatastoreServiceDsn from '../projects/dataStores/services/useDatastoreServiceDsn';
import DeploymentsItem from '../../types/DeploymentsItem';
import DbNode from '../../types/DbNode';
import LbNode from '../../types/LbNode';

interface Props extends CcxComponentProps {
    databases?: DbDatabase[];
    users?: string[];
    hosts?: any;
    service?: string;
    dataStore?: DeploymentsItem | undefined;
}

function ConnectionAssistantForm({
    databases,
    users,
    hosts,
    service,
    testId = 'ConnectionAssistantForm',
    dataStore,
}: Props): ReactElement {
    const type = 'db';
    const { password } = useDatastoreServiceDsn({
        dataStore,
        type,
    });

    const [form] = Form.useForm();
    const [errorFields] = useState([]);
    const [showConnectionString, setShowConnectionString] =
        useState<boolean>(false);

    const getHostOptions = () => {
        // if access to services is disabled primary and replica url will be empty, in this case we fall back to our old logic of showing all hosts
        if (!dataStore?.primary_url && !dataStore?.replica_url) {
            return hosts?.dbHosts
                ?.map((d: DbNode) => {
                    return {
                        label: `${d.getFqdnPort(
                            false
                        )} [${d.getFormattedNodeType()}]`,
                        value: `${d.getFqdnPort(false)}`,
                    };
                })
                .concat(
                    hosts.lbHosts?.map((d: LbNode) => {
                        return {
                            label: `${d.getFqdnPort(
                                false
                            )} [${d.getFormattedNodeType()}]`,
                            value: `${d.getFqdnPort(false)}`,
                        };
                    })
                );
        }

        // if access to services is enabled, only show primary and replica hosts
        return hosts
            ? getUniqueSortedNodesByRole(hosts.dbHosts, dataStore)
                  ?.map((node: DbNode) => {
                      return {
                          label: `${node.getServiceConnectionName()}`,
                          value: `${dataStore.getPrimaryReplicaUrl(node)}:${
                              node.port
                          }`,
                      };
                  })
                  .concat(
                      hosts.lbHosts?.map((node: LbNode) => {
                          return {
                              label: `${node.getServiceConnectionName()}`,
                              value: `${dataStore.getPrimaryReplicaUrl(node)}:${
                                  node.port
                              }`,
                          };
                      })
                  )
            : [];
    };

    const fieldsSetup = {
        step1: [
            {
                name: ['dbuser'],
                testId: 'ConnectionAssistantFormDBUser',
                required: true,
                label: 'Db user',
                placeholder: 'Select a Db user',
                type: 'select',
                options: users
                    ? users?.map((d) => {
                          return {
                              label: d,
                              value: d,
                          };
                      })
                    : [],
            },
            {
                name: ['database'],
                testId: 'ConnectionAssistantFormDatabase',
                required: true,
                label: 'Database name',
                placeholder: 'Select a database',
                type: 'select',
                options: databases
                    ? databases?.map((d) => {
                          return {
                              label: d.databaseName,
                              value: d.databaseName,
                          };
                      })
                    : [],
            },
            {
                name: ['host'],
                testId: 'ConnectionAssistantFormHost',
                required: true,
                label: 'Endpoint',
                placeholder: 'Select an endpoint',
                type: 'select',
                options: getHostOptions(),
            },
        ],
    };

    const [fields, setFields] = useState<any>(fieldsSetup);
    const [formData, setFormData] = useState<any>();

    useEffect(() => {
        if (dataStore?.isRedis() && fields) {
            setFields({ step1: [fieldsSetup.step1[0], fieldsSetup.step1[fieldsSetup.step1.length - 1 ]]});
        } else {
            setFields(fieldsSetup);
        }
    }, [databases, users, hosts, dataStore]);



    // return boolean if form is valid
    const validateFormStep = async () => {
        let {
            dbuser: account,
            database,
            host: hostPort,
        } = form.getFieldsValue();
        if  ( dataStore?.isRedis() ) {
            database = '0';
        }
        setFormData({ account, database, hostPort, password });


        account && database && hostPort
            ? setShowConnectionString(true)
            : setShowConnectionString(false);
    };

    return (
        <div className={styles.ConnectionAssistantForm} data-testid={testId}>
            <p>Configure your connection strings for your database</p>

            <strong>Connection settings</strong>

            <Form
                onValuesChange={validateFormStep}
                layout="vertical"
                form={form}
                scrollToFirstError={true}
            >
                <ConnectionAssistantFormStep1
                    fields={fields.step1}
                    errorFields={errorFields}
                />
            </Form>

            <Divider />
            <p>
                <strong>Connection string examples</strong>
            </p>

            {showConnectionString && (
                <ConnectionAssistantFormStep2
                    formData={formData}
                    service={service}
                />
            )}
        </div>
    );
}

export default ConnectionAssistantForm;
