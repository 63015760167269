import React, { ReactElement, useState } from 'react';
import { Button, Modal } from 'antd';
import styles from './ConnectionAssistant.module.less';
import { ApiOutlined } from '@ant-design/icons';
import ConnectionAssistantForm from './ConnectionAssistantForm';
import DbUser from '../../types/DbUser';
import DbDatabase from '../../types/DbDatabase';
import CcxComponentProps from '../../core/CcxComponent';
import { Row } from 'antd';
import { Col } from 'antd';
import DeploymentsItem from '../../types/DeploymentsItem';
interface Props extends CcxComponentProps {
    user?: DbUser;
    databases?: DbDatabase[];
    users?: string[];
    hosts?: any;
    service?: string;
    datastore?: DeploymentsItem | undefined;
}

function ConnectionAssistant({
    user,
    databases,
    users,
    hosts,
    service,
    datastore,
    testId = 'ConnectionAssistant',
}: Props): ReactElement {
    const [connectionStringsVisible, setConnectionStringsVisible] =
        useState(false);

    const hide = () => {
        setConnectionStringsVisible(false);
    };

    const show = () => {
        setConnectionStringsVisible(true);
    };

    return (
        <div className={styles.ConnectionAssistant} data-testid={testId}>
            <Button data-testid={`${testId}Button`} onClick={show}>
                <ApiOutlined data-testid={`${testId}ButtonIcon`} />
                Connection assistant
            </Button>
            <Modal
                title={'Connection assistant'}
                open={connectionStringsVisible}
                data-testid={`${testId}Modal`}
                width={850}
                onCancel={hide}
                footer={
                    <Row justify="end">
                        <Col
                            span={3}
                            className={styles.ButtonDrawerFormButtonRight}
                        >
                            <Button
                                data-testid={`${testId}SubmitButton`}
                                onClick={hide}
                            >
                                Close
                            </Button>
                        </Col>
                    </Row>
                }
            >
                <ConnectionAssistantForm
                    testId={`${testId}ModalForm`}
                    databases={databases}
                    users={users}
                    hosts={hosts}
                    service={service}
                    dataStore={datastore}
                />
            </Modal>
        </div>
    );
}

export default ConnectionAssistant;
