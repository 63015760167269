import React, { ReactElement, useState, useEffect } from 'react';
import { notification, Modal } from 'antd';
import styles from './DbDatabase.module.less';
import AppEmpty from '../AppEmpty';
import { useHistory, useParams } from 'react-router-dom';
import useDatabases from '../../core/hooks/useDatabases';
import AppTable from '../AppTable';
import { StopOutlined } from '@ant-design/icons';
import AppBytes from '../AppBytes';
import AddDbDatabase from './AddDbDatabase';
import AppConfirmDialog from '../AppConfirmDialog';
import DbUserService from '../../services/DbUserService';
import AppLoading from '../AppLoading';
import Deployments from '../../types/Deployments';
import Deployment from '../../types/Deployment';
import CcxSectionHeader from '../ccx/common/CcxSectionHeader';
import CcxIconCloseCircleTwoTone from '../ccx/icons/CcxIconCloseCircleTwoTone';
import CcxIconInfoCircleTwoTone from '../ccx/icons/CcxIconInfoCircleTwoTone';
import CcxIconDeleteOutlined from '../ccx/icons/CcxIconDeleteOutlined';
import DbDatabase from '../../types/DbDatabase';
import { Tooltip } from 'antd';
import LazyLoader from '../LazyLoader';
import { DatabaseType } from '../../types/Db';

interface UrlProps {
    dataStoreUuid: string;
}

type DbDatabaseProps = {
    deployments?: Deployments;
    deployment?: Deployment;
};

function DbDatabases({ deployments }: DbDatabaseProps): ReactElement {
    let { dataStoreUuid } = useParams<UrlProps>();
    const currentDeployment = deployments?.getByUuid(dataStoreUuid);
    const {
        databases,
        refresh: dbRefresh,
        loading: dbLoading,
    } = useDatabases(dataStoreUuid);
    const [deleting, setDeleting] = useState<boolean>(false);

    let history = useHistory();

    useEffect(() => {
        if (currentDeployment?.isRedis()) {
            history.goBack();
        }
    }, [currentDeployment]);

    const databasesColumns = [
        {
            title: 'Database Name',
            dataIndex: 'databaseName',
            key: 'databaseName',
        },
        {
            title: 'Size',
            key: 'databaseSize',
            render: (text: string, record: any) => {
                return record.databaseSize ? (
                    <AppBytes>{record.databaseSize}</AppBytes>
                ) : (
                    '0'
                );
            },
        },
        {
            title: 'Tables',
            key: 'numberOfTables',
            render: (text: string, record: any) => {
                return record.numberOfTables ? record.numberOfTables : '0';
            },
        },
        {
            title: 'Actions',
            key: 'actions',
            width: 90,
            render: (text: string, record: DbDatabase) => {
                const onConfirmDelete = () => {
                    return new Promise(async (resolve, reject) => {
                        setDeleting(true);

                        notification.open({
                            message: 'Remove database',
                            description: 'The database will be removed soon.',
                            icon: <CcxIconInfoCircleTwoTone />,
                        });

                        Modal.destroyAll();

                        try {
                            const data = {
                                uuid: dataStoreUuid,
                                dbName: record.databaseName,
                            };
                            await DbUserService.deleteDatabase(data);

                            dbRefresh && (await dbRefresh());

                            setDeleting(false);
                            resolve(null);
                        } catch (e) {
                            notification.open({
                                message: 'Remove database',
                                description: `There was an error removing the database. ${e}`,
                                icon: (
                                    <CcxIconCloseCircleTwoTone twoToneColor="#eb2f96" />
                                ),
                            });

                            console.error(e);

                            setDeleting(false);
                            reject();
                        }
                    }).catch(() => console.log('Oops errors!'));
                };

                return (
                    <div className={styles.DbDatabaseOptionsColumn}>
                        {record.isDeleting() ? (
                            <Tooltip
                                placement="top"
                                title="Database is deleting"
                            >
                                <LazyLoader />
                            </Tooltip>
                        ) : (
                            <>
                                {record.isDefaultDatabase() ? (
                                    <Tooltip
                                        placement="top"
                                        title="Default database cannot be deleted"
                                    >
                                        <StopOutlined
                                            className={styles.DbUsersStop}
                                        />
                                    </Tooltip>
                                ) : (
                                    <AppConfirmDialog
                                        critical={true}
                                        title={'Dropping database'}
                                        onOk={onConfirmDelete}
                                        content={`Are you sure you want to drop database "${record.databaseName}"? WARNING! All data in the database will be lost.`}
                                        actionIcon={<CcxIconDeleteOutlined />}
                                    />
                                )}
                            </>
                        )}
                    </div>
                );
            },
        },
    ];

    return (
        <section>
            <CcxSectionHeader>
                <>
                    {currentDeployment?.isMSSQL() ? undefined : (
                        <AddDbDatabase
                            onSuccess={dbRefresh}
                            uuid={dataStoreUuid}
                            currentDeployment={currentDeployment}
                            type={
                                (currentDeployment?.getServiceName() as DatabaseType) ||
                                DatabaseType.UNKNOWN
                            }
                        />
                    )}
                </>
            </CcxSectionHeader>
            <h4 className={styles.DbDatabaseLabel}>Databases</h4>
            {dbLoading ? (
                <AppLoading />
            ) : databases?.databases.length ? (
                <AppTable
                    columns={databasesColumns}
                    data={databases?.databases}
                    rowKey="uniqueKey"
                    pagination={{
                        hideOnSinglePage: true,
                    }}
                />
            ) : (
                <AppEmpty message="There are no databases created yet" />
            )}
        </section>
    );
}

export default DbDatabases;
