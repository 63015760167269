import { ReactElement, useEffect, useState } from 'react';
import { Button, Dropdown, Menu, Modal, notification, Tooltip } from 'antd';
import CcxComponentProps from '../../../core/CcxComponent';
import AppEmpty from '../../AppEmpty';
import AppGridTable from '../../ccx/common/AppGridTable';
import styles from './DataStores.module.less';
import { Link, useHistory, useParams } from 'react-router-dom';
import DeploymentsItem from '../../../types/DeploymentsItem';
import ProvisionService from '../../../services/ProvisionService';
import CcxIconInfoCircleTwoTone from '../../ccx/icons/CcxIconInfoCircleTwoTone';
import CcxIconCloseCircleTwoTone from '../../ccx/icons/CcxIconCloseCircleTwoTone';
import CcxContextualMenuIcon from '../../ccx/icons/CcxContextualMenuIcon';
import DataStoreInformation from './DataStoreInformation';
import DataStoreStatus from './DataStoreStatus';
import CloudInformation from './CloudInformation';
import DatabaseInformation from './DatabaseInformation';
import DataStoreChart from './DataStoreChart';
import NoChartAvailable from '../../ccx/common/NoChartAvailable';
import useContentDeployWizard from '../../../core/hooks/useContentDeployWizard';
import CcxIconInfoCircleOutlined from '../../ccx/icons/CcxIconInfoCircleOutlined';
import AppDeleteModal from '../../AppDeleteModal';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { updateProfile } from '../../../slices/user.slice';
import { getAllDataStores } from '../../../slices/datastores.slice';
import { DeleteOutlined, FileTextOutlined } from '@ant-design/icons';

interface UrlProps {
    projectUuid: string;
}

interface Props extends CcxComponentProps {}

function DataStores({ testId = 'DataStores' }: Props): ReactElement {
    let history = useHistory();
    let { projectUuid } = useParams<UrlProps>();

    const { datastores } = useAppSelector((state) => state.datastores);

    const [rows, setRows] = useState<any>([]);
    const [updating, setUpdating] = useState<boolean>(false);
    const { deploymentOptions } = useContentDeployWizard();
    const confirmPhrase = 'delete';

    const { user } = useAppSelector((state) => state.user);
    const dispatch = useAppDispatch();

    const onAlertClose = async () => {
        try {
            dispatch(updateProfile());
        } catch (e) {
            notification.open({
                message: 'New User',
                description: `There was an error . ${e}`,
                icon: <CcxIconCloseCircleTwoTone twoToneColor="#eb2f96" />,
            });
        }
    };

    const QPSTitle = (
        <Tooltip
            placement="bottom"
            title={'Showing last 15 minutes of activity'}
        >
            Queries per second <CcxIconInfoCircleOutlined />
        </Tooltip>
    );

    const columns = [
        { title: 'Datastore', span: 5 },
        { title: 'Status', span: 4 },
        { title: 'Database', span: 4 },
        { title: 'Cloud provider', span: 4 },
        { title: QPSTitle, span: 5 },
    ];

    useEffect(() => {
        dispatch(getAllDataStores());
    }, []);

    useEffect(() => {
        const AUTO_REFRESH_INTERVAL = 20000;
        const interval = setInterval(async () => {
            setUpdating(true);
            dispatch(getAllDataStores());
            setUpdating(false);
        }, AUTO_REFRESH_INTERVAL);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        if (datastores) {
            if (user?.isNewUser() && datastores.deployments.length > 0) {
                onAlertClose();
            }

            setRows(
                datastores.deployments?.map((d: DeploymentsItem) => {
                    function handleMenuOptionClick(e: any) {
                        if (e.key === 'details') {
                            history.push(
                                `/projects/${projectUuid}/data-stores/${d.dataStoreUuid}/overview`
                            );
                        }
                    }

                    const onConfirmDelete = () => {
                        return new Promise(async (resolve, reject) => {
                            if (d) {
                                Modal.destroyAll();

                                try {
                                    await ProvisionService.removeDeployment(
                                        d.dataStoreUuid
                                    );

                                    dispatch(getAllDataStores());

                                    notification.open({
                                        message: 'Delete datastore',
                                        description:
                                            'The datastore will be removed soon.',
                                        icon: <CcxIconInfoCircleTwoTone />,
                                    });

                                    resolve(null);
                                } catch (e) {
                                    notification.open({
                                        message: 'Delete datastore',
                                        description: `There was an error trying to delete the datastore. ${e}`,
                                        icon: (
                                            <CcxIconCloseCircleTwoTone twoToneColor="#eb2f96" />
                                        ),
                                    });

                                    console.error(e);
                                    reject();
                                }
                            }
                        }).catch(() => console.log('Oops errors!'));
                    };

                    const menu = (
                        <>
                            <Menu onClick={handleMenuOptionClick}>
                                {!d.isUnreachable() && (
                                    <>
                                        <Menu.Item
                                            key="details"
                                            icon={<FileTextOutlined />}
                                        >
                                            Details
                                        </Menu.Item>
                                    </>
                                )}

                                <Menu.Divider />

                                <Menu.Item
                                    key="delete"
                                    danger={true}
                                    icon={<DeleteOutlined />}
                                >
                                    <AppDeleteModal
                                        stretchedClick={true}
                                        confirmPhrase={confirmPhrase}
                                        onConfirmDelete={onConfirmDelete}
                                        datastore={d}
                                        toDelete="datastore"
                                    />
                                </Menu.Item>
                            </Menu>
                        </>
                    );

                    const countryCode = deploymentOptions
                        ?.getCloudRegions(d?.getCloudProvider().code)
                        ?.filter((r: any) => {
                            return (
                                r.code === d.cloudRegion ||
                                r.preferredDisplayCode === d.cloudRegion
                            );
                        })[0]?.countryCode;

                    return {
                        borderAlert: d.isStatusError(),
                        statusOverlayText: d.getDataStoreStatus(),
                        columns: [
                            {
                                content: (
                                    <DataStoreInformation
                                        dataStore={d}
                                        testId={`${testId}${d.dataStoreUuid}DataStoreInformation`}
                                    />
                                ),
                                span: 5,
                            },
                            {
                                content: (
                                    <DataStoreStatus
                                        dataStore={d}
                                        testId={`${testId}${d.dataStoreUuid}Status`}
                                        updating={updating}
                                    />
                                ),
                                span: 4,
                            },
                            {
                                content: (
                                    <DatabaseInformation
                                        dataStore={d}
                                        testId={`${testId}${d.dataStoreUuid}DatabaseInformation`}
                                    />
                                ),
                                span: 4,
                            },
                            {
                                content: (
                                    <CloudInformation
                                        dataStore={d}
                                        testId={`${testId}${d.dataStoreUuid}CloudInformation`}
                                        countryCode={countryCode}
                                    />
                                ),
                                span: 4,
                            },
                            {
                                align: 'center',
                                content:
                                    d.isDeploying() ||
                                    d.isDeleting() ||
                                    d.isStatusError() ||
                                    d.isStatusWarning() ? (
                                        <NoChartAvailable
                                            testId={`${testId}${d.dataStoreUuid}NoChartAvailable`}
                                        />
                                    ) : (
                                        <DataStoreChart dataStore={d} />
                                    ),
                                span: 5,
                            },
                            {
                                align: 'center',
                                content:
                                    (d.operable &&
                                        !d.isDeleting() &&
                                        !d.isDeploying()) ||
                                    d.isUnreachable() ? (
                                        <Dropdown
                                            data-testid={`${testId}${d.dataStoreUuid}Menu`}
                                            overlay={menu}
                                            placement="bottomRight"
                                            trigger={['click']}
                                        >
                                            <Button
                                                data-testid={`${testId}${d.dataStoreUuid}MenuButton`}
                                                icon={
                                                    <CcxContextualMenuIcon
                                                        testId={`${testId}${d.dataStoreUuid}MenuButtonIcon`}
                                                    />
                                                }
                                            ></Button>
                                        </Dropdown>
                                    ) : (
                                        //temporary
                                        <Dropdown
                                            data-testid={`${testId}${d.dataStoreUuid}Menu`}
                                            overlay={menu}
                                            placement="bottomRight"
                                            trigger={['click']}
                                            disabled={!d.isUnreachable()}
                                        >
                                            <Button
                                                data-testid={`${testId}${d.dataStoreUuid}MenuButton`}
                                                icon={
                                                    <CcxContextualMenuIcon
                                                        testId={`${testId}${d.dataStoreUuid}MenuButtonIcon`}
                                                    />
                                                }
                                            ></Button>
                                        </Dropdown>
                                    ),
                                span: 2,
                            },
                        ],
                        disabled: d.isDeleting(),
                    };
                })
            );
        }
    }, [datastores]);

    if (rows?.length === 0) {
        return (
            <div className={styles.DataStoresEmpty}>
                <AppEmpty
                    testId={`${testId}Empty`}
                    message="There are no datastores created yet."
                >
                    <Link
                        to={`/projects/${projectUuid}/data-stores/add`}
                        data-testid={`${testId}CreateFirstLink`}
                    >
                        <Button data-testid={`${testId}CreateFirstButton`}>
                            Add your first datastore
                        </Button>
                    </Link>
                </AppEmpty>
            </div>
        );
    }

    return <AppGridTable columns={columns} rows={rows} testId={testId} />;
}

export default DataStores;
