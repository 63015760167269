export type CmonSqlStatBackendApiProps = {
    ABORTED_CLIENTS: number;
    ABORTED_CONNECTS: number;
    BYTES_RECEIVED: number;
    BYTES_SENT: number;
    COM_DELETE: number;
    COM_INSERT: number;
    COM_REPLACE: number;
    COM_SELECT: number;
    COM_UPDATE: number;
    CONNECTIONS: number;
    CREATED_TMP_DISK_TABLES: number;
    CREATED_TMP_FILES: number;
    CREATED_TMP_TABLES: number;
    HANDLER_DELETE: number;
    HANDLER_READ_FIRST: number;
    HANDLER_READ_KEY: number;
    HANDLER_READ_LAST: number;
    HANDLER_READ_NEXT: number;
    HANDLER_READ_PREV: number;
    HANDLER_READ_RND: number;
    HANDLER_READ_RND_NEXT: number;
    HANDLER_UPDATE: number;
    HANDLER_WRITE: number;
    INNODB_BUFFER_POOL_PAGES_DATA: number;
    INNODB_BUFFER_POOL_PAGES_DIRTY: number;
    INNODB_BUFFER_POOL_PAGES_FLUSHED: number;
    INNODB_BUFFER_POOL_PAGES_FREE: number;
    INNODB_BUFFER_POOL_PAGES_MISC: number;
    INNODB_BUFFER_POOL_PAGES_TOTAL: number;
    INNODB_DATA_FSYNCS: number;
    INNODB_DATA_PENDING_FSYNCS: number;
    INNODB_DATA_PENDING_READS: number;
    INNODB_DATA_PENDING_WRITES: number;
    INNODB_DATA_READ: number;
    INNODB_DATA_READS: number;
    INNODB_DATA_WRITES: number;
    INNODB_DATA_WRITTEN: number;
    INNODB_LOG_WAITS: number;
    INNODB_OS_LOG_FSYNCS: number;
    INNODB_OS_LOG_PENDING_FSYNCS: number;
    INNODB_OS_LOG_PENDING_WRITES: number;
    INNODB_OS_LOG_WRITTEN: number;
    INNODB_PAGES_CREATED: number;
    INNODB_PAGES_READ: number;
    INNODB_PAGES_WRITTEN: number;
    OPENED_TABLES: number;
    OPEN_TABLES: number;
    QUERIES: number;
    QUESTIONS: number;
    REPLICATION_LAG: number;
    SELECT_FULL_JOIN: number;
    SELECT_FULL_RANGE_JOIN: number;
    SELECT_RANGE: number;
    SELECT_RANGE_CHECK: number;
    SELECT_SCAN: number;
    SLOW_QUERIES: number;
    THREADS_CACHED: number;
    THREADS_CONNECTED: number;
    THREADS_CREATED: number;
    THREADS_RUNNING: number;
    WSREP_FLOW_CONTROL_PAUSED: number;
    WSREP_FLOW_CONTROL_PAUSED_NS: number;
    WSREP_FLOW_CONTROL_RECV: number;
    WSREP_FLOW_CONTROL_SENT: number;
    WSREP_LOCAL_BF_ABORTS: number;
    WSREP_LOCAL_RECV_QUEUE: number;
    WSREP_LOCAL_RECV_QUEUE_AVG: number;
    WSREP_LOCAL_RECV_QUEUE_MAX: number;
    WSREP_LOCAL_RECV_QUEUE_MIN: number;
    WSREP_LOCAL_SEND_QUEUE: number;
    WSREP_LOCAL_SEND_QUEUE_AVG: number;
    WSREP_LOCAL_SEND_QUEUE_MAX: number;
    WSREP_LOCAL_SEND_QUEUE_MIN: number;
    WSREP_RECEIVED_BYTES: number;
    WSREP_REPLICATED_BYTES: number;
    WSREP_REPL_DATA_BYTES: number;
    WSREP_REPL_KEYS_BYTES: number;
    WSREP_REPL_OTHER_BYTES: number;
    'blocks-hit': number;
    'blocks-read': number;
    commits: number;
    connections: number;
    created: number;
    interval: number;
    rollbacks: number;
    'rows-deleted': number;
    'rows-fetched': number;
    'rows-inserted': number;
    'rows-updated': number;
    sampleends: number;
    samplekey: string;
    serverid: number;
    transactions: number;
};

export default class CmonSqlStat {
    readonly abortedClients: number;
    readonly abortedConnects: number;
    readonly bytesReceived: number;
    readonly bytesSent: number;
    readonly comDelete: number;
    readonly comInsert: number;
    readonly comReplace: number;
    readonly comSelect: number;
    readonly comUpdate: number;
    readonly connectionsPostgreSql: number;
    readonly createdTmpDiskTables: number;
    readonly createdTmpFiles: number;
    readonly createdTmpTables: number;
    readonly handlerDelete: number;
    readonly handlerReadFirst: number;
    readonly handlerReadKey: number;
    readonly handlerReadLast: number;
    readonly handlerReadNext: number;
    readonly handlerReadPrev: number;
    readonly handlerReadRnd: number;
    readonly handlerReadRndNext: number;
    readonly handlerUpdate: number;
    readonly handlerWrite: number;
    readonly innodbBufferPoolPagesData: number;
    readonly innodbBufferPoolPagesDirty: number;
    readonly innodbBufferPoolPagesFlushed: number;
    readonly innodbBufferPoolPagesFree: number;
    readonly innodbBufferPoolPagesMisc: number;
    readonly innodbBufferPoolPagesTotal: number;
    readonly innodbDataFsyncs: number;
    readonly innodbDataPendingFsyncs: number;
    readonly innodbDataPendingReads: number;
    readonly innodbDataPendingWrites: number;
    readonly innodbDataRead: number;
    readonly innodbDataReads: number;
    readonly innodbDataWrites: number;
    readonly innodbDataWritten: number;
    readonly innodbLogWaits: number;
    readonly innodbOsLogFsyncs: number;
    readonly innodbOsLogPendingFsyncs: number;
    readonly innodbOsLogPendingWrites: number;
    readonly innodbOsLogWritten: number;
    readonly innodbPagesCreated: number;
    readonly innodbPagesRead: number;
    readonly innodbPagesWritten: number;
    readonly openedTables: number;
    readonly openTables: number;
    readonly queries: number;
    readonly questions: number;
    readonly replicationLag: number;
    readonly selectFullJoin: number;
    readonly selectFullRangeJoin: number;
    readonly selectRange: number;
    readonly selectRangeCheck: number;
    readonly selectScan: number;
    readonly slowQueries: number;
    readonly threadsCached: number;
    readonly threadsConnected: number;
    readonly threadsCreated: number;
    readonly threadsRunning: number;
    readonly wsrepFlowControlPaused: number;
    readonly wsrepFlowControlPausedNs: number;
    readonly wsrepFlowControlRecv: number;
    readonly wsrepFlowControlSent: number;
    readonly wsrepLocalBfAborts: number;
    readonly wsrepLocalRecvQueue: number;
    readonly wsrepLocalRecvQueueAvg: number;
    readonly wsrepLocalRcvQueueMax: number;
    readonly wsrepLocalRcvQueueMin: number;
    readonly wsrepLocalSendQueue: number;
    readonly wsrepLocalSendQueueAvg: number;
    readonly wsrepLocalSendQueueMax: number;
    readonly wsrepLocalSendQueueMin: number;
    readonly wsrepReceivedBytes: number;
    readonly wsrepReplicatedBytes: number;
    readonly wsrepReplDataBytes: number;
    readonly wsrepReplKeysBytes: number;
    readonly wsrepReplOtherBytes: number;
    readonly blocksHit: number;
    readonly blocksRead: number;
    readonly commits: number;
    readonly connectionsMySql: number;
    readonly created: number;
    readonly interval: number;
    readonly rollbacks: number;
    readonly rowsDeleted: number;
    readonly rowsFetched: number;
    readonly rowsInserted: number;
    readonly rowsUpdated: number;
    readonly sampleEnds: number;
    readonly sampleKey: string;
    readonly serverId: number;
    readonly transactions: number;

    constructor(props: CmonSqlStatBackendApiProps) {
        this.abortedClients = props['ABORTED_CLIENTS'];
        this.abortedConnects = props['ABORTED_CONNECTS'];
        this.bytesReceived = props['BYTES_RECEIVED'];
        this.bytesSent = props['BYTES_SENT'];
        this.comDelete = props['COM_DELETE'];
        this.comInsert = props['COM_INSERT'];
        this.comReplace = props['COM_REPLACE'];
        this.comSelect = props['COM_SELECT'];
        this.comUpdate = props['COM_UPDATE'];
        this.connectionsPostgreSql = props['connections'];
        this.createdTmpDiskTables = props['CREATED_TMP_DISK_TABLES'];
        this.createdTmpFiles = props['CREATED_TMP_FILES'];
        this.createdTmpTables = props['CREATED_TMP_TABLES'];
        this.handlerDelete = props['HANDLER_DELETE'];
        this.handlerReadFirst = props['HANDLER_READ_FIRST'];
        this.handlerReadKey = props['HANDLER_READ_KEY'];
        this.handlerReadLast = props['HANDLER_READ_LAST'];
        this.handlerReadNext = props['HANDLER_READ_NEXT'];
        this.handlerReadPrev = props['HANDLER_READ_PREV'];
        this.handlerReadRnd = props['HANDLER_READ_RND'];
        this.handlerReadRndNext = props['HANDLER_READ_RND_NEXT'];
        this.handlerUpdate = props['HANDLER_UPDATE'];
        this.handlerWrite = props['HANDLER_WRITE'];
        this.innodbBufferPoolPagesData = props['INNODB_BUFFER_POOL_PAGES_DATA'];
        this.innodbBufferPoolPagesDirty =
            props['INNODB_BUFFER_POOL_PAGES_DIRTY'];
        this.innodbBufferPoolPagesFlushed =
            props['INNODB_BUFFER_POOL_PAGES_FLUSHED'];
        this.innodbBufferPoolPagesFree = props['INNODB_BUFFER_POOL_PAGES_FREE'];
        this.innodbBufferPoolPagesMisc = props['INNODB_BUFFER_POOL_PAGES_MISC'];
        this.innodbBufferPoolPagesTotal =
            props['INNODB_BUFFER_POOL_PAGES_TOTAL'];
        this.innodbDataFsyncs = props['INNODB_DATA_FSYNCS'];
        this.innodbDataPendingFsyncs = props['INNODB_DATA_PENDING_FSYNCS'];
        this.innodbDataPendingReads = props['INNODB_DATA_PENDING_READS'];
        this.innodbDataPendingWrites = props['INNODB_DATA_PENDING_WRITES'];
        this.innodbDataRead = props['INNODB_DATA_READ'];
        this.innodbDataReads = props['INNODB_DATA_READS'];
        this.innodbDataWrites = props['INNODB_DATA_WRITES'];
        this.innodbDataWritten = props['INNODB_DATA_WRITTEN'];
        this.innodbLogWaits = props['INNODB_LOG_WAITS'];
        this.innodbOsLogFsyncs = props['INNODB_OS_LOG_FSYNCS'];
        this.innodbOsLogPendingFsyncs = props['INNODB_OS_LOG_PENDING_FSYNCS'];
        this.innodbOsLogPendingWrites = props['INNODB_OS_LOG_PENDING_WRITES'];
        this.innodbOsLogWritten = props['INNODB_OS_LOG_WRITTEN'];
        this.innodbPagesCreated = props['INNODB_PAGES_CREATED'];
        this.innodbPagesRead = props['INNODB_PAGES_READ'];
        this.innodbPagesWritten = props['INNODB_PAGES_WRITTEN'];
        this.openedTables = props['OPENED_TABLES'];
        this.openTables = props['OPEN_TABLES'];
        this.queries = props['QUERIES'];
        this.questions = props['QUESTIONS'];
        this.replicationLag = props['REPLICATION_LAG'];
        this.selectFullJoin = props['SELECT_FULL_JOIN'];
        this.selectFullRangeJoin = props['SELECT_FULL_RANGE_JOIN'];
        this.selectRange = props['SELECT_RANGE'];
        this.selectRangeCheck = props['SELECT_RANGE_CHECK'];
        this.selectScan = props['SELECT_SCAN'];
        this.slowQueries = props['SLOW_QUERIES'];
        this.threadsCached = props['THREADS_CACHED'];
        this.threadsConnected = props['THREADS_CONNECTED'];
        this.threadsCreated = props['THREADS_CREATED'];
        this.threadsRunning = props['THREADS_RUNNING'];
        this.wsrepFlowControlPaused = props['WSREP_FLOW_CONTROL_PAUSED'];
        this.wsrepFlowControlPausedNs = props['WSREP_FLOW_CONTROL_PAUSED_NS'];
        this.wsrepFlowControlRecv = props['WSREP_FLOW_CONTROL_RECV'];
        this.wsrepFlowControlSent = props['WSREP_FLOW_CONTROL_SENT'];
        this.wsrepLocalBfAborts = props['WSREP_LOCAL_BF_ABORTS'];
        this.wsrepLocalRecvQueue = props['WSREP_LOCAL_RECV_QUEUE'];
        this.wsrepLocalRecvQueueAvg = props['WSREP_LOCAL_RECV_QUEUE_AVG'];
        this.wsrepLocalRcvQueueMax = props['WSREP_LOCAL_RECV_QUEUE_MAX'];
        this.wsrepLocalRcvQueueMin = props['WSREP_LOCAL_RECV_QUEUE_MIN'];
        this.wsrepLocalSendQueue = props['WSREP_LOCAL_SEND_QUEUE'];
        this.wsrepLocalSendQueueAvg = props['WSREP_LOCAL_SEND_QUEUE_AVG'];
        this.wsrepLocalSendQueueMax = props['WSREP_LOCAL_SEND_QUEUE_MAX'];
        this.wsrepLocalSendQueueMin = props['WSREP_LOCAL_SEND_QUEUE_MIN'];
        this.wsrepReceivedBytes = props['WSREP_RECEIVED_BYTES'];
        this.wsrepReplicatedBytes = props['WSREP_REPLICATED_BYTES'];
        this.wsrepReplDataBytes = props['WSREP_REPL_DATA_BYTES'];
        this.wsrepReplKeysBytes = props['WSREP_REPL_KEYS_BYTES'];
        this.wsrepReplOtherBytes = props['WSREP_REPL_OTHER_BYTES'];
        this.blocksHit = props['blocks-hit'];
        this.blocksRead = props['blocks-read'];
        this.commits = props['commits'];
        this.connectionsMySql = props['CONNECTIONS'];
        this.created = props['created'];
        this.interval = props['interval'];
        this.rollbacks = props['rollbacks'];
        this.rowsDeleted = props['rows-deleted'];
        this.rowsFetched = props['rows-fetched'];
        this.rowsInserted = props['rows-inserted'];
        this.rowsUpdated = props['rows-updated'];
        this.sampleEnds = props['sampleends'];
        this.sampleKey = props['samplekey'];
        this.serverId = props['serverid'];
        this.transactions = props['transactions'];
    }
}
