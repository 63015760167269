import React, { ReactElement, useState } from 'react';
import styles from './SecretText.module.less';
import { Tooltip } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import Hoverable from './Hoverable';
import CcxComponentProps from '../core/CcxComponent';

interface Props extends CcxComponentProps {
    visibleText?: string;
    hiddenText?: string;
    tooltipText?: string;
    displayInline?: any;
}

function SecretText({
    visibleText,
    hiddenText,
    testId = 'SecretText',
    tooltipText,
    displayInline,
}: Props): ReactElement {
    const [expanded, setExpanded] = useState(false);

    const handleClick = () => {
        setExpanded(!expanded);
    };

    return (
        <div
            data-testid={testId}
            className={displayInline ? `${styles.SecretTextDisplay}` : ``}
        >
            <Tooltip
                placement="bottom"
                data-testid={`${testId}Tooltip`}
                title={tooltipText || 'Click to show / hide text.'}
                className={styles.SecretText}
                {...{ onClick: handleClick }}
            >
                {expanded ? hiddenText : visibleText}
            </Tooltip>
        </div>
    );
}

export default SecretText;
