import React, { ReactElement, Suspense } from 'react';
import { Button, Tabs, Tooltip } from 'antd';
import CcxComponentProps from '../../core/CcxComponent';
import styles from './Projects.module.less';
import { Link, useHistory, useParams } from 'react-router-dom';
import LazyLoader from '../LazyLoader';
import DataStores from './dataStores/DataStores';
import Vpcs from './vpcs/Vpcs';
import CreateVpcButton from '../vpc/CreateVpcButton';
import { vpcDisabled } from '../../core/CcxEnv';
import { userReachedDatastoreLimit } from '../layout/AppCreateButton';
import { useAppSelector } from '../../redux/hooks';

interface UrlProps {
    projectUuid: string;
    activeTab: string;
}

interface Props extends CcxComponentProps {}

function Projects({ testId = 'Projects' }: Props): ReactElement {
    let history = useHistory();
    let { projectUuid, activeTab } = useParams<UrlProps>();

    const { datastores } = useAppSelector((state) => state.datastores);

    const { user } = useAppSelector((state) => state.user);

    const limitReached = userReachedDatastoreLimit(datastores, user);
    const operations =
        activeTab === 'data-stores' ? (
            <Tooltip title={limitReached ? user?.disabledText : ''}>
                <Link to={`/projects/${projectUuid}/${activeTab}/add`}>
                    <Button
                        data-testid={`${testId}CreateNewButton`}
                        type="primary"
                        disabled={limitReached}
                        className={styles.ProjectsOperationButton}
                    >
                        + Create{' '}
                        {activeTab === 'data-stores' ? 'datastore' : 'VPC'}
                    </Button>
                </Link>
            </Tooltip>
        ) : (
            <CreateVpcButton
                buttonText="+ Create VPC"
                buttonType="primary"
                className={styles.ProjectsOperationButton}
                size="middle"
            />
        );

    return (
        <section className={styles.Projects} data-testid={testId}>
            <Tabs
                className={styles.ProjectsTabs}
                data-testid={`${testId}Tabs`}
                tabBarExtraContent={operations}
                type="card"
                defaultActiveKey={activeTab}
                onChange={(tabKey: any) => {
                    history.push(`/projects/${projectUuid}/${tabKey}`);
                }}
            >
                <Tabs.TabPane tab="Datastores" key="data-stores">
                    <Suspense fallback={<LazyLoader />}>
                        <DataStores />
                    </Suspense>
                </Tabs.TabPane>

                {!vpcDisabled && (
                    <Tabs.TabPane tab="VPCs" key="vpcs">
                        <Suspense fallback={<LazyLoader />}>
                            <Vpcs />
                        </Suspense>
                    </Tabs.TabPane>
                )}
            </Tabs>
        </section>
    );
}

export default Projects;
