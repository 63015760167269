import { useEffect, useState } from 'react';
import Deployment from '../../types/Deployment';
import DataStoreService from '../../services/DataStoreService';

export default function useDataStore(dataStoreUuid?: string) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<Error | null>(null);
    const [dataStore, setDataStore] = useState<Deployment | undefined>(
        undefined
    );

    const load = async () => {
        if (dataStoreUuid) {
            setLoading(true);

            try {
                const result = await DataStoreService.getDataStore(
                    dataStoreUuid
                );
                setDataStore(result);
                setLoading(false);
                setError(null);
            } catch (e: any) {
                setLoading(false);
                setError(e);
            }

            setLoading(false);
        }
    };

    const refresh = () => {
        load();
    };

    // get deployments for the given user
    useEffect(() => {
        refresh();
    }, [dataStoreUuid]);

    return { dataStore, refresh, loading, error };
}
