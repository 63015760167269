import React, { ReactElement } from 'react';
import Icon from '@ant-design/icons';
import CcxComponentProps from '../../../core/CcxComponent';
import styles from './AppCdnSvg.module.less';

interface Props extends CcxComponentProps {
    filename: string;
    height?: number;
    size: string | number;
    width?: number;
}

function AppCdnSvg({
    className,
    filename,
    height,
    size,
    testId,
    width,
}: Props): ReactElement {
    const ICON_SIZE_XSMALL = 'xsmall';
    const ICON_SIZE_SMALL = 'small';
    const ICON_SIZE_MEDIUM = 'medium';
    const ICON_SIZE_LARGE = 'large';
    const ICON_SIZE_XLARGE = 'xlarge';
    const ICON_SIZE_2XLARGE = '2xlarge';

    const CDN_URL = 'https://st.s9s.io/ccx';

    let widthPx: string, heightPx: string;

    if (typeof size === 'string') {
        switch (size) {
            case ICON_SIZE_XSMALL:
                heightPx = '16px';
                widthPx = '16px';
                break;
            case ICON_SIZE_MEDIUM:
                heightPx = '24px';
                widthPx = '24px';
                break;
            case ICON_SIZE_LARGE:
                heightPx = '32px';
                widthPx = '32px';
                break;
            case ICON_SIZE_XLARGE:
                heightPx = '64px';
                widthPx = '64px';
                break;
            case ICON_SIZE_2XLARGE:
                heightPx = '128px';
                widthPx = '128px';
                break;
            case ICON_SIZE_SMALL:
            default:
                heightPx = '18px';
                widthPx = '18px';
                break;
        }
    } else {
        heightPx = `${size}px`;
        widthPx = `${size}px`;
    }

    // width and height will overide size
    if (width && height) {
        heightPx = `${height}px`;
        widthPx = `${width}px`;
    }

    return (
        <Icon
            className={className || styles.AppCdnSvg}
            data-testid={testId || 'AppCdnSvg'}
            component={() =>
                typeof filename === 'string' ? (
                    <img
                        src={`${CDN_URL}/${filename}.svg`}
                        alt={filename}
                        width={widthPx}
                        height={heightPx}
                    />
                ) : (
                    React.cloneElement(filename, {
                        width: widthPx,
                        height: heightPx,
                    })
                )
            }
        />
    );
}

export default AppCdnSvg;
