import { ReactElement, useState } from 'react';
import styles from './ConnectionAssistantFormStep2.module.less';
import DsnService from '../../services/DsnService';
import AppCodeCard from '../AppCodeCard';
import { Select } from 'antd';
import CcxComponentProps from '../../core/CcxComponent';

interface Props extends CcxComponentProps {
    formData?: any;
    service?: string;
}

export default function ConnectionAssistantFormStep2({
    formData,
    service,
    testId = 'ConnectionAssistantFormStep2',
}: Props): ReactElement {
    const { Option } = Select;

    const [technology, setTechnology] = useState<string>();

    const { account, database, hostPort, password } = formData;
    const [host, port] = hostPort.split(':');
    const getUsageExample = (targetLanguage: string) => {
        return DsnService.getDsnUsageExample({
            service,
            username: account,
            database,
            hostPort,
            host: host,
            port: port,
            target: targetLanguage,
            password: password,
        });
    };

    const getCodeCardContent = (targetLanguage: string) => {
        return getUsageExample(targetLanguage)?.dsnForDisplay?.map(
            (line: string) => {
                return <>{line}</>;
            }
        );
    };

    let technologies :string[] = [
        'dsn',
        'ado.net',
        'jdbc',
        'node.js',
        'odbc',
        'php',
        'php.pdo',
        'php.pear.db',
        'python',
        'ruby',
        'go',
    ];
    switch (service) {
        case 'redis':
            technologies = [
                'dsn',
                'node.js',
                'php',
                'python',
                'ruby',
                'go',
            ];
            break;
        case 'mssql':
            technologies = ['dsn']
            break;
    }

    const onChange = (e: any) => {
        setTechnology(e);
    };

    return (
        <div
            className={styles.ConnectionAssistantFormStep2}
            data-testid={testId}
        >
            <p>Technology</p>
            <Select
                onChange={onChange}
                data-testid={`${testId}Select`}
                className={styles.ConnectionAssistantFormStep2Select}
                placeholder="Select a technology"
                allowClear
            >
                {technologies.map((o: string) => {
                    return (
                        <Option value={o} key={o}>
                            {o.toUpperCase()}
                        </Option>
                    );
                })}
            </Select>

            {technology && (
                <>
                    <p>{technology.toUpperCase()}</p>
                    <AppCodeCard
                        data-testid={`${testId}CodeCard`}
                        usageExample={getUsageExample(technology)?.dsnForCopy}
                    >
                        {getCodeCardContent(technology)}
                    </AppCodeCard>
                </>
            )}
        </div>
    );
}
