import React, { ReactElement } from 'react';
import { hot } from 'react-hot-loader/root';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import AppLayout from './components/layout/AppLayout';
import './App.module.less';
import AppErrorBoundary from './components/errors/AppErrorBoundary';
import AppProviders from './AppProviders';

function App(): ReactElement {
    return (
        <AppErrorBoundary>
            <AppProviders>
                <Router>
                    <Switch>
                        <Route
                            path="*"
                            exact
                            render={() => <AppLayout />}
                        ></Route>
                    </Switch>
                </Router>
            </AppProviders>
        </AppErrorBoundary>
    );
}

export default hot(App);
