import React, { ReactElement, useEffect } from 'react';
import { Row, Col, Form, Input } from 'antd';
import CcxComponentProps from '../../../../core/CcxComponent';
import styles from './DataStoreSettingsNameChange.module.less';

interface Props extends CcxComponentProps {
    dataStoreName?: string;
    form: any;
    onChange: Function;
}

function DataStoreSettingsNameChange({
    dataStoreName,
    form,
    onChange,
    testId = 'DataStoreSettingsNameChange',
}: Props): ReactElement {
    useEffect(() => {
        if (dataStoreName) form.setFieldsValue({ dataStoreName });
    }, [dataStoreName]);

    const handleOnChange = () => {
        if (dataStoreName !== form.getFieldValue('dataStoreName')) {
            onChange && onChange(form.getFieldValue('dataStoreName'));
        }
    };

    return (
        <div
            className={styles.DataStoreSettingsNameChange}
            data-testid={testId}
        >
            <h2 data-testid={`${testId}Title`}>Datastore name</h2>
            <Form form={form} layout="vertical">
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item noStyle>
                            <Form.Item
                                hasFeedback
                                label="Name"
                                name="dataStoreName"
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            'Please enter a name for the datastore',
                                    },
                                ]}
                            >
                                <Input
                                    autoComplete="off"
                                    data-testid={`${testId}DataStoreNameInput`}
                                    placeholder="Enter a name for your datastore"
                                    size="large"
                                    onChange={handleOnChange}
                                />
                            </Form.Item>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </div>
    );
}

export default DataStoreSettingsNameChange;
