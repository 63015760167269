import React, { ReactElement, useEffect, useState } from 'react';
import styles from './MysqlDbDashboard.module.less';
import { Col, Row, Spin } from 'antd';
import usePrometheusMySqlQueriesStats from '../../../core/hooks/usePrometheusMySqlQueriesStats';
import usePrometheusMySqlAbortedConnectionsStats from '../../../core/hooks/usePrometheusMySqlAbortedConnectionsStats';
import usePrometheusMySqlDbConnectionsStats from '../../../core/hooks/usePrometheusMySqlDbConnectionsStats';
import usePrometheusMySqlHandlersStats from '../../../core/hooks/usePrometheusMySqlHandlersStats';
import usePrometheusMySqlHandlerTransactionsStats from '../../../core/hooks/usePrometheusMySqlHandlerTransactionsStats';
import usePrometheusMySqlMemoryUtilizationStats from '../../../core/hooks/usePrometheusMySqlMemoryUtilizationStats';
import usePrometheusMySqlScanOperationsStats from '../../../core/hooks/usePrometheusMySqlScanOperationsStats';
import usePrometheusMySqlSortingStats from '../../../core/hooks/usePrometheusMySqlSortingStats';
import usePrometheusMySqlTableLockingStats from '../../../core/hooks/usePrometheusMySqlTableLockingStats';
import usePrometheusMySqlTemporaryTablesStats from '../../../core/hooks/usePrometheusMySqlTemporaryTablesStats';
import PrometheusMySqlHandlersChart from '../../ccx/charts/PrometheusMySqlHandlersChart';
import PrometheusMySqlHandlerTransactionsChart from '../../ccx/charts/PrometheusMySqlHandlerTransactionsChart';
import PrometheusMySqlDbConnectionsChart from '../../ccx/charts/PrometheusMySqlDbConnectionsChart';
import PrometheusMySqlQueriesChart from '../../ccx/charts/PrometheusMySqlQueriesChart';
import PrometheusMySqlScanOperationsChart from '../../ccx/charts/PrometheusMySqlScanOperationsChart';
import PrometheusMySqlTableLockingChart from '../../ccx/charts/PrometheusMySqlTableLockingChart';
import PrometheusMySqlTemporaryTablesChart from '../../ccx/charts/PrometheusMySqlTemporaryTablesChart';
import PrometheusMySqlSortingChart from '../../ccx/charts/PrometheusMySqlSortingChart';
import PrometheusMySqlAbortedConnectionsChart from '../../ccx/charts/PrometheusMySqlAbortedConnectionsChart';
import PrometheusMySqlMemoryUtilizationChart from '../../ccx/charts/PrometheusMySqlMemoryUtilizationChart';

type MysqlDbDashboardProps = {
    uuid: string;
    host_uuid: string;
    port: number;
    from?: string;
    to?: string;
    interval: number;
    hidden?: boolean;
    displaySummary?: boolean;
    onLoading?: Function;
};

function MysqlDbDashboard({
    uuid,
    host_uuid,
    port,
    from,
    to,
    interval,
    hidden,
    displaySummary,
    onLoading,
}: MysqlDbDashboardProps): ReactElement {
    const {
        stats: queriesStats,
        refresh: queriesStatsRefresh,
        loading: queriesStatsLoading,
    } = usePrometheusMySqlQueriesStats(uuid, host_uuid, from, to);

    const {
        stats: abortedConnectionsStats,
        refresh: abortedConnectionsStatsRefresh,
        loading: abortedConnectionsStatsLoading,
    } = usePrometheusMySqlAbortedConnectionsStats(uuid, host_uuid, from, to);

    const {
        stats: connectionsStats,
        refresh: connectionsStatsRefresh,
        loading: connectionsStatsLoading,
    } = usePrometheusMySqlDbConnectionsStats(uuid, host_uuid, from, to);

    const {
        stats: handlersStats,
        refresh: handlersStatsRefresh,
        loading: handlersStatsLoading,
    } = usePrometheusMySqlHandlersStats(uuid, host_uuid, from, to);

    const {
        stats: handlerTransactionsStats,
        refresh: handlerTransactionsStatsRefresh,
        loading: handlerTransactionsStatsLoading,
    } = usePrometheusMySqlHandlerTransactionsStats(uuid, host_uuid, from, to);

    const {
        stats: memoryUtilizationStats,
        refresh: memoryUtilizationStatsRefresh,
        loading: memoryUtilizationStatsLoading,
    } = usePrometheusMySqlMemoryUtilizationStats(uuid, host_uuid, from, to);

    const {
        stats: scanOperationsStats,
        refresh: scanOperationsStatsRefresh,
        loading: scanOperationsStatsLoading,
    } = usePrometheusMySqlScanOperationsStats(uuid, host_uuid, from, to);

    const {
        stats: sortingStats,
        refresh: sortingStatsRefresh,
        loading: sortingStatsLoading,
    } = usePrometheusMySqlSortingStats(uuid, host_uuid, from, to);

    const {
        stats: tableLockingStats,
        refresh: tableLockingStatsRefresh,
        loading: tableLockingStatsLoading,
    } = usePrometheusMySqlTableLockingStats(uuid, host_uuid, from, to);

    const {
        stats: temporaryTablesStats,
        refresh: temporaryTablesStatsRefresh,
        loading: temporaryTablesStatsLoading,
    } = usePrometheusMySqlTemporaryTablesStats(uuid, host_uuid, from, to);

    const [queriesStatsInterval, setQueriesStatsInterval] = useState<
        number | undefined
    >(undefined);
    const [
        abortedConnectionsStatsInterval,
        setAbortedConnectionsStatsInterval,
    ] = useState<number | undefined>(undefined);
    const [connectionsStatsInterval, setConnectionsStatsInterval] = useState<
        number | undefined
    >(undefined);
    const [handlersStatsInterval, setHandlersStatsInterval] = useState<
        number | undefined
    >(undefined);
    const [
        handlerTransactionsStatsInterval,
        setHandlerTransactionsStatsInterval,
    ] = useState<number | undefined>(undefined);
    const [memoryUtilizationStatsInterval, setMemoryUtilizationStatsInterval] =
        useState<number | undefined>(undefined);
    const [scanOperationsStatsInterval, setScanOperationsStatsInterval] =
        useState<number | undefined>(undefined);
    const [sortingStatsInterval, setSortingStatsInterval] = useState<
        number | undefined
    >(undefined);
    const [tableLockingStatsInterval, setTableLockingStatsInterval] = useState<
        number | undefined
    >(undefined);
    const [temporaryTablesStatsInterval, setTemporaryTablesStatsInterval] =
        useState<number | undefined>(undefined);

    const [refreshInterval, setRefreshInterval] = useState(interval);

    useEffect(() => {
        let interval: any;
        if (queriesStats && refreshInterval > 0) {
            interval = setInterval(() => {
                queriesStatsRefresh();
            }, refreshInterval);
            setQueriesStatsInterval(interval);
        }
        return () => clearInterval(interval);
    }, [queriesStats, refreshInterval]);

    useEffect(() => {
        let interval: any;
        if (abortedConnectionsStats && refreshInterval > 0) {
            interval = setInterval(() => {
                abortedConnectionsStatsRefresh();
            }, refreshInterval);
            setAbortedConnectionsStatsInterval(interval);
        }
        return () => clearInterval(interval);
    }, [abortedConnectionsStats, refreshInterval]);

    useEffect(() => {
        let interval: any;
        if (connectionsStats && refreshInterval > 0) {
            interval = setInterval(() => {
                connectionsStatsRefresh();
            }, refreshInterval);
            setConnectionsStatsInterval(interval);
        }
        return () => clearInterval(interval);
    }, [connectionsStats, refreshInterval]);

    useEffect(() => {
        let interval: any;
        if (handlersStats && refreshInterval > 0) {
            interval = setInterval(() => {
                handlersStatsRefresh();
            }, refreshInterval);
            setHandlersStatsInterval(interval);
        }
        return () => clearInterval(interval);
    }, [handlersStats, refreshInterval]);

    useEffect(() => {
        let interval: any;
        if (handlerTransactionsStats && refreshInterval > 0) {
            interval = setInterval(() => {
                handlerTransactionsStatsRefresh();
            }, refreshInterval);
            setHandlerTransactionsStatsInterval(interval);
        }
        return () => clearInterval(interval);
    }, [handlerTransactionsStats, refreshInterval]);

    useEffect(() => {
        let interval: any;
        if (memoryUtilizationStats && refreshInterval > 0) {
            interval = setInterval(() => {
                memoryUtilizationStatsRefresh();
            }, refreshInterval);
            setMemoryUtilizationStatsInterval(interval);
        }
        return () => clearInterval(interval);
    }, [memoryUtilizationStats, refreshInterval]);

    useEffect(() => {
        let interval: any;
        if (scanOperationsStats && refreshInterval > 0) {
            interval = setInterval(() => {
                scanOperationsStatsRefresh();
            }, refreshInterval);
            setScanOperationsStatsInterval(interval);
        }
        return () => clearInterval(interval);
    }, [scanOperationsStats, refreshInterval]);

    useEffect(() => {
        let interval: any;
        if (sortingStats && refreshInterval > 0) {
            interval = setInterval(() => {
                sortingStatsRefresh();
            }, refreshInterval);
            setSortingStatsInterval(interval);
        }
        return () => clearInterval(interval);
    }, [sortingStats, refreshInterval]);

    useEffect(() => {
        let interval: any;
        if (tableLockingStats && refreshInterval > 0) {
            interval = setInterval(() => {
                tableLockingStatsRefresh();
            }, refreshInterval);
            setTableLockingStatsInterval(interval);
        }
        return () => clearInterval(interval);
    }, [tableLockingStats, refreshInterval]);

    useEffect(() => {
        let interval: any;
        if (temporaryTablesStats && refreshInterval > 0) {
            interval = setInterval(() => {
                temporaryTablesStatsRefresh();
            }, refreshInterval);
            setTemporaryTablesStatsInterval(interval);
        }
        return () => clearInterval(interval);
    }, [temporaryTablesStats, refreshInterval]);

    useEffect(() => {
        if (interval === 0) {
            // when interval is 0, we must pause the refresh (clear existing intervals)
            clearInterval(queriesStatsInterval);
            clearInterval(abortedConnectionsStatsInterval);
            clearInterval(connectionsStatsInterval);
            clearInterval(handlersStatsInterval);
            clearInterval(handlerTransactionsStatsInterval);
            clearInterval(memoryUtilizationStatsInterval);
            clearInterval(scanOperationsStatsInterval);
            clearInterval(sortingStatsInterval);
            clearInterval(tableLockingStatsInterval);
            clearInterval(temporaryTablesStatsInterval);
            setQueriesStatsInterval(0);
            setAbortedConnectionsStatsInterval(0);
            setConnectionsStatsInterval(0);
            setHandlersStatsInterval(0);
            setHandlerTransactionsStatsInterval(0);
            setMemoryUtilizationStatsInterval(0);
            setScanOperationsStatsInterval(0);
            setSortingStatsInterval(0);
            setTableLockingStatsInterval(0);
            setTemporaryTablesStatsInterval(0);
        }
        setRefreshInterval(interval);
    }, [interval]);

    useEffect(() => {
        if (hidden) {
            // clear interval to stop updating data if the tab is not active (charts are not visible)
            clearInterval(queriesStatsInterval);
            clearInterval(abortedConnectionsStatsInterval);
            clearInterval(connectionsStatsInterval);
            clearInterval(handlersStatsInterval);
            clearInterval(handlerTransactionsStatsInterval);
            clearInterval(memoryUtilizationStatsInterval);
            clearInterval(scanOperationsStatsInterval);
            clearInterval(sortingStatsInterval);
            clearInterval(tableLockingStatsInterval);
            clearInterval(temporaryTablesStatsInterval);
            setQueriesStatsInterval(0);
            setAbortedConnectionsStatsInterval(0);
            setConnectionsStatsInterval(0);
            setHandlersStatsInterval(0);
            setHandlerTransactionsStatsInterval(0);
            setMemoryUtilizationStatsInterval(0);
            setScanOperationsStatsInterval(0);
            setSortingStatsInterval(0);
            setTableLockingStatsInterval(0);
            setTemporaryTablesStatsInterval(0);
        } else if (
            queriesStatsInterval === 0 ||
            abortedConnectionsStatsInterval === 0 ||
            connectionsStatsInterval === 0 ||
            handlersStatsInterval === 0 ||
            handlerTransactionsStatsInterval === 0 ||
            memoryUtilizationStatsInterval === 0 ||
            scanOperationsStatsInterval === 0 ||
            sortingStatsInterval === 0 ||
            tableLockingStatsInterval === 0 ||
            temporaryTablesStatsInterval === 0
        ) {
            // force refresh if the interval is 0, otherwise, the interval will refresh data soon
            // interval is undefined on the first load
            // interval will be 0 when paused or tab is hidden
            queriesStatsRefresh();
            abortedConnectionsStatsRefresh();
            connectionsStatsRefresh();
            handlersStatsRefresh();
            handlerTransactionsStatsRefresh();
            memoryUtilizationStatsRefresh();
            scanOperationsStatsRefresh();
            sortingStatsRefresh();
            tableLockingStatsRefresh();
            temporaryTablesStatsRefresh();
        }
    }, [hidden]);

    useEffect(() => {
        if (
            queriesStatsLoading ||
            abortedConnectionsStatsLoading ||
            connectionsStatsLoading ||
            handlersStatsLoading ||
            handlerTransactionsStatsLoading ||
            memoryUtilizationStatsLoading ||
            scanOperationsStatsLoading ||
            sortingStatsLoading ||
            tableLockingStatsLoading ||
            temporaryTablesStatsLoading
        ) {
            onLoading && onLoading(true);
        } else {
            onLoading && onLoading(false);
        }
    }, [
        queriesStatsLoading,
        abortedConnectionsStatsLoading,
        connectionsStatsLoading,
        handlersStatsLoading,
        handlerTransactionsStatsLoading,
        memoryUtilizationStatsLoading,
        scanOperationsStatsLoading,
        sortingStatsLoading,
        tableLockingStatsLoading,
        temporaryTablesStatsLoading,
    ]);

    return (
        <section className={styles.MysqlDbDashboard}>
            <Row
                gutter={[
                    32,
                    { xs: 8, sm: 16, md: 24, lg: 32, xl: 40, xxl: 48 },
                ]}
                className={styles.MysqlDbDashboardRow}
            >
                <Col
                    className={styles.MysqlDbDashboardCol}
                    xs={24}
                    sm={24}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                >
                    <PrometheusMySqlHandlersChart
                        data={handlersStats}
                        loading={handlersStatsLoading}
                        displaySummary={displaySummary}
                    />
                </Col>
                <Col
                    className={styles.MysqlDbDashboardCol}
                    xs={24}
                    sm={24}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                >
                    <PrometheusMySqlHandlerTransactionsChart
                        data={handlerTransactionsStats}
                        loading={handlerTransactionsStatsLoading}
                        displaySummary={displaySummary}
                    />
                </Col>
                <Col
                    className={styles.MysqlDbDashboardCol}
                    xs={24}
                    sm={24}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                >
                    <PrometheusMySqlDbConnectionsChart
                        data={connectionsStats}
                        loading={connectionsStatsLoading}
                        displaySummary={displaySummary}
                    />
                </Col>
                <Col
                    className={styles.MysqlDbDashboardCol}
                    xs={24}
                    sm={24}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                >
                    <PrometheusMySqlQueriesChart
                        data={queriesStats}
                        loading={queriesStatsLoading}
                        displaySummary={displaySummary}
                    />
                </Col>
                <Col
                    className={styles.MysqlDbDashboardCol}
                    xs={24}
                    sm={24}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                >
                    <PrometheusMySqlScanOperationsChart
                        data={scanOperationsStats}
                        loading={scanOperationsStatsLoading}
                        displaySummary={displaySummary}
                    />
                </Col>
                <Col
                    className={styles.MysqlDbDashboardCol}
                    xs={24}
                    sm={24}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                >
                    <PrometheusMySqlTableLockingChart
                        data={tableLockingStats}
                        loading={tableLockingStatsLoading}
                        displaySummary={displaySummary}
                    />
                </Col>
                <Col
                    className={styles.MysqlDbDashboardCol}
                    xs={24}
                    sm={24}
                    md={12}
                    lg={12}
                    xl={12}
                    xxl={12}
                >
                    <PrometheusMySqlTemporaryTablesChart
                        data={temporaryTablesStats}
                        loading={temporaryTablesStatsLoading}
                        displaySummary={displaySummary}
                    />
                </Col>
                <Col
                    className={styles.MysqlDbDashboardCol}
                    xs={24}
                    sm={24}
                    md={12}
                    lg={12}
                    xl={12}
                    xxl={12}
                >
                    <PrometheusMySqlSortingChart
                        data={sortingStats}
                        loading={sortingStatsLoading}
                        displaySummary={displaySummary}
                    />
                </Col>
                <Col
                    className={styles.MysqlDbDashboardCol}
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={24}
                    xxl={24}
                >
                    <PrometheusMySqlAbortedConnectionsChart
                        data={abortedConnectionsStats}
                        loading={abortedConnectionsStatsLoading}
                        displaySummary={displaySummary}
                    />
                </Col>
                <Col
                    className={styles.MysqlDbDashboardCol}
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={24}
                    xxl={24}
                >
                    <PrometheusMySqlMemoryUtilizationChart
                        data={memoryUtilizationStats}
                        loading={memoryUtilizationStatsLoading}
                        displaySummary={displaySummary}
                    />
                </Col>
            </Row>
        </section>
    );
}

export default MysqlDbDashboard;
