import Service, { ServiceClassProps } from './Service';
import {getHostnameOrIP} from "../core/helpers";

export type DbServiceClassProps = ServiceClassProps & {
    disk_size: number;
};

export interface DbServiceInterface {}

export default class DbService extends Service implements DbServiceInterface {
    private disk_size: number;

    constructor(props: DbServiceClassProps) {
      super(props);
      const {disk_size} = props;
      this.disk_size = disk_size;
    }

    public getDiskSize(): number {
        return this.disk_size;
    }
}
