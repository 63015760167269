import { Alert, Form } from 'antd';
import React, { useMemo } from 'react';
import { FormInstance } from 'antd/lib/form';
import DeploymentsItem from '../../types/DeploymentsItem';
import CronInput from '@severalnines/bar-frontend-components/build/lib/DataEntry/CronInput';
import useBackupSettings from '../../core/hooks/useBackupSettings';
import AppLoading from '../AppLoading';
import CronFormat from '@severalnines/bar-frontend-components/build/lib/Format/CronFormat';
import Space from '../common/antd/Space';
import BackupScheduleInfo from './FormParts/BackupScheduleInfo';

type BackupsScheduleEditFullFormProps = {
    deployment: DeploymentsItem;
    testId?: string;
    form: FormInstance;
    onSuccess?: () => void;
};
export default function BackupsScheduleEditFullForm({
    deployment,
    form,
    onSuccess,
    testId = 'BackupsScheduleEditFullForm',
}: BackupsScheduleEditFullFormProps) {
    const { backupSettings, update, loading }: any = useBackupSettings(
        deployment.dataStoreUuid
    );
    const initialValues = useMemo(() => {
        if (loading) return undefined;
        if (!backupSettings) return undefined;
        return {
            cron: backupSettings.fullCron,
        };
    }, [backupSettings, loading]);

    const handleSubmit = async ({ cron }: any) => {
        try {
            await update({
                full: {
                    frequency: backupSettings.fullFrequency,
                    cron,
                },
            });
            onSuccess?.();
        } catch (e: any) {
            console.error(e);
        }
    };

    return loading ? (
        <AppLoading />
    ) : (
        <Form
            form={form}
            data-testid={testId}
            layout="vertical"
            initialValues={initialValues}
            onFinish={handleSubmit}
        >
            <Form.Item
                name="cron"
                label="Start the full backup every"
                rules={[
                    {
                        required: true,
                        message: 'Please select an interval',
                    },
                ]}
            >
                <CronInput
                    enableMinutely={false}
                    enableWeekly={false}
                    enableMonthly={false}
                    enableYearly={false}
                />
            </Form.Item>
            <BackupScheduleInfo
                form={form}
                message={'Your full backup will start'}
            />
        </Form>
    );
}
