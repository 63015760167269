import { Tag, Tooltip } from 'antd';
import CcxComponentProps from '../../../core/CcxComponent';
import styles from './AppFormTag.module.less';

interface Props extends CcxComponentProps {
    label: string;
    value?: string;
    size?: string;
    closable?: boolean;
    toolTip?: boolean;
    onClose?: Function;
}

function AppFormTag({
    label,
    closable,
    toolTip = true,
    size = '',
    onClose,
    testId = 'AppFormTag',
}: Props) {
    const onPreventMouseDown = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
    };

    return toolTip ? (
        <Tooltip title={label} data-testid={`${testId}Tooltip`}>
            <Tag
                className={`${styles.AppFormTag}${size}`}
                onMouseDown={onPreventMouseDown}
                closable={closable}
                onClose={onClose as any}
                data-testid={`${testId}Tag`}
            >
                {label.length <= 10 ? label : `${label.substring(0, 10)}...`}
            </Tag>
        </Tooltip>
    ) : (
        <Tag
            className={`${styles.AppFormTag}${size}`}
            onMouseDown={onPreventMouseDown}
            closable={closable}
            onClose={onClose as any}
            data-testid={`${testId}Tag`}
        >
            {label.length <= 10 ? label : `${label.substring(0, 10)}...`}
        </Tag>
    );
}

export default AppFormTag;
