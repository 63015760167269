export type DatabaseVendorTypeClassProps = {
    code: string;
    name: string;
    size_hints: any;
};

export interface DatabaseVendorTypeInterface {
    code: string;
    name: string;
    sizeHints: any;
    getSizeHintsByNumber: Function;
}

export default class DatabaseVendorType implements DatabaseVendorTypeInterface {
    readonly code: string;
    readonly name: string;
    readonly sizeHints: any;

    constructor(props: DatabaseVendorTypeClassProps) {
        this.code = props.code;
        this.name = props.name;
        this.sizeHints = props.size_hints;
    }

    getSizeHintsByNumber(number: number): string {
        return this.sizeHints ? this.sizeHints[number] || undefined : undefined;
    }

    getSizeHintsName(number: number): string {
        if (this.sizeHints[number]?.name?.includes('master')) {
            return this.sizeHints[number]?.name?.replaceAll(
                'master',
                'primary'
            );
        } else
            return this.sizeHints
                ? this.sizeHints[number]?.name || undefined
                : undefined;
    }

    getSizeHintsInfoByNumber(number: number): string {
        return this.sizeHints
            ? this.sizeHints[number]?.info || undefined
            : undefined;
    }
}
