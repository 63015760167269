import React, { ReactElement, useEffect, useState } from 'react';
import CcxComponentProps from '../../../core/CcxComponent';

import useAdminDataStores from '../../../core/hooks/useAdminDataStores';
import AppTable from '../../AppTable';
import styles from './AdminDataStores.module.less';
import DotLabelStatus from '../../ccx/common/DotLabelStatus';
import Button from 'antd/lib/button';
import AdminNodes from '../nodes/AdminNodes';
import Datastore from '../../../types/Datastore';
import Tooltip from 'antd/lib/tooltip';
import AppLoading from '../../AppLoading';
import CcxContextualMenuIcon from '../../ccx/icons/CcxContextualMenuIcon';
import { Dropdown, Menu, notification } from 'antd';
import AdminService from '../../../services/AdminService';
import CcxIconCloseCircleTwoTone from '../../ccx/icons/CcxIconCloseCircleTwoTone';
import DatastoreDeleteButton from '../../projects/dataStores/DataStoreDeleteButton';
import CopyToClipboardText from '../../CopyToClipboardText';

interface Props extends CcxComponentProps {}

function AdminDataStores({ testId = 'AdminDataStores' }: Props): ReactElement {
    const { dataStores, loading, refresh, updating } = useAdminDataStores();
    const [visible, setVisible] = useState<boolean>(false);
    const [datastore, setDatastore] = useState<Datastore>();

    useEffect(() => {
        const AUTO_REFRESH_INTERVAL = 20000;
        const interval = setInterval(async () => {
            refresh && (await refresh());
        }, AUTO_REFRESH_INTERVAL);

        return () => clearInterval(interval);
    }, []);

    const handleGetNodes = (ds: Datastore) => {
        setVisible(true);
        setDatastore(ds);
    };

    const handleDeleteDatastore = async (ds: Datastore) => {
        try {
            await AdminService.deleteDataStore(ds.id);
        } catch (e: any) {
            console.log(e);
            notification.open({
                message: 'Datastore removing error',
                description: `An error occurred while deleting datastore: ${e}`,
                icon: <CcxIconCloseCircleTwoTone twoToneColor="#eb2f96" />,
            });
        }
    };

    const tableColumns = [
        {
            title: 'Datastore',
            dataIndex: 'id',
            key: 'id',
            render: (text: any, record: any) => {
                return (
                    <>
                        <div className={styles.AdminDataStoresClusterName}>
                            <Tooltip placement="bottom" title={record.name}>
                                {record.name}
                            </Tooltip>
                        </div>
                        <div className={styles.AdminDataStoresClusterId}>
                            <Tooltip
                                title={
                                    record?.status.isStatusOk()
                                        ? 'Click to get nodes'
                                        : null
                                }
                            >
                                <Button
                                    type="link"
                                    disabled={!record?.status.isStatusOk()}
                                    onClick={() => handleGetNodes(record)}
                                >
                                    {record.id}
                                </Button>
                            </Tooltip>
                            <CopyToClipboardText
                                text={record.id}
                                showPreview={false}
                                hoverable={false}
                            />
                        </div>
                    </>
                );
            },
        },
        {
            title: 'User',
            dataIndex: 'userLogin',
            key: 'userLogin',
        },
        {
            title: 'Size',
            dataIndex: 'size',
            key: 'size',
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: 'Internal ID',
            dataIndex: 'internalId',
            key: 'internalId',
        },
        {
            title: 'Created',
            dataIndex: 'createdAt',
            key: 'createdAt',
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (value: string, record: Datastore) => {
                return (
                    // @todo make this component reusable in DataStores.tsx
                    <Dropdown
                        data-testid="TableActions"
                        overlay={
                            <Menu>
                                <Menu.Item key="delete">
                                    <DatastoreDeleteButton
                                        onConfirmDelete={async () => {
                                            await handleDeleteDatastore(record);
                                        }}
                                    />
                                </Menu.Item>
                            </Menu>
                        }
                        placement="bottomRight"
                        trigger={['click']}
                    >
                        <Button icon={<CcxContextualMenuIcon />}></Button>
                    </Dropdown>
                );
            },
        },
    ];

    return loading ? (
        <AppLoading />
    ) : (
        <div className={styles.AdminDataStores}>
            <AppTable
                columns={tableColumns}
                testId={`${testId}Table`}
                data={dataStores || []}
                rowKey="id"
                expandable={false}
                pagination={{
                    hideOnSinglePage: true,
                    pageSize: 50,
                }}
            />

            {visible && datastore && (
                <AdminNodes
                    visible={visible}
                    datastore={datastore}
                    handleClose={() => setVisible(false)}
                />
            )}
        </div>
    );
}

export default AdminDataStores;
