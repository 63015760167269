import React, { ReactElement, useEffect, useState } from 'react';
import CmonDiskStat from '../../../types/CmonDiskStat';
import FriendlyTimestamp from '../../../types/FriendlyTimestamp';
import AppChart from '../../AppChart';
import prettyBytes from 'pretty-bytes';
import CcxComponentProps from '../../../core/CcxComponent';
import ChartCustomTooltip from './ChartCustomTooltip';
import numbro from 'numbro';

interface Props extends CcxComponentProps {
    data?: CmonDiskStat[];
    loading: boolean;
    displaySummary?: boolean;
    height?: number;
    width?: number;
}

function DiskSpaceUsageChart({
    data,
    loading,
    displaySummary,
    testId = 'DiskSpaceUsageChart',
    width,
    height = 250,
}: Props): ReactElement {
    const [chartData, setChartData] = useState<any>([]);
    const [summary, setSummary] = useState([]);

    useEffect(() => {
        if (data && data.length > 0) {
            const formattedData = data.map((stat: CmonDiskStat) => {
                return [
                    {
                        yValue: stat.free,
                        series: `${stat.device} Free`,
                        xValue: new FriendlyTimestamp(stat.created).getDate(),
                    },
                    {
                        yValue: stat.total - stat.free,
                        series: `${stat.device} Used`,
                        xValue: new FriendlyTimestamp(stat.created).getDate(),
                    },
                ];
            });
            setChartData(formattedData.flat());
        }
    }, [data]);

    const chartConfig = {
        title: {
            visible: true,
            text: 'Disk space usage',
        },
        description: {
            visible: true,
            text: '',
        },
        height: height,
        width: width,
        autoFit: !width && !height,
        xField: 'xValue',
        yField: 'yValue',
        xAxis: {
            type: 'time',
            mask: 'HH:mm',
        },
        yAxis: {
            label: {
                formatter: (text: string, item: any, index: number) => {
                    return numbro(parseInt(text, 10)).format({
                        output: 'byte',
                        base: 'binary',
                        spaceSeparated: true,
                        mantissa: 2,
                    });
                },
            },
        },
        animation: false,
        seriesField: 'series',
        tooltip: {
            customContent: (title: any, data: any) => {
                const getTitle = () => {
                    if (!data || data.length === 0) return '';
                    return data[0]?.data?.xValue.toString();
                };

                const getContent = () => {
                    if (!data || data.length === 0) return [];
                    return data.map((d: any) => ({
                        color: d?.color,
                        label: d?.data?.series,
                        value: numbro(
                            parseInt(d?.data?.yValue.toFixed(2), 10)
                        ).format({
                            output: 'byte',
                            base: 'binary',
                            spaceSeparated: true,
                            mantissa: 2,
                        }),
                    }));
                };

                const tooltipData = {
                    title: getTitle(),
                    content: getContent(),
                };

                return (
                    <ChartCustomTooltip
                        title={tooltipData.title}
                        content={tooltipData.content}
                    />
                );
            },
        },
    };

    return (
        <AppChart
            type="area"
            data={chartData}
            config={chartConfig}
            summary={summary}
            loading={loading}
            displaySummary={displaySummary}
            testId={testId}
        />
    );
}

export default DiskSpaceUsageChart;
