import SecretText from '../components/SecretText';
import { normalizeHostPort } from '../core/helpers';

export type RequestProps = {
    target?: string;
    hostPort?: string;
    host?: string;
    port?: string;
    username?: string;
    database?: string;
    password?: string;
    protocol?: string;
    service?: string;
};

export default class DsnService {
    public static getDsn({
        hostPort = '{your_host:your_port}',
        port = '{your_port}',
        username = '{your_username}',
        database = '{your_database_name}',
        password = '{your_password}',
        service = 'mysql',
    }: RequestProps): string {
        let dsn = '';
        switch (service) {
            case 'mssql':
                dsn = `Data Source=${normalizeHostPort(hostPort, port)};User ID=${username};Password=${password}`;
                break;
            case 'postgres':
                dsn = `${service}://${username}:${password}@${normalizeHostPort(hostPort, port)}/${database}`;
                break;
            default:
                dsn = `${service}://${username}:${password}@${hostPort}/${database}`;
        }
        return dsn;
    }

    public static getDsnUsageExample({
        target = 'example',
        hostPort = '{your_host:your_port}',
        host = '{your_host}',
        port = '{your_port}',
        username = '{your_username}',
        database = '{your_database_name}',
        password = '{your_password}',
        protocol = 'tcp',
        service = 'mysql',
    }: RequestProps): any {
        let dsnForCopy: string[] = [];
        let dsnForDisplay: any[];
        const passwordReplace :string = '{your_password}'

        const getPasswordForDisplay = () => {
            return username === 'ccxadmin' ? (
                <SecretText
                    visibleText={`_CLICK_TO_REVEAL_PASSWORD_`}
                    hiddenText={`${password}`}
                    tooltipText="Click to show / hide password"
                    displayInline
                />
            ) : (
                <>{`{REPLACE_WITH_YOUR_PASSWORD}`}</>
            );
        };

        const getPasswordForCopy = () => {
            return username === 'ccxadmin'
                ? password
                : '{REPLACE_WITH_YOUR_PASSWORD}';
        };

        const connectionCodeCopy = (connectionCode: string[])  => {
            return connectionCode.map((line: string) => {
                        return line.replace(passwordReplace, getPasswordForCopy);
            });

        }
        const connectionCodeDisplay = (
           dsnForCopy: string[],
        ) => {
            return [
                <>
                    {dsnForCopy.map((line: string) => {
                        const i = line.indexOf(passwordReplace)
                        return (
                            <>
                                { i === -1 ?
                                    <>
                                        {line}
                                    </> :
                                    <>
                                        <>{line.substring(0, i)}</>
                                        <>{getPasswordForDisplay()}</>
                                        <>{line.substring(i+passwordReplace.length)}</>
                                    </>
                                }
                                <br />
                            </>
                        );
                    })}
                </>,
            ];
        };
        let connectionString :string[] = [];
        switch (target) {
            case 'ado.net':
                connectionString = [
                    `Server=${host};`,
                    `Port=${port};`,
                    `Database=${database};`,
                    `Uid=${username};`,
                    `Pwd=${passwordReplace};`,
                    `SslMode=Required;`,
                ];
                break;
            case 'jdbc':
                connectionString = [
                    `String url = "jdbc:${service}://%s:%s/%s?verifyServerCertificate=true&useSSL=true&requireSSL=true", ${host}, ${port}, ${database}";`,
                    `myDbConn = DriverManager.getConnection(url, ${username}, ${passwordReplace}";`,
                ];
                break;
            case 'node.js':
                switch (service) {
                    case 'mysql':
                        connectionString = [
                            `var conn = mysql.createConnection({host: ${host}, user: ${username}, password: ${passwordReplace}, database: ${database}, Port: ${port}, ssl: {ca:fs.readFileSync({ca-cert filename})}});`,
                        ];
                        break;
                    case 'postgres':
                       connectionString = [
                           `const pg = require('pg');`,
                           ``,
                           `var conn = pg.Client({host: ${host}, user: ${username}, password: ${passwordReplace}, database: ${database}, port: ${port}, ssl: true);`,
                       ]
                        break;
                    case 'redis':
                        connectionString = [
                            `const { createClient } = require('redis');`,
                            ``,
                            `const client = createClient({`,
                            `    host: '${host}',`,
                            `    port:  ${port},`,
                            `    user: '${username}',`,
                            `    password: '${passwordReplace}',`,
                            `    db: ${database}`,
                            `});`,
                        ]
                        break;
                }
                break;
            case 'odbc':
                connectionString =
                    service === 'mysql'
                        ? [
                              `DRIVER={MySQL ODBC 5.3 UNICODE Driver};`,
                              `Server=${host};`,
                              `Port=${port};`,
                              `Database=${database};`,
                              `Uid=${username};`,
                              `Pwd=${passwordReplace};`,
                              `sslca={ca-cert filename};`,
                              `sslverify=1;`,
                              `Option=3;`,
                          ]
                        : [
                              `DRIVER={PostgreSQL};`,
                              `Server=${host};`,
                              `Port=${port};`,
                              `Database=${database};`,
                              `Uid=${username};`,
                              `Pwd=${passwordReplace};`,
                              `sslca={ca-cert filename};`,
                              `sslverify=1;`,
                              `Option=3;`,
                          ];
                break;
            case 'php':
                switch (service) {
                    case 'mysql':
                        connectionString = [
                                    `<?php`,
                                    ``,
                                    `$con = mysqli_init();`,
                                    `mysqli_ssl_set($con, NULL, NULL, {ca-cert filename}, NULL, NULL);`,
                                    `mysqli_real_connect($con, ${host}, ${username}, ${passwordReplace}, ${database}, ${port});`,
                                ]
                        break;
                    case 'postgres':
                        connectionString = [
                                    `<?php`,
                                    ``,
                                    `$connection = pg_connect("host='${host}' dbname='${database}' user='${username}' password='${passwordReplace}'")`,
                                    `  or die("Failed to create connection to database: ". pg_last_error());`,
                                ];
                        break;
                    case 'redis':
                        connectionString = [
                            `<?php`,
                            ``,
                            `$redis = new Redis();`,
                            `$redis->connect('${host}', ${port});`,
                            `$redis->auth('${username},${passwordReplace}');`,
                            `$redis->select(${database});`,
                        ]
                        break;
                }
                break;
            case 'php.pdo':
                connectionString = [
                    `<?php`,
                    ``,
                    `$dsn = '${service}://${username}:${passwordReplace}@${hostPort}/${database}'`,
                    ``,
                    `$dbh = new PDO($dsn, $username, $password);`,
                ];
                break;
            case 'php.pear.db':
                connectionString = [
                    `<?php`,
                    ``,
                    `require_once('DB.php');`,
                    ``,
                    `$db = DB::connect('${service}://${username}:${passwordReplace}@${hostPort}/${database}');`,
                ];
                break;
            case 'python':
                switch (service) {
                    case 'mysql':
                        connectionString = [
                                    `cnx = ${service}.connector.connect(user=${username}, password=${passwordReplace}, host=${host}, port=${port}, database=${database}, ssl_ca={ca-cert filename}, ssl_verify_cert=true)`,
                                ]
                        break;
                    case 'postgres':
                        connectionString = [
                                    `import psycopg2`,
                                    ``,
                                    `host = "${host}:${port}"`,
                                    `dbname = "${database}"`,
                                    `user = "${username}"`,
                                    `password = "${passwordReplace}"`,
                                    `sslmode = "require"`,
                                    ``,
                                    `conn_string = "host={0} user={1} dbname={2} password={3} sslmode={4}".format(host, user, dbname, password, sslmode)`,
                                    `conn = psycopg2.connect(conn_string)`,
                                ];
                        break;
                    case 'redis':
                        connectionString = [
                            `import redis`,
                            ``,
                            `r = redis.StrictRedis(`,
                            `    host='${host}',`,
                            `    port=${port},`,
                            `    user='${username}',`,
                            `    password='${passwordReplace}',`,
                            `    db=${database}`,
                            `)`,
                        ]
                        break;
                }
                break;
            case 'ruby':
                switch (service) {
                    case 'mysql':
                        connectionString = [
                                    `client = Mysql2::Client.new(username: ${username}, password: ${passwordReplace}, database: ${database}, host: ${host}, port: ${port}, sslca:{ca-cert filename}, sslverify:false, sslcipher:'AES256-SHA')`,
                                ]
                        break;
                    case 'postgres':
                        connectionString = [
                                    `require 'pg'`,
                                    ``,
                                    `begin`,
                                    `  connection = PG::Connection.new(:host => '${host}', :user => '${username}', :dbname => '${database}', :port => '${port}', :password => '${passwordReplace}')`,
                                ];
                        break;
                    case 'redis':
                        connectionString = [
                            `require 'redis'`,
                            ``,
                            `redis = Redis.new(:host => '${host}', :port => ${port}, :user => '${username}', :password => '${passwordReplace}', :db => ${database})`,
                        ]
                        break;
                }
                break;
            case 'go':
                switch (service) {
                    case 'mysql':
                        connectionString = [
                            `import (`,
                            `  "database/sql"`,
                            `  _ "github.com/go-sql-driver/mysql"`,
                            `)`,
                            ``,
                            `db, err := sql.Open("mysql", "${username}:${passwordReplace}@${hostPort}/${database}")`,
                        ];
                        break;
                    case 'postgres':
                        connectionString = [
                            `import (`,
                            `  "database/sql"`,
                            `  "fmt"`,
                            `  _ "github.com/lib/pq"`,
                            `)`,
                            ``,
                            `var connectionString string = fmt.Sprintf("host=%s user=%s password=%s dbname=%s sslmode=require", "${host}", "${username}", "${passwordReplace}", "${database}")`,
                            ``,
                            `db, err := sql.Open("postgres", connectionString)`,
                        ]
                        break;
                    case 'redis':
                        connectionString = [
                            `import (`,
                            `  "github.com/go-redis/redis/v8"`,
                            `)`,
                            ``,
                            `rdb := redis.NewClient(&redis.Options{`,
                            `  Addr:     "${host}:${port}",`,
                            `  Username: "${username}",`,
                            `  Password: "${passwordReplace}",`,
                            `  DB:       ${database},`,
                            `})`,
                        ]
                        break;
                }

                break;
            case 'dsn':
                connectionString = [
                    this.getDsn({
                        hostPort,
                        port,
                        username,
                        database,
                        password: passwordReplace,
                        service,
                    }),
                ];
                break;
        }
        dsnForCopy = connectionCodeCopy(connectionString);
        dsnForDisplay = connectionCodeDisplay(connectionString);

        return { dsnForCopy, dsnForDisplay };
    }
}
