import React, { ReactElement } from 'react';
import { Select, Space } from 'antd';
import AppLabeledComponent from '../ccx/common/AppLabeledComponent';
import styles from './VpcListOptions.module.less';
import CcxComponentProps from '../../core/CcxComponent';

interface VpcListOptionsProps extends CcxComponentProps {
    cloud?: string;
    clouds?: any[];
    region?: string;
    regions?: any[];
    onCloudChange?: Function;
    onRegionChange?: Function;
    disabled?: boolean;
}

function VpcListOptions({
    cloud,
    clouds,
    region,
    regions,
    onCloudChange,
    onRegionChange,
    disabled,
    testId = 'VpcListOptions',
}: VpcListOptionsProps): ReactElement {
    const { Option } = Select;

    const handleCloudChange = (value: any) => {
        onCloudChange && onCloudChange(value);
    };

    const handleRegionChange = (value: string) => {
        onRegionChange && onRegionChange(value);
    };

    return (
        <Space data-testid={`${testId}Space`}>
            <AppLabeledComponent label="Cloud provider:">
                <Select
                    onChange={handleCloudChange}
                    disabled={disabled}
                    value={cloud}
                    placeholder="Select cloud provider"
                    data-testid={`${testId}CloudProviderSelect`}
                >
                    {clouds?.map((item: any, index: number) => {
                        return (
                            <Option
                                value={item.value}
                                key={`${testId}CloudProvider${index}`}
                                data-testid={`${testId}CloudProviderOption${index}`}
                            >
                                {item.label}
                            </Option>
                        );
                    })}
                </Select>
            </AppLabeledComponent>
            <AppLabeledComponent label="Available regions:">
                <Select<string>
                    onChange={handleRegionChange}
                    disabled={disabled}
                    value={region}
                    placeholder="Select region"
                    data-testid={`${testId}RegionSelect`}
                >
                    {regions?.map((item: any, index: number) => {
                        return (
                            <Option
                                value={item.value}
                                key={`${testId}Region${index}`}
                                data-testid={`${testId}RegionOption${index}`}
                            >
                                {item.label}
                            </Option>
                        );
                    })}
                </Select>
            </AppLabeledComponent>
        </Space>
    );
}

export default VpcListOptions;
