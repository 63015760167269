import React, { ReactElement, useEffect, useState } from 'react';
import styles from './AddCard.module.less';
import {
    CardCvcElement,
    CardExpiryElement,
    CardNumberElement,
    useElements,
    useStripe,
} from '@stripe/react-stripe-js';
import ButtonModalForm from '../ButtonModalForm';
import BillingService from '../../services/BillingService';
import { message, notification } from 'antd';
import CcxIconCloseCircleTwoTone from '../ccx/icons/CcxIconCloseCircleTwoTone';
import CcxIconCheckCircleTwoTone from '../ccx/icons/CcxIconCheckCircleTwoTone';
import { Form } from 'antd';
import AppForm from '../ccx/common/AppForm';
import { Button } from 'antd';
import CcxComponentProps from '../../core/CcxComponent';
import CountryService from '../../services/CountryService';
import Country from '../../types/Country';
import AppFlagIcon from '../ccx/common/AppFlagIcon';
import BillingForm from '../deployments/services/BillingForm';
import Address from '../../types/Address';
import BillingUserData from '../../types/BillingUserData';
import { useAppSelector } from '../../redux/hooks';

interface Props extends CcxComponentProps {
    onSuccess: Function;
    buttonType?: string;
    drawer?: boolean;
    submitText?: string;
    setCardToken?: Function;
    submitDisabled?: boolean;
}

function AddCard({
    onSuccess,
    buttonType,
    drawer = true,
    submitText = 'Add',
    setCardToken,
    submitDisabled,
    testId = 'AddCard',
}: Props): ReactElement {
    const [loading, setLoading] = useState(false);
    const stripe = useStripe();
    const elements = useElements();
    const [errorFields, setErrorFields] = useState<any>([]);
    const [form] = Form.useForm();

    const { user, subscription } = useAppSelector((state) => state.user);

    const cardElementOptions = {
        style: {
            base: {
                backgroundColor: '#fff',
                padding: '4px 11px',
                fontSize: '14px',
                lineHeight: '1.5715',
                fontSmoothing: 'antialiased',
                color: 'rgba(0, 0, 0, 0.65)',
                '::placeholder': {
                    color: 'rgba(0, 0, 0, 0.25)',
                },
                iconColor: 'rgba(0, 0, 0, 0.65)',
                fontFamily:
                    '-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji',
                fontVariant: 'tabular-nums',
            },
            invalid: {
                color: '#f5222d',
                iconColor: '#f5222d',
            },
            complete: {},
        },
        hidePostalCode: false,
    };

    const fields = [
        {
            name: ['cardNumber'],
            testId: 'AddCardCardNumber',
            required: true,
            label: 'Card number',
            type: 'rawElement',
            rawElement: (
                <div
                    className={styles.CheckoutCardElementContainer}
                    data-testid="AddCardCardElementContainer"
                >
                    <CardNumberElement options={cardElementOptions} />
                </div>
            ),
        },
        {
            name: ['cardExpiry'],
            testId: 'AddCardCardExpiry',
            required: true,
            label: 'Expiration date',
            type: 'rawElement',
            rawElement: (
                <div
                    className={styles.CheckoutCardElementContainer}
                    data-testid="AddCardCardElementContainer"
                >
                    <CardExpiryElement options={cardElementOptions} />
                </div>
            ),
        },
        {
            name: ['cardCvc'],
            testId: 'AddCardCardCvc',
            required: true,
            label: 'CVC/CVV',
            type: 'rawElement',
            rawElement: (
                <div
                    className={styles.CheckoutCardElementContainer}
                    data-testid="AddCardCardElementContainer"
                >
                    <CardCvcElement options={cardElementOptions} />
                </div>
            ),
        },
    ];

    const vatFieldsSetup = [
        {
            name: ['companyName'],
            required: false,
            label: 'Company name',
            testId: `${testId}CompanyName`,
            placeholder: 'Time travel agency Ltd.',
            type: 'input',
        },
        {
            name: ['vat'],
            required: false,
            label: 'EU VAT number',
            placeholder: 'RO U25775505',
            testId: `${testId}Vat`,
            type: 'input',
            tips: (
                <div>Only for countries in the EU for reverse charge rule</div>
            ),
        },
    ];

    const fieldsSetup = [
        {
            name: ['firstName'],
            required: true,
            label: 'First name',
            placeholder: 'Jane',
            testId: `${testId}FirstName`,
            type: 'input',
            onChange: async (v: any) => {
                setErrorFields([]);
            },
        },
        {
            name: ['city'],
            required: false,
            label: 'City',
            testId: `${testId}City`,
            placeholder: 'Bucharest',
            type: 'input',
        },
        {
            name: ['lastName'],
            required: true,
            label: 'Last name',
            placeholder: 'Doe',
            testId: `${testId}LastName`,
            type: 'input',
            onChange: async (v: any) => {
                setErrorFields([]);
            },
        },
        {
            name: ['state'],
            required: false,
            label: 'State',
            testId: `${testId}State`,
            placeholder: 'Stockholm',
            type: 'input',
        },
        {
            name: ['email'],
            required: false,
            label: 'Email',
            testId: `${testId}Email`,
            placeholder: 'Enter your email',
            type: 'input',
            disabled: true,
            defaultValue: user?.login,
        },
        {
            name: ['postalCode'],
            required: false,
            label: 'Postal code',
            testId: `${testId}PostalCode`,
            placeholder: '021568',
            type: 'input',
        },

        {
            name: ['line1'],
            required: true,
            label: 'Billing address 1',
            testId: `${testId}AddressLine1`,
            placeholder: 'STR. ORZARI nr. 5 bl. 46bis et 2 ap. 5',
            type: 'input',
            onChange: async (v: any) => {
                setErrorFields([]);
            },
        },
        {
            name: ['country'],
            required: true,
            label: 'Country',
            testId: `${testId}Country`,
            placeholder: 'Spain',
            type: 'select',
            options: [],
            onChange: async (v: any, o: any) => {
                setErrorFields([]);
                await form.setFieldsValue({ country: v });
            },
        },
        {
            name: ['line2'],
            required: false,
            label: 'Billing address 2',
            testId: `${testId}AddressLine2`,
            placeholder: 'DISTRICT 2',
            type: 'input',
        },
    ];

    const [billingFieldsSetup, setBillingFieldsSetup] =
        useState<any>(fieldsSetup);

    const [countries, setCountries] = useState<any>([]);

    const [cardForm, setCardForm] = useState<any>(undefined);

    const [billingForm] = Form.useForm();

    useEffect(() => {
        const load = async () => {
            const response = await CountryService.listCountries();
            setCountries(response);
        };

        load();
    }, []);

    useEffect(() => {
        if (fieldsSetup && countries && countries.length > 0) {
            const newStep1 = fieldsSetup.map((f: any) => {
                if (f.name && f.name[0] === 'country') {
                    f.options = countries.map((c: Country) => {
                        return {
                            value: c.code,
                            label: c.name,
                            content: (
                                <div data-testid={`${testId}${c.code}`}>
                                    <AppFlagIcon code={c.code.toLowerCase()} />
                                    {c.name}
                                </div>
                            ),
                        };
                    });
                }
                return f;
            });
            const newFields = newStep1;
            setBillingFieldsSetup(newFields);
        }
    }, [countries]);

    const validateForm = async () => {
        if (!subscription?.valid) {
            const stepValid = await validateBillingForm();

            if (!stepValid) {
                return false;
            }
        }

        if (stripe === null) {
            throw new Error(`BUG: stripe is null`);
        }

        if (elements === null) {
            throw new Error(`BUG: stripe elements is null`);
        }

        const cardNumber = elements.getElement(CardNumberElement);
        const cardExpiry = elements.getElement(CardExpiryElement);
        const cardCvc = elements.getElement(CardCvcElement);

        if (cardNumber === null) {
            throw new Error(`BUG: stripe elements cardNumberElement is null`);
        }
        if (cardExpiry === null) {
            throw new Error(`BUG: stripe elements cardExpiryElement is null`);
        }
        if (cardCvc === null) {
            throw new Error(`BUG: stripe elements cardCvcElement is null`);
        }

        return true;
    };

    let cardToken: any = null;

    const onSubmit = async () => {
        if (stripe === null) {
            throw new Error(`BUG: stripe is null`);
        }

        if (elements === null) {
            throw new Error(`BUG: stripe elements is null`);
        }

        const cardNumber = elements.getElement(CardNumberElement);
        const cardExpiry = elements.getElement(CardExpiryElement);
        const cardCvc = elements.getElement(CardCvcElement);

        if (cardNumber === null) {
            throw new Error(`BUG: stripe elements cardNumberElement is null`);
        }
        if (cardExpiry === null) {
            throw new Error(`BUG: stripe elements cardExpiryElement is null`);
        }
        if (cardCvc === null) {
            throw new Error(`BUG: stripe elements cardCvcElement is null`);
        }

        setLoading(true);
        setErrorFields([]);

        try {
            const { error, token } = await stripe.createToken(cardNumber);

            if (error) {
                throw error; // return error to catch block
            }

            cardToken = token;

            if (setCardToken) {
                setCardToken(token);
                return;
            }
        } catch (e: any) {
            setErrorFields([
                {
                    errors: [e.message],
                    name: ['cardInformation'],
                },
            ]);

            message.error(e.message);
            setLoading(false);

            throw e;
        }

        if (setCardToken || subscription?.valid) {
            addCard();
        }
    };

    const addCard = async () => {
        try {
            await BillingService.addCard(cardToken?.id);

            notification.open({
                message: 'Add Card',
                description: `New Card successfully added!`,
                icon: <CcxIconCheckCircleTwoTone twoToneColor="#52c41a" />,
            });

            setLoading(false);

            onSuccess && onSuccess();
        } catch (e) {
            notification.open({
                message: 'Add Card',
                description: `There was an error adding your Card. ${e}`,
                icon: <CcxIconCloseCircleTwoTone twoToneColor="#eb2f96" />,
            });

            console.error(e);
            setLoading(false);

            throw e;
        }
    };

    const onSubmitButtonClick = async () => {
        const stepValid = await validateForm();

        if (stepValid) {
            setLoading(true);
            try {
                await onSubmit();
                form.resetFields();
                onSuccess && onSuccess();
            } catch (e) {
                console.log('error e=', e);
            }
            setLoading(false);
        }
    };

    const validateBillingForm = async () => {
        try {
            const a = await billingForm.validateFields();
            return true;
        } catch (e: any) {
            setErrorFields(e.errorFields);
            message.error(
                'One or more fields have errors. Please double check and try again.'
            );
            return false;
        }
    };

    const handleCreateUser = async (billingForm: any) => {
        try {
            const data = {
                address: new Address({
                    country: billingForm.getFieldValue('country'),
                    state: billingForm.getFieldValue('state'),
                    city: billingForm.getFieldValue('city'),
                    line_1: billingForm.getFieldValue('line1'),
                    line_2: billingForm.getFieldValue('line2'),
                    postal_code: billingForm.getFieldValue('postalCode'),
                }),
                coupon: form.getFieldValue('coupon'),
                userData: new BillingUserData({
                    company_name: billingForm.getFieldValue('companyName'),
                    eu_vat_id: billingForm.getFieldValue('vat'),
                    first_name: billingForm.getFieldValue('firstName'),
                    last_name: billingForm.getFieldValue('lastName'),
                }),
            };

            await BillingService.createBillingUser(data);

            notification.open({
                message: 'Add billing user',
                description: 'User added successfully.',
                icon: <CcxIconCheckCircleTwoTone twoToneColor="#52c41a" />,
            });
            return true;
        } catch (e: any) {
            notification.open({
                message: 'Add billing user',
                description: `There was an error adding billing user. ${e}`,
                icon: <CcxIconCloseCircleTwoTone twoToneColor="#eb2f96" />,
            });
            return false;
        }
    };

    const handleSetupIntent = async (cardToken: any) => {
        try {
            let intent = await BillingService.createBillingIntent(cardToken);

            notification.open({
                message: 'Add subscription intent',
                description: 'Intent added successfully.',
                icon: <CcxIconCheckCircleTwoTone twoToneColor="#52c41a" />,
            });

            await createUserSubscription(billingForm, intent.paymentMethodID);
        } catch (e: any) {
            notification.open({
                message: 'Add subscription intent',
                description: `There was an error adding subscription intent. ${e}`,
                icon: <CcxIconCloseCircleTwoTone twoToneColor="#eb2f96" />,
            });
        }
    };

    const createUserSubscription = async (
        billingForm: any,
        paymentMethod: any
    ) => {
        const data = {
            address: new Address({
                country: billingForm.getFieldValue('country'),
                state: billingForm.getFieldValue('state'),
                city: billingForm.getFieldValue('city'),
                line_1: billingForm.getFieldValue('line1'),
                line_2: billingForm.getFieldValue('line2'),
                postal_code: billingForm.getFieldValue('postalCode'),
            }),
            paymentMethod: paymentMethod,
            coupon: form.getFieldValue('coupon'),
            userData: new BillingUserData({
                company_name: billingForm.getFieldValue('companyName'),
                eu_vat_id: billingForm.getFieldValue('vat'),
                first_name: billingForm.getFieldValue('firstName'),
                last_name: billingForm.getFieldValue('lastName'),
            }),
        };

        await BillingService.createUserSubscription(data);
    };

    const onSubmitCreateUser = async () => {
        setLoading(true);
        try {
            let subscriptionUser = await handleCreateUser(billingForm);

            if (subscriptionUser) {
                await onSubmit();
            }

            if (cardToken) {
                await handleSetupIntent(cardToken.id);
            }
        } catch (e) {
            console.log('error e=', e);
        }
        setLoading(false);
    };

    return drawer ? (
        <ButtonModalForm
            title="Create new credit/debit card"
            buttonText="Add new Card"
            onSubmit={subscription?.valid ? onSubmit : onSubmitCreateUser}
            onSuccess={onSuccess}
            fields={fields}
            testId="AddCard"
            formLayout="vertical"
            overideValidationFunction={validateForm}
            buttonType={buttonType}
            submitText="Create"
            cancelText="Back"
            formClassName={styles.AddCardFormFields}
            rightCol={4}
            leftCol={5}
            onFormLoad={setCardForm}
        >
            <span>
                {!subscription?.valid && (
                    <>
                        <div className={styles.AddCardBilling}>
                            <strong>Enter your billing information</strong>
                            <BillingForm
                                fields={billingFieldsSetup}
                                form={billingForm}
                                errorFields={errorFields}
                            />
                        </div>
                        <div className={styles.AddCardBilling}>
                            <strong>Enter your invoice information</strong>
                            <BillingForm
                                fields={vatFieldsSetup}
                                form={billingForm}
                                errorFields={errorFields}
                            />
                        </div>
                    </>
                )}
                <strong>Enter your card credentials</strong>
                <br />
                <br />
            </span>
        </ButtonModalForm>
    ) : (
        <Form
            layout="vertical"
            form={form}
            scrollToFirstError={true}
            className={styles.AddCard}
        >
            <AppForm
                formClassName={styles.AddCardFormFields}
                fields={fields}
                onPressEnter={onSubmitButtonClick}
                errorFields={errorFields}
            />
            <Button onClick={onSubmitButtonClick} disabled={submitDisabled}>
                {submitText}
            </Button>
        </Form>
    );
}

export default AddCard;
