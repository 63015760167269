import React from 'react';
import prettyBytes from 'pretty-bytes';
import styles from './AppBytes.module.less';
import CcxComponentProps from '../core/CcxComponent';

interface Props extends CcxComponentProps {
    children: number;
}

function AppBytes({ children, className, testId }: Props) {
    return children ? (
        <span
            className={`${styles.AppBytes} ${className}`}
            data-testid={testId || 'AppBytes'}
        >
            {prettyBytes(children)}
        </span>
    ) : null;
}

export default AppBytes;
