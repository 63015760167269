import React, { ReactElement, useEffect, useState } from 'react';
import styles from './RedisDashboard.module.less';
import { Col, Row, Spin } from 'antd';
import usePrometheusRedisCommandsPerSecStats from '../../../core/hooks/usePrometheusRedisCommandsPerSecStats';
import usePrometheusRedisTotalKeysStats from '../../../core/hooks/usePrometheusRedisTotalKeysStats';
import usePrometheusRedisConnectedClientsStats from '../../../core/hooks/usePrometheusRedisConnectedClientsStats';
import usePrometheusRedisBlockedClientsStats from '../../../core/hooks/usePrometheusRedisBlockedClientsStats';
import usePrometheusRedisMemoryUsedStats from '../../../core/hooks/usePrometheusRedisMemoryUsedStats';
import usePrometheusRedisSlaveLagStats from '../../../core/hooks/usePrometheusRedisSlaveLagStats';
import PrometheusRedisBlockedClientsChart from '../../ccx/charts/PrometheusRedisBlockedClientsChart';
import PrometheusRedisMemoryUsedChart from '../../ccx/charts/PrometheusRedisMemoryUsedChart';
import PrometheusRedisConnectedClientsChart from '../../ccx/charts/PrometheusRedisConnectedClientsChart';
import PrometheusRedisCommandsPerSecChart from '../../ccx/charts/PrometheusRedisCommandsPerSecChart';
import PrometheusRedisTotalKeysChart from '../../ccx/charts/PrometheusRedisTotalKeysChart';
import PrometheusRedisSlaveLagChart from '../../ccx/charts/PrometheusRedisSlaveLagChart';
import CcxComponentProps from '../../../core/CcxComponent';
import CloudInstance from "../../../types/CloudInstance";

interface Props extends CcxComponentProps {
    uuid: string;
    host_uuid: string;
    port: number;
    from?: string;
    to?: string;
    interval: number;
    node?: CloudInstance;
    hidden?: boolean;
    displaySummary?: boolean;
    onLoading?: Function;
}

function RedisDashboard({
    uuid,
    host_uuid,
    port,
    from,
    to,
    interval,
    node,
    hidden,
    displaySummary,
    onLoading,
    testId = 'RedisDashboard',
}: Props): ReactElement {
    const {
        stats: commandsPerSecStats,
        refresh: commandsPerSecStatsRefresh,
        loading: commandsPerSecStatsLoading,
    } = usePrometheusRedisCommandsPerSecStats(uuid, host_uuid, from, to);

    const {
        stats: totalKeysStats,
        refresh: totalKeysStatsRefresh,
        loading: totalKeysStatsLoading,
    } = usePrometheusRedisTotalKeysStats(uuid, host_uuid, from, to);

    const {
        stats: connectedClientsStats,
        refresh: connectedClientsStatsRefresh,
        loading: connectedClientsStatsLoading,
    } = usePrometheusRedisConnectedClientsStats(uuid, host_uuid, from, to);

    const {
        stats: blockedClientsStats,
        refresh: blockedClientsStatsRefresh,
        loading: blockedClientsStatsLoading,
    } = usePrometheusRedisBlockedClientsStats(uuid, host_uuid, from, to);

    const {
        stats: memoryUsedStats,
        refresh: memoryUsedStatsRefresh,
        loading: memoryUsedStatsLoading,
    } = usePrometheusRedisMemoryUsedStats(uuid, host_uuid, from, to);

    const {
        stats: slaveLagStats,
        refresh: slaveLagStatsRefresh,
        loading: slaveLagStatsLoading,
    } = usePrometheusRedisSlaveLagStats(uuid, host_uuid, from, to);

    const [commandsPerSecStatsInterval, setCommandsPerSecStatsInterval] =
        useState<number | undefined>(undefined);
    const [totalKeysStatsInterval, setTotalKeysStatsInterval] = useState<
        number | undefined
    >(undefined);
    const [connectedClientsStatsInterval, setConnectedClientsStatsInterval] =
        useState<number | undefined>(undefined);
    const [blockedClientsStatsInterval, setBlockedClientsStatsInterval] =
        useState<number | undefined>(undefined);
    const [memoryUsedStatsInterval, setMemoryUsedStatsInterval] = useState<
        number | undefined
    >(undefined);
    const [slaveLagStatsInterval, setSlaveLagStatsInterval] = useState<
        number | undefined
    >(undefined);

    const [refreshInterval, setRefreshInterval] = useState(interval);

    useEffect(() => {
        let interval: any;
        if (commandsPerSecStats && refreshInterval > 0) {
            interval = setInterval(() => {
                commandsPerSecStatsRefresh();
            }, refreshInterval);
            setCommandsPerSecStatsInterval(interval);
        }
        return () => clearInterval(interval);
    }, [commandsPerSecStats, refreshInterval]);

    useEffect(() => {
        let interval: any;
        if (totalKeysStats && refreshInterval > 0) {
            interval = setInterval(() => {
                totalKeysStatsRefresh();
            }, refreshInterval);
            setTotalKeysStatsInterval(interval);
        }
        return () => clearInterval(interval);
    }, [totalKeysStats, refreshInterval]);

    useEffect(() => {
        let interval: any;
        if (connectedClientsStats && refreshInterval > 0) {
            interval = setInterval(() => {
                connectedClientsStatsRefresh();
            }, refreshInterval);
            setConnectedClientsStatsInterval(interval);
        }
        return () => clearInterval(interval);
    }, [connectedClientsStats, refreshInterval]);

    useEffect(() => {
        let interval: any;
        if (blockedClientsStats && refreshInterval > 0) {
            interval = setInterval(() => {
                blockedClientsStatsRefresh();
            }, refreshInterval);
            setBlockedClientsStatsInterval(interval);
        }
        return () => clearInterval(interval);
    }, [blockedClientsStats, refreshInterval]);

    useEffect(() => {
        let interval: any;
        if (memoryUsedStats && refreshInterval > 0) {
            interval = setInterval(() => {
                memoryUsedStatsRefresh();
            }, refreshInterval);
            setMemoryUsedStatsInterval(interval);
        }
        return () => clearInterval(interval);
    }, [memoryUsedStats, refreshInterval]);

    useEffect(() => {
        let interval: any;
        if (slaveLagStats && refreshInterval > 0) {
            interval = setInterval(() => {
                slaveLagStatsRefresh();
            }, refreshInterval);
            setSlaveLagStatsInterval(interval);
        }
        return () => clearInterval(interval);
    }, [slaveLagStats, refreshInterval]);

    useEffect(() => {
        if (interval === 0) {
            // when interval is 0, we must pause the refresh (clear existing intervals)
            clearInterval(commandsPerSecStatsInterval);
            clearInterval(totalKeysStatsInterval);
            clearInterval(connectedClientsStatsInterval);
            clearInterval(blockedClientsStatsInterval);
            clearInterval(memoryUsedStatsInterval);
            clearInterval(slaveLagStatsInterval);
            setCommandsPerSecStatsInterval(0);
            setTotalKeysStatsInterval(0);
            setConnectedClientsStatsInterval(0);
            setBlockedClientsStatsInterval(0);
            setMemoryUsedStatsInterval(0);
            setSlaveLagStatsInterval(0);
        }
        setRefreshInterval(interval);
    }, [interval]);

    useEffect(() => {
        if (hidden) {
            // clear interval to stop updating data if the tab is not active (charts are not visible)
            clearInterval(commandsPerSecStatsInterval);
            clearInterval(totalKeysStatsInterval);
            clearInterval(connectedClientsStatsInterval);
            clearInterval(blockedClientsStatsInterval);
            clearInterval(memoryUsedStatsInterval);
            clearInterval(slaveLagStatsInterval);
            setCommandsPerSecStatsInterval(0);
            setTotalKeysStatsInterval(0);
            setConnectedClientsStatsInterval(0);
            setBlockedClientsStatsInterval(0);
            setMemoryUsedStatsInterval(0);
            setSlaveLagStatsInterval(0);
        } else if (
            commandsPerSecStatsInterval === 0 ||
            totalKeysStatsInterval === 0 ||
            connectedClientsStatsInterval === 0 ||
            blockedClientsStatsInterval === 0 ||
            memoryUsedStatsInterval === 0 ||
            slaveLagStatsInterval === 0
        ) {
            // force refresh if the interval is 0, otherwise, the interval will refresh data soon
            // interval is undefined on the first load
            // interval will be 0 when paused or tab is hidden
            commandsPerSecStatsRefresh();
            totalKeysStatsRefresh();
            connectedClientsStatsRefresh();
            blockedClientsStatsRefresh();
            memoryUsedStatsRefresh();
            slaveLagStatsRefresh();
        }
    }, [hidden]);

    useEffect(() => {
        if (
            commandsPerSecStatsLoading ||
            totalKeysStatsLoading ||
            connectedClientsStatsLoading ||
            blockedClientsStatsLoading ||
            memoryUsedStatsLoading ||
            slaveLagStatsLoading
        ) {
            onLoading && onLoading(true);
        } else {
            onLoading && onLoading(false);
        }
    }, [
        commandsPerSecStatsLoading,
        totalKeysStatsLoading,
        connectedClientsStatsLoading,
        blockedClientsStatsLoading,
        memoryUsedStatsLoading,
        slaveLagStatsLoading,
    ]);

    return (
        <section className={styles.RedisDashboard}>
            <Row
                gutter={[
                    32,
                    { xs: 8, sm: 16, md: 24, lg: 32, xl: 40, xxl: 48 },
                ]}
                className={styles.RedisDashboardRow}
            >
                <Col
                    className={styles.RedisDashboardCol}
                    xs={24}
                    sm={24}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                >
                    <PrometheusRedisBlockedClientsChart
                        data={blockedClientsStats}
                        loading={blockedClientsStatsLoading}
                        displaySummary={displaySummary}
                    />
                </Col>
                <Col
                    className={styles.RedisDashboardCol}
                    xs={24}
                    sm={24}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                >
                    <PrometheusRedisMemoryUsedChart
                        data={memoryUsedStats}
                        loading={memoryUsedStatsLoading}
                        displaySummary={displaySummary}
                    />
                </Col>
                <Col
                    className={styles.RedisDashboardCol}
                    xs={24}
                    sm={24}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                >
                    <PrometheusRedisConnectedClientsChart
                        data={connectedClientsStats}
                        loading={connectedClientsStatsLoading}
                        displaySummary={displaySummary}
                    />
                </Col>
                <Col
                    className={styles.RedisDashboardCol}
                    xs={24}
                    sm={24}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                >
                    <PrometheusRedisCommandsPerSecChart
                        data={commandsPerSecStats}
                        loading={commandsPerSecStatsLoading}
                        displaySummary={displaySummary}
                    />
                </Col>
                <Col
                    className={styles.RedisDashboardCol}
                    xs={24}
                    sm={24}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                >
                    <PrometheusRedisTotalKeysChart
                        data={totalKeysStats}
                        loading={totalKeysStatsLoading}
                        displaySummary={displaySummary}
                    />
                </Col>
                {node?.isMaster() && (
                    <Col
                        className={styles.RedisDashboardCol}
                        xs={24}
                        sm={24}
                        md={8}
                        lg={8}
                        xl={8}
                        xxl={8}
                    >
                        <PrometheusRedisSlaveLagChart
                            data={slaveLagStats}
                            loading={slaveLagStatsLoading}
                            displaySummary={displaySummary}
                        />
                    </Col>
                )}

            </Row>
        </section>
    );
}

export default RedisDashboard;
