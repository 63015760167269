import { useState } from 'react';
import { Col, Form, Input, Modal, Row, Typography, message } from 'antd';
import styles from './AppDeleteModal.module.less';
import { ReactComponent as Circle } from './../assets/icons/circle.svg';
import { ReactComponent as CircleIcon } from './../assets/icons/exclamationCircle.svg';
import DeploymentsItem from '../types/DeploymentsItem';
import CcxLogo from './ccx/common/CcxLogo';
import Service from '../types/Service';
import StretchedClick from './common/StretchedClick';

export type AppDeleteModalProps = {
    confirmPhrase?: string;
    stretchedClick?: boolean;
    onConfirmDelete?: () => void;
    datastore?: DeploymentsItem;
    service?: Service;
    toDelete: string;
    testId?: string;
};
export default function AppDeleteModal({
    confirmPhrase = 'delete',
    stretchedClick = false,
    onConfirmDelete,
    datastore,
    service,
    toDelete,
    testId = 'AppDeleteModal',
}: AppDeleteModalProps) {
    const { Text } = Typography;
    const [showModal, setShowModal] = useState(false);
    const onClose = () => {
        setShowModal(false);
    };
    const [form] = Form.useForm();
    const verifyMatch = (value: string) => {
        return value === confirmPhrase;
    };

    const handleOk = () => {
        const phrase = form.getFieldValue('confirmation');
        if (verifyMatch(phrase)) {
            onClose();
            form.setFieldsValue({ confirmation: '' });
            onConfirmDelete && onConfirmDelete();
        } else {
            message.error(
                'Incorrect confirmation. Did you enter the correct value?'
            );
        }
    };
    const handleKeyUpEvent = (event: any) => {
        if (event.key === 'Enter') {
            handleOk();
        }
    };

    return (
        <>
            {stretchedClick && (
                <StretchedClick handleClick={() => setShowModal(true)} />
            )}

            <span
                style={{ textAlign: 'center' }}
                onClick={() => {
                    if (stretchedClick) return;
                    setShowModal(true);
                }}
            >
                Delete
            </span>

            <Modal
                title={
                    <strong>{`Are you sure you want to delete a ${toDelete} ?`}</strong>
                }
                open={showModal}
                width={600}
                data-testid={testId}
                onOk={handleOk}
                onCancel={onClose}
            >
                <Row>
                    <Col span={24}>
                        <div className={styles.AppDeleteModalCol}>
                            <div className={styles.AppDeleteModalIconStack}>
                                <Circle />
                                <CircleIcon
                                    className={styles.AppDeleteCircleIcon}
                                />
                            </div>
                        </div>
                        <div className={styles.AppDeleteLabelCol}>
                            <div className={styles.AppDeleteModalTitle}>
                                {`You’re going to delete a ${toDelete}`}
                            </div>
                            <div className={styles.AppDeleteModalSubTitle}>
                                <div>
                                    {`Deleting the ${toDelete} will remove all data
                                    stored there.`}
                                </div>
                                <div>
                                    Caution! This action is irreversible and all
                                    data will be lost.
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col span={24} className={styles.AppDeleteServiceInfo}>
                        <span className={styles.AppDeleteServiceLabel}>
                            You will delete:
                        </span>
                        <div className={styles.AppDeleteService}>
                            <Row>
                                <Col
                                    span={3}
                                    className={styles.AppDeleteModalLogo}
                                >
                                    <CcxLogo
                                        logo={datastore?.getDbIconName()}
                                        size="large"
                                    />
                                </Col>
                                <Col span={20}>
                                    <strong>
                                        {service
                                            ? service.getFQDN()
                                            : datastore?.getName()}
                                    </strong>
                                    <br />
                                    {!service && (
                                        <>
                                            <Text type="secondary">
                                                <small>
                                                    {datastore?.getUUID()}
                                                </small>
                                            </Text>
                                            <br />
                                        </>
                                    )}
                                    <Text type="secondary">
                                        {service ? (
                                            <small>
                                                {`${datastore?.getDbVendorName()} ${datastore?.getDbVendorVersion()}`}
                                                {` `}| {service?.getRoleName()}{' '}
                                                ({service?.getPermisiion()})
                                            </small>
                                        ) : (
                                            <small>
                                                {`${datastore?.getDbVendorName()} ${datastore?.getDbVendorVersion()}`}
                                                {` `}|{' '}
                                                {datastore?.getClusterTypeName()}{' '}
                                                ({datastore?.clusterType})
                                            </small>
                                        )}
                                    </Text>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col span={24} className={styles.AppDeleteInputCol}>
                        <p>{`Type "${confirmPhrase}" into the field to confirm`}</p>
                        <Form
                            layout="horizontal"
                            form={form}
                            scrollToFirstError={true}
                            onKeyUp={handleKeyUpEvent}
                        >
                            <Form.Item name="confirmation" noStyle>
                                <Input
                                    autoComplete="off"
                                    placeholder="Click here to type"
                                />
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
            </Modal>
        </>
    );
}
