import Helpers from '../services/Helpers';
import { VpcDTO } from '../sdk/VpcApi';
import { Subnet } from '../sdk/vpc/typescript-axios';

export type VpcClassProps = VpcDTO & {};

export enum VpcStatus {
    OK = 'AVAILABLE',
    ERROR = 'NOT_AVAILABLE',
    WARNING = 'WARNING',
}

export interface VpcInterface {
    id: string;
    name: string;
    cloudspace?: string;
    cloud: string;
    region: string;
    project?: string;
    cidrIpv4Block: string;
    state?: string;
    stateMessage?: string;
    datastores?: number;
    subnets?: { [key: string]: Subnet };
    isStatusOk: Function;
    isStatusError: Function;
    isStatusWarning: Function;
    getCidrIpv4Block: Function;
    getName: Function;
    getState: Function;
    getStatusMessage: Function;
    vpc_name: string;
}

export default class Vpc implements VpcInterface {
    readonly id: string;
    readonly name: string;
    readonly cloudspace?: string;
    readonly cloud: string;
    readonly region: string;
    readonly project?: string;
    readonly cidrIpv4Block: string;
    readonly state?: string;
    readonly stateMessage?: string;
    readonly datastores?: number;
    readonly subnets?: { [key: string]: Subnet };
    readonly vpc_name!: string;

    constructor(props: VpcClassProps | any) {
        this.id = props.id;
        this.name = props.name;
        this.cloudspace = props.cloudspace;
        this.cloud = props.cloud;
        this.region = props.region;
        this.project = props.project;
        this.cidrIpv4Block = props.cidr_ipv4_block;
        this.state = props.state;
        this.stateMessage = props.state_message;
        this.datastores = props.datastores;
        this.subnets = props.subnets;
        this.vpc_name = props.vpc_name;
    }

    getVpcName(): string {
        return this.vpc_name;
    }

    isStatusOk() {
        return this.state?.toUpperCase() === VpcStatus.OK;
    }

    isStatusError() {
        return this.state?.toUpperCase() === VpcStatus.ERROR;
    }

    getName(): string {
        return this.name;
    }

    isStatusWarning() {
        return this.state?.toUpperCase() === VpcStatus.WARNING;
    }

    getStatusMessage() {
        return Helpers.capitalizeFirstLetter(this.stateMessage || '');
    }

    getCidrIpv4Block() {
        return this.cidrIpv4Block;
    }

    getState() {
        return this.state;
    }

    getVpcStatus(): string {
        if (this.isStatusOk()) return 'Available';
        if (this.isStatusWarning()) return 'Warning';
        if (this.isStatusError()) return 'Not available';
        if (!this.stateMessage)
            return Helpers.capitalizeFirstLetter(this.state || '');
        return this.getStatusMessage();
    }
}
